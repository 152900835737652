import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import {backup} from "../../data/orbit.js";
export function Logout(_props) {
  async function logout() {
    await backup.reset();
    localStorage.clear();
    location.href = "/";
  }
  useEffect(() => {
    logout();
  }, []);
  return /* @__PURE__ */ React.createElement("span", null, "Logging out...");
}
