export function form_mask(mask, old, next) {
  if (next.length < old?.length || 0) {
    return next;
  }
  if (next.length > mask.length)
    return old;
  const splits = next.split("");
  if (!splits.length)
    return "";
  const masked = [];
  for (let segment of mask) {
    if (typeof segment === "string") {
      if (splits[0] === segment)
        splits.shift();
      masked.push(segment);
    }
    if (!splits.length)
      break;
    if (segment instanceof RegExp) {
      while (true) {
        const next2 = splits.shift();
        if (!next2)
          break;
        if (segment.test(next2)) {
          masked.push(next2);
          break;
        }
      }
    }
  }
  return masked.join("");
}
export const form_mask_date = [
  /[0-1]/,
  /[0-9]/,
  "/",
  /[0-3]/,
  /[0-9]/,
  "/",
  /[1-2]/,
  /\d/,
  /\d/,
  /\d/
];
export const form_mask_phone = [
  "(",
  /\d/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
