import React from "../../../_snowpack/pkg/react.js";
import {Link} from "../../../_snowpack/pkg/@reach/router.js";
import {Title, Description} from "./common.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {TemplateCard} from "../../structures/TemplateCard.js";
import {Theme, Wrap} from "../../components/index.js";
import {Footer} from "./common.js";
const Grid = styled.div`
  display: grid;
  margin-top: ${Theme.spacing.size800};
  gap: ${Theme.spacing.size800};

  @media only screen and (min-device-width: ${Theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export function Select(props) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Title, null, "Schedule with", " ", props.user.attributes.name_first || props.user.attributes.slug), /* @__PURE__ */ React.createElement(Description, null, "Select an event below to start scheduling"), /* @__PURE__ */ React.createElement(Wrap, {
    width: "sm"
  }, /* @__PURE__ */ React.createElement(Grid, null, (props.templates || []).filter((item) => item).sort((a, b) => a.attributes.name > b.attributes.name ? 1 : -1).filter((item) => item.attributes?.private != true).map((item) => /* @__PURE__ */ React.createElement(Link, {
    key: item?.id,
    to: `./${item.attributes?.slug}`
  }, /* @__PURE__ */ React.createElement(TemplateCard, {
    template: item
  }))))), /* @__PURE__ */ React.createElement(Footer, {
    fixed: true
  }));
}
