import React from "../../../_snowpack/pkg/react.js";
import {Root, Header, Title, Description, Continue} from "./common.js";
import {Logo} from "../../structures/Logo.js";
import {google_start} from "../../data/api.js";
import {useAuth} from "../../../_snowpack/pkg/@ironbay/ship-react.js";
export function Connect(_props) {
  const auth = useAuth();
  return /* @__PURE__ */ React.createElement(Root, null, /* @__PURE__ */ React.createElement(Logo, null), /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement(Title, null, "Connect your first calendar"), /* @__PURE__ */ React.createElement(Description, null, "Nori needs access to your calendar to figure out when you're busy. You can add more accounts later.")), /* @__PURE__ */ React.createElement(Continue, {
    href: google_start("/dashboard", auth.user),
    as: "a",
    "data-test": "button-continue"
  }, "Continue"));
}
