import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./index.js";
export const PageTitle = styled.div`
  font-size: ${Theme.font.size500};
  font-weight: 600;
  padding-bottom: ${Theme.spacing.size800};
  display: flex;
  align-items: center;
`;
export const PageTitleStretch = styled.div`
  flex-grow: 1;
`;
