import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {FcGoogle} from "../../_snowpack/pkg/react-icons/fc.js";
import {Theme} from "../components/index.js";
const Root = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  background: white;
  border-radius: 8px;
  border: 1px solid ${Theme.colors.border};
  cursor: ${(props) => props.onClick && "pointer"};
  padding: ${Theme.spacing.size400};
`;
const Stretch = styled.div`
  flex-grow: 1;
  font-weight: 500;
`;
const Icon = styled(FcGoogle)`
  width: 25px;
  height: 25px;
  margin-right: ${Theme.spacing.size200};
`;
export function CalendarRow(props) {
  const {calendar, children, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Root, {
    ...rest
  }, /* @__PURE__ */ React.createElement(Icon, null), /* @__PURE__ */ React.createElement(Stretch, null, calendar?.attributes?.name), children);
}
