import React from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {Theme} from "../../components/index.js";
import {Router} from "../../../_snowpack/pkg/@reach/router.js";
import {Logout} from "./Logout.js";
import {Connect} from "./Connect.js";
const Root = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Theme.colors.background};
`;
export function Auth(_props) {
  return /* @__PURE__ */ React.createElement(Root, null, /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Connect, {
    path: "connect"
  }), /* @__PURE__ */ React.createElement(Logout, {
    path: "logout"
  })));
}
