import React, {useState} from "../../../_snowpack/pkg/react.js";
import {Title, Description, Back} from "./common.js";
import {memory, remote} from "../../data/orbit.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {
  Theme,
  Wrap,
  FormGrid,
  FormSectionHeader,
  FormSimpleInput,
  FormOutline,
  FormInput,
  SimpleLink,
  FormLabel,
  TagGrid,
  TagIcon,
  Tag,
  Button
} from "../../components/index.js";
import {format, addMinutes} from "../../../_snowpack/pkg/date-fns.js";
import {useForm} from "../../data/form.js";
import {FiX} from "../../../_snowpack/pkg/react-icons/fi.js";
import {v4} from "../../../_snowpack/pkg/uuid.js";
import {useMaybeAuth} from "../../../_snowpack/pkg/@ironbay/ship-react.js";
const Grid = styled(FormGrid)`
  margin-top: ${Theme.spacing.size1200};
`;
export function Confirm(props) {
  const auth = useMaybeAuth();
  const {template} = props;
  const [pending, pending_set] = useState(false);
  const time = new Date(parseInt(props.time));
  const self = auth.user ? memory.cache.query((q) => q.findRecord({type: "user", id: auth.user})) : null;
  const form = useForm({
    name: template?.attributes.name,
    times: {
      start: time.getTime()
    },
    from: {
      name: self ? `${self.attributes.name_first} ${self.attributes.name_last}` : "",
      email: self ? self.attributes.email : ""
    }
  });
  const [guest_input, guest_input_set] = useState("");
  async function create() {
    pending_set(true);
    const booking = {
      type: "booking",
      id: v4(),
      attributes: {
        start: time.toISOString(),
        end: addMinutes(time, template.attributes.duration).toISOString(),
        guests: form.data.guests?.map((i) => i.trim()),
        from: {
          name: form.data.from.name,
          email: form.data.from.email.trim()
        },
        name: "",
        description: template.attributes.description
      },
      relationships: {
        template: {
          data: {
            type: "template",
            id: template.id
          }
        },
        calendar: template.relationships.target
      }
    };
    try {
      await remote.update((t) => t.addRecord(booking));
      props.navigate?.(`../../${booking.id}/complete`);
    } catch {
      alert("Something went wrong, check your email address");
    }
    pending_set(false);
  }
  function guest_add() {
    if (!guest_input)
      return;
    form.merge(["template_info", "guests", guest_input], true);
    guest_input_set("");
  }
  return /* @__PURE__ */ React.createElement(Wrap, {
    width: "xs"
  }, /* @__PURE__ */ React.createElement(Back, {
    to: "../"
  }, "Choose a different time"), /* @__PURE__ */ React.createElement(Title, null, form?.data?.name), /* @__PURE__ */ React.createElement(Description, null, template?.attributes.duration, "min  •  ", format(time, "EEEE, MMMM do 'at' hh:mm a")), /* @__PURE__ */ React.createElement(Grid, null, /* @__PURE__ */ React.createElement(FormSectionHeader, null, "General Info"), /* @__PURE__ */ React.createElement(FormSimpleInput, {
    label: "Your Name",
    path: ["from", "name"],
    formHook: form
  }), /* @__PURE__ */ React.createElement(FormSimpleInput, {
    label: "Your Email Address",
    path: ["from", "email"],
    formHook: form
  }), template?.attributes.unnamed && /* @__PURE__ */ React.createElement(FormSimpleInput, {
    label: "Event Name",
    path: ["name"],
    formHook: form
  }), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Additional Guests"), /* @__PURE__ */ React.createElement(FormOutline, null, /* @__PURE__ */ React.createElement(FormLabel, {
    active: guest_input
  }, "Guest's email"), /* @__PURE__ */ React.createElement(FormInput, {
    onKeyPress: (e) => {
      if (e.key !== "13")
        return;
      guest_add();
    },
    onChange: (e) => guest_input_set(e.target.value),
    value: guest_input
  }), /* @__PURE__ */ React.createElement(SimpleLink, {
    onClick: guest_add,
    as: "div"
  }, "Add")), /* @__PURE__ */ React.createElement(TagGrid, null, Object.keys(form?.data?.template_info?.guests || {}).map((item) => /* @__PURE__ */ React.createElement(Tag, {
    key: item,
    onClick: () => form.delete(["template_info", "guests", item])
  }, item, /* @__PURE__ */ React.createElement(TagIcon, {
    as: FiX
  })))), !pending && /* @__PURE__ */ React.createElement(Button, {
    onClick: create
  }, "Schedule Event"), pending && /* @__PURE__ */ React.createElement(Button, {
    variant: "secondary"
  }, "Booking...")));
}
