import { a as fulfillInSeries, s as settleInSeries, O as Orbit } from './log-e4bf9a42.js';
import { S as Source } from './source-bf183794.js';
import { d as buildQuery, n as mapNamedFullResponses, f as buildTransform } from './record-source-87b54fe8.js';

const { assert, deprecate } = Orbit;
const PULLABLE = '__pullable__';
/**
 * Has a source been decorated as `@pullable`?
 */
function isPullable(source) {
    return !!source[PULLABLE];
}
/**
 * Marks a source as "pullable" and adds an implementation of the `Pullable`
 * interface.
 *
 * The `pull` method is part of the "request flow" in Orbit. Requests trigger
 * events before and after processing of each request. Observers can delay the
 * resolution of a request by returning a promise in an event listener.
 *
 * A pullable source emits the following events:
 *
 * - `beforePull` - emitted prior to the processing of `pull`, this event
 * includes the requested `Query` as an argument.
 *
 * - `pull` - emitted after a `pull` has successfully been requested, this
 * event's arguments include both the requested `Query` and an array of the
 * resulting `Transform` instances.
 *
 * - `pullFail` - emitted when an error has occurred processing a `pull`, this
 * event's arguments include both the requested `Query` and the error.
 *
 * A pullable source must implement a private method `_pull`, which performs
 * the processing required for `pull` and returns a promise that resolves to an
 * array of `Transform` instances.
 */
function pullable(Klass) {
    let proto = Klass.prototype;
    if (isPullable(proto)) {
        return;
    }
    assert('Pullable interface can only be applied to a Source', proto instanceof Source);
    proto[PULLABLE] = true;
    proto.pull = async function (queryOrExpressions, options, id) {
        deprecate("'pull' has been deprecated. Please use 'query' instead and create your own transform from the results.");
        await this.activated;
        const query = buildQuery(queryOrExpressions, options, id, this.queryBuilder);
        const response = await this._requestQueue.push({
            type: 'pull',
            data: query
        });
        return (options === null || options === void 0 ? void 0 : options.fullResponse) ? response : response.transforms || [];
    };
    proto.__pull__ = async function (query) {
        var _a;
        try {
            const hints = {};
            const otherResponses = (await fulfillInSeries(this, 'beforePull', query, hints));
            const fullResponse = await this._pull(query, hints);
            if (otherResponses.length > 0) {
                fullResponse.sources = mapNamedFullResponses(otherResponses);
            }
            if (((_a = fullResponse.transforms) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                await this.transformed(fullResponse.transforms);
            }
            await settleInSeries(this, 'pull', query, fullResponse);
            return fullResponse;
        }
        catch (error) {
            await settleInSeries(this, 'pullFail', query, error);
            throw error;
        }
    };
}

const { assert: assert$1, deprecate: deprecate$1 } = Orbit;
const PUSHABLE = '__pushable__';
/**
 * Has a source been decorated as `@pushable`?
 */
function isPushable(source) {
    return !!source[PUSHABLE];
}
/**
 * Marks a source as "pushable" and adds an implementation of the `Pushable`
 * interface.
 *
 * The `push` method is part of the "request flow" in Orbit. Requests trigger
 * events before and after processing of each request. Observers can delay the
 * resolution of a request by returning a promise in an event listener.
 *
 * A pushable source emits the following events:
 *
 * - `beforePush` - emitted prior to the processing of `push`, this event
 * includes the requested `Transform` as an argument.
 *
 * - `push` - emitted after a `push` has successfully been applied, this event's
 * arguments include both the requested `Transform` and an array of the actual
 * applied `Transform` instances.
 *
 * - `pushFail` - emitted when an error has occurred pushing a transform, this
 * event's arguments include both the requested `Transform` and the error.
 *
 * A pushable source must implement a private method `_push`, which performs
 * the processing required for `push` and returns a promise that resolves to an
 * array of `Transform` instances.
 */
function pushable(Klass) {
    let proto = Klass.prototype;
    if (isPushable(proto)) {
        return;
    }
    assert$1('Pushable interface can only be applied to a Source', proto instanceof Source);
    proto[PUSHABLE] = true;
    proto.push = async function (transformOrOperations, options, id) {
        deprecate$1("'push' has been deprecated. Please use 'update' instead and specify '{ fullResponse: true }' to access the resultant 'transforms'.");
        await this.activated;
        const transform = buildTransform(transformOrOperations, options, id, this.transformBuilder);
        if (this.transformLog.contains(transform.id)) {
            const transforms = [];
            const response = (options === null || options === void 0 ? void 0 : options.fullResponse) ? { transforms } : transforms;
            return response;
        }
        else {
            const response = await this._requestQueue.push({
                type: 'push',
                data: transform
            });
            return (options === null || options === void 0 ? void 0 : options.fullResponse) ? response : response.transforms || [];
        }
    };
    proto.__push__ = async function (transform) {
        var _a;
        if (this.transformLog.contains(transform.id)) {
            return { transforms: [] };
        }
        try {
            const hints = {};
            const otherResponses = (await fulfillInSeries(this, 'beforePush', transform, hints));
            const fullResponse = await this._push(transform, hints);
            if (otherResponses.length > 0) {
                fullResponse.sources = mapNamedFullResponses(otherResponses);
            }
            if (((_a = fullResponse.transforms) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                await this.transformed(fullResponse.transforms);
            }
            await settleInSeries(this, 'push', transform, fullResponse);
            return fullResponse;
        }
        catch (error) {
            await settleInSeries(this, 'pushFail', transform, error);
            throw error;
        }
    };
}

export { pushable as a, pullable as p };
