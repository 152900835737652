import React, {useRef} from "../../_snowpack/pkg/react.js";
import {Theme} from "../components/index.js";
import {remote} from "../data/orbit.js";
import {useEffect, useState} from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  z-index: 10000;
  background: repeating-linear-gradient(
    to right,
    white 0%,
    ${Theme.colors.primary} 10%,
    ${Theme.colors.primary} 20%,
    white 30%,
    white 100%
  );
  background-size: 200% auto;
  animation: gradient 2s infinite linear;
  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;
export function Loader() {
  const [count, count_set] = useState(0);
  const [active, active_set] = useState(false);
  const debounce = useRef(void 0);
  useEffect(() => {
    remote.on("beforeQuery", () => {
      count_set((item) => item + 1);
    });
    remote.on("beforeUpdate", () => {
      count_set((item) => item + 1);
    });
    remote.on("query", () => {
      count_set((item) => item - 1);
    });
    remote.on("queryFail", () => {
      count_set((item) => item - 1);
    });
    remote.on("update", () => {
      count_set((item) => item - 1);
    });
    remote.on("updateFail", () => {
      count_set((item) => item - 1);
    });
  }, []);
  useEffect(() => {
    if (count > 0) {
      if (debounce.current)
        clearTimeout(debounce.current);
      active_set(true);
    }
    if (count === 0) {
      debounce.current = setTimeout(() => active_set(false), 250);
    }
  }, [count]);
  return active ? /* @__PURE__ */ React.createElement(Root, null) : null;
}
