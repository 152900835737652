import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import "../_snowpack/pkg/sanitize.css";
import "./reset.css";
import "./assets/fonts/avenir-next/stylesheet.css";
import React, {useEffect, useState} from "../_snowpack/pkg/react.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import {Router, Redirect} from "../_snowpack/pkg/@reach/router.js";
import {createGlobalStyle} from "../_snowpack/pkg/styled-components.js";
import {Theme} from "./components/theme.js";
import {Dashboard} from "./pages/Dashboard/index.js";
import {Book} from "./pages/Book/index.js";
import {memory, coordinator, backup} from "./data/orbit.js";
import {AuthProvider, useMaybeAuth} from "../_snowpack/pkg/@ironbay/ship-react.js";
import {Scrap} from "./pages/Scrap/index.js";
import * as Sentry from "../_snowpack/pkg/@sentry/react.js";
import {Integrations} from "../_snowpack/pkg/@sentry/tracing.js";
import {Config} from "./data/config.js";
import {Auth} from "./pages/Auth/index.js";
import {Splash} from "./components/index.js";
Sentry.init({
  dsn: "https://7f5a468bdeef4e3183dd70e4770a5d96@o237235.ingest.sentry.io/5898979",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1
});
const GlobalStyle = createGlobalStyle`
  body { 
    font-family: Avenir Next;
    color: ${Theme.colors.textDark};
    background: #f8f8f8;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    line-height: 1;
  }

  a {
    text-decoration: none;
  }

  :focus {
    outline: none;
  }
  ::-moz-focus-inner {
    border: 0;
  }
`;
function Application() {
  const [_, render_set] = useState(0);
  const [ready, ready_set] = useState(false);
  function rerender() {
    render_set((prev) => prev + 1);
  }
  async function start() {
    try {
      const existing = localStorage.getItem("version");
      if (existing !== Config.VERSION) {
        const records = await backup.query((q) => q.findRecords());
        await backup.update((q) => records.map((r) => q.removeRecord(r)));
        localStorage.setItem("version", Config.VERSION);
      }
      const transform = await backup.pull((q) => q.findRecords());
      await memory.sync(transform);
    } catch (ex) {
      indexedDB.deleteDatabase("nori");
    }
    await coordinator.activate();
    memory.on("transform", () => {
      rerender();
    });
    ready_set(true);
  }
  useEffect(() => {
    start();
  }, []);
  return /* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(GlobalStyle, null), ready && /* @__PURE__ */ React.createElement(AuthProvider, {
    pool: Config.USER_POOL_ID,
    client: Config.USER_POOL_CLIENT_ID,
    url: Config.AUTH_URL,
    logo: location.origin + "/logo.svg",
    fallback: /* @__PURE__ */ React.createElement(Splash, {
      active: true
    })
  }, /* @__PURE__ */ React.createElement(AuthLoader, null), /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Dashboard, {
    path: "/dashboard/*"
  }), /* @__PURE__ */ React.createElement(Redirect, {
    noThrow: true,
    from: "/",
    to: "/dashboard"
  }), /* @__PURE__ */ React.createElement(Book, {
    path: "/:slug/*"
  }), /* @__PURE__ */ React.createElement(Scrap, {
    path: "/scrap"
  }), /* @__PURE__ */ React.createElement(Auth, {
    path: "/auth/*"
  }))));
}
function AuthLoader() {
  const auth = useMaybeAuth();
  useEffect(() => {
    if (auth.token)
      localStorage.setItem("token", auth.token);
  }, [auth.token]);
  return null;
}
ReactDOM.render(/* @__PURE__ */ React.createElement(Application, null), document.getElementById("root"));
if (undefined /* [snowpack] import.meta.hot */ ) {
  undefined /* [snowpack] import.meta.hot */ .accept();
}
