import { c as createCommonjsModule, b as commonjsGlobal } from '../common/_commonjsHelpers-08657d54.js';
import { r as react } from '../common/index-72e4f9cb.js';
import { d as dist$1 } from '../common/index-80f15825.js';

var auth = createCommonjsModule(function (module, exports) {
var __createBinding = (commonjsGlobal && commonjsGlobal.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (commonjsGlobal && commonjsGlobal.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (commonjsGlobal && commonjsGlobal.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMaybeAuth = exports.useAuth = exports.AuthProvider = exports.AuthContext = void 0;
const react_1 = __importStar(react);

exports.AuthContext = react_1.default.createContext({});
function AuthProvider(props) {
    const [id, setID] = react_1.useState("");
    const [token, setToken] = react_1.useState("");
    const [ready, setReady] = react_1.useState(false);
    react_1.useEffect(() => {
        dist$1.Auth.init(props)
            .then((result) => {
            setID(result.id);
            setToken(result.token);
        })
            .catch(() => { })
            .finally(() => setReady(true));
    }, []);
    if (!ready)
        return null;
    return (react_1.default.createElement(exports.AuthContext.Provider, { value: { user: id, token, config: props } }, props.children));
}
exports.AuthProvider = AuthProvider;
function useAuth() {
    const result = react_1.useContext(exports.AuthContext);
    if (!result.user || !result.token) {
        dist$1.Auth.login(result.config);
    }
    return {
        user: result.user,
        token: result.token,
    };
}
exports.useAuth = useAuth;
function useMaybeAuth() {
    const result = react_1.useContext(exports.AuthContext);
    return {
        user: result.user,
        token: result.token,
    };
}
exports.useMaybeAuth = useMaybeAuth;
});

var dist = createCommonjsModule(function (module, exports) {
var __createBinding = (commonjsGlobal && commonjsGlobal.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (commonjsGlobal && commonjsGlobal.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(auth, exports);
});

var AuthProvider = dist.AuthProvider;
var useAuth = dist.useAuth;
var useMaybeAuth = dist.useMaybeAuth;
export { AuthProvider, useAuth, useMaybeAuth };
