const colors = {
  blue50: "#F2FAFD",
  blue100: "#E6F6FB",
  blue200: "#C0E7F6",
  blue300: "#99D9F0",
  blue400: "#4DBDE4",
  blue500: "#01A0D9",
  blue600: "#0190C3",
  blue700: "#016082",
  blue800: "#004862",
  blue900: "#003041",
  gray50: "#FEFEFE",
  gray100: "#FDFDFE",
  gray200: "#FAFBFC",
  gray300: "#F7F8FB",
  gray400: "#F1F2F7",
  gray500: "#EBEDF4",
  gray600: "#D4D5DC",
  gray700: "#8D8E92",
  gray800: "#6A6B6E",
  gray900: "#474749",
  red50: "#FDF6F6",
  red100: "#FCEDEC",
  red200: "#F7D2D0",
  red300: "#F1B7B4",
  red400: "#E7817B",
  red500: "#DD4B43",
  red600: "#C7443C",
  red700: "#852D28",
  red800: "#63221E",
  red900: "#421714",
  green50: "#F4FCF7",
  green100: "#EAF9F0",
  green200: "#C9F0D9",
  green300: "#A9E6C2",
  green400: "#69D494",
  green500: "#28C166",
  green600: "#24AE5C",
  green700: "#18743D",
  green800: "#12572E",
  green900: "#0C3A1F",
  midnight50: "#F3F4F5",
  midnight100: "#E7E9EC",
  midnight200: "#C4C7CF",
  midnight300: "#A0A6B1",
  midnight400: "#586377",
  midnight500: "#11203D",
  midnight600: "#0F1D37",
  midnight700: "#0A1325",
  midnight800: "#080E1B",
  midnight900: "#050A12"
};
const colors_semantic = {
  background: "#f8f8f8",
  danger: "red",
  primary: colors.green500,
  textDark: colors.midnight500,
  textLight: "#4d4d4d",
  border: "#DDDDDD"
};
const spacing = {
  size50: "0.25rem",
  size100: "0.25rem",
  size200: "0.5rem",
  size300: "0.75rem",
  size325: "0.8125rem",
  size350: "0.875rem",
  size375: "0.9375rem",
  size400: "1rem",
  size450: "1.125rem",
  size500: "1.25rem",
  size600: "1.5rem",
  size700: "1.75rem",
  size800: "2rem",
  size1200: "3rem",
  size1600: "4rem",
  size2400: "6rem",
  size3200: "8rem"
};
const breakpoints = {
  xs: "400px",
  sm: "560px",
  md: "768px",
  lg: "1024px",
  xl: "1280px"
};
const media = {
  xs: `@media only screen and (min-width: ${breakpoints.xs})`,
  sm: `@media only screen and (min-width: ${breakpoints.sm})`,
  md: `@media only screen and (min-width: ${breakpoints.md})`,
  lg: `@media only screen and (min-width: ${breakpoints.lg})`,
  xl: `@media only screen and (min-width: ${breakpoints.xl})`
};
export const Theme = {
  breakpoints,
  media,
  colors: {
    ...colors,
    ...colors_semantic
  },
  spacing,
  font: {
    ...spacing
  },
  easing: {
    default: "cubic-bezier(0.03, 0.7, 0.31, 1) 0s"
  }
};
