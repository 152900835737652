import React from "../../../_snowpack/pkg/react.js";
import {Theme} from "../../components/index.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {FiChevronLeft} from "../../../_snowpack/pkg/react-icons/fi.js";
import {Link} from "../../../_snowpack/pkg/@reach/router.js";
import {Logo} from "../../structures/Logo.js";
export const Title = styled.div`
  font-size: ${Theme.font.size600};
  font-weight: 600;
  text-align: center;
`;
export const Description = styled.div`
  margin-top: ${Theme.spacing.size100};
  color: ${Theme.colors.textLight};
  text-align: center;
  line-height: 150%;
`;
const BackRoot = styled(Link)`
  display: flex;
  font-size: ${Theme.font.size450};
  justify-content: center;
  gap: ${Theme.spacing.size100};
  align-items: center;
  margin-bottom: ${Theme.spacing.size200};
  cursor: pointer;
  color: ${Theme.colors.textDark};
`;
export function Back(props) {
  return /* @__PURE__ */ React.createElement(BackRoot, {
    to: props.to
  }, /* @__PURE__ */ React.createElement(FiChevronLeft, {
    size: 23
  }), props.children);
}
const FooterRoot = styled.div`
  position: ${(props) => props.fixed ? "fixed" : "relative"};
  z-index: ${(props) => props.fixed && 100};
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${Theme.spacing.size1200};
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0)
    linear-gradient(rgba(248, 248, 248, 0) 0%, rgb(248, 248, 248) 40%) repeat
    scroll 0% 0%;
`;
const FooterLogo = styled(Logo)`
  margin-left: ${Theme.spacing.size400};
`;
export function Footer(props) {
  return /* @__PURE__ */ React.createElement(FooterRoot, {
    ...props
  }, "Powered by", /* @__PURE__ */ React.createElement(Link, {
    to: "/"
  }, /* @__PURE__ */ React.createElement(FooterLogo, null)));
}
