import { e as evented, L as Log, T as TaskQueue, s as settleInSeries, O as Orbit } from './log-e4bf9a42.js';

/**
 * Merges general request options with those specific to a source. The more
 * specific options override the general options. If an array of options is
 * provided, they will be merged to a single set.
 */
function requestOptionsForSource(options, source) {
    let result;
    if (options !== undefined) {
        if (Array.isArray(options)) {
            for (let o of options) {
                if (o) {
                    let so = extractRequestOptionsForSource(o, source);
                    if (result) {
                        result = {
                            ...result,
                            ...so
                        };
                    }
                    else {
                        result = so;
                    }
                }
            }
        }
        else {
            result = extractRequestOptionsForSource(options, source);
        }
    }
    return result;
}
function extractRequestOptionsForSource(options, source) {
    if (options.sources) {
        let { sources, ...rest } = options;
        if (source && sources[source]) {
            return {
                ...rest,
                ...sources[source]
            };
        }
        else {
            return rest;
        }
    }
    else {
        return options;
    }
}

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const { assert } = Orbit;
/**
 * Base class for sources.
 */
let Source = class Source {
    constructor(settings = {}) {
        const name = (this._name = settings.name);
        const bucket = (this._bucket = settings.bucket);
        const requestQueueSettings = settings.requestQueueSettings || {};
        const syncQueueSettings = settings.syncQueueSettings || {};
        const autoActivate = settings.autoActivate === undefined || settings.autoActivate;
        this._queryBuilder = settings.queryBuilder;
        this._transformBuilder = settings.transformBuilder;
        this._defaultQueryOptions = settings.defaultQueryOptions;
        this._defaultTransformOptions = settings.defaultTransformOptions;
        if (bucket) {
            assert('TransformLog requires a name if it has a bucket', !!name);
        }
        this._transformLog = new Log({
            name: name ? `${name}-log` : undefined,
            bucket: bucket
        });
        this._syncQueue = new TaskQueue(this, {
            name: name ? `${name}-sync` : undefined,
            bucket: bucket,
            autoActivate: false,
            ...syncQueueSettings
        });
        this._requestQueue = new TaskQueue(this, {
            name: name ? `${name}-requests` : undefined,
            bucket: bucket,
            autoActivate: false,
            ...requestQueueSettings
        });
        if (autoActivate) {
            this.activate();
        }
    }
    get name() {
        return this._name;
    }
    get bucket() {
        return this._bucket;
    }
    get transformLog() {
        return this._transformLog;
    }
    get requestQueue() {
        return this._requestQueue;
    }
    get syncQueue() {
        return this._syncQueue;
    }
    get queryBuilder() {
        return this._queryBuilder;
    }
    get transformBuilder() {
        return this._transformBuilder;
    }
    get defaultQueryOptions() {
        return this._defaultQueryOptions;
    }
    set defaultQueryOptions(options) {
        this._defaultQueryOptions = options;
    }
    get defaultTransformOptions() {
        return this._defaultTransformOptions;
    }
    set defaultTransformOptions(options) {
        this._defaultTransformOptions = options;
    }
    getQueryOptions(query, expression) {
        return requestOptionsForSource([
            this._defaultQueryOptions,
            query.options,
            expression === null || expression === void 0 ? void 0 : expression.options
        ], this._name);
    }
    getTransformOptions(transform, operation) {
        return requestOptionsForSource([
            this._defaultTransformOptions,
            transform.options,
            operation === null || operation === void 0 ? void 0 : operation.options
        ], this._name);
    }
    // Performer interface
    perform(task) {
        const methodName = `__${task.type}__`;
        const method = this[methodName];
        assert(`Method '${methodName}' does not exist on Source`, method !== undefined);
        return method.call(this, task.data);
    }
    /**
     * Upgrade source as part of a schema upgrade.
     */
    upgrade() {
        return Promise.resolve();
    }
    get activated() {
        if (!this._activated) {
            throw new Error(`"${this.name}" source is not activated`);
        }
        return this._activated;
    }
    async activate() {
        if (!this._activated) {
            this._activated = this._activate();
        }
        await this._activated;
    }
    async deactivate() {
        if (this._activated) {
            await this._activated;
        }
        this._activated = undefined;
    }
    /**
     * Notifies listeners that this source has been transformed by emitting the
     * `transform` event.
     *
     * Resolves when any promises returned to event listeners are resolved.
     *
     * Also, adds an entry to the Source's `transformLog` for each transform.
     */
    async transformed(transforms) {
        await this.activated;
        for (let transform of transforms) {
            if (!this._transformLog.contains(transform.id)) {
                await this._transformLog.append(transform.id);
                await settleInSeries(this, 'transform', transform);
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////
    // Protected methods
    /////////////////////////////////////////////////////////////////////////////
    async _activate() {
        await this._transformLog.reified;
        await this._syncQueue.activate();
        await this._requestQueue.activate();
    }
};
Source = __decorate([
    evented
], Source);

export { Source as S, requestOptionsForSource as r };
