import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {DurationIcon} from "./DurationIcon.js";
import {FiChevronRight} from "../../_snowpack/pkg/react-icons/fi.js";
import {Theme, Button, SimpleLink} from "../components/index.js";
const Next = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;
const NextButton = styled.div`
  width: 35px;
  height: 35px;
  background: ${Theme.colors.gray500};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
  transition: 300ms all ${Theme.easing.default};
`;
const Card = styled.div`
  background: white;
  padding: ${Theme.spacing.size500};
  border-radius: 10px;
  cursor: pointer;
  transition: 300ms all ${Theme.easing.default};
  border: 1px solid ${Theme.colors.border};

  :hover {
    ${NextButton} {
      opacity: 1;
      transform: initial;
    }
  }
`;
const Header = styled.div`
  display: flex;
`;
const Footer = styled.div`
  display: flex;
  margin-top: ${Theme.spacing.size800};
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  font-size: ${Theme.font.size500};
  color: ${Theme.colors.textDark};
  padding-right: 1rem;
  font-weight: 600;
  flex-grow: 1;
`;
const Description = styled.div`
  margin-top: ${Theme.spacing.size100};
  color: ${Theme.colors.textLight};
  font-size: ${Theme.font.size350};
`;
export function TemplateCard(props) {
  const [copied, copied_set] = React.useState(false);
  const url = `${location.origin}/${props.slug}/${props.template.attributes.slug}`;
  const {template} = props;
  return /* @__PURE__ */ React.createElement(Card, {
    "data-test": "template-card"
  }, /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement(Title, null, template.attributes.alias || template.attributes.name), /* @__PURE__ */ React.createElement(DurationIcon, {
    duration: template.attributes.duration
  })), /* @__PURE__ */ React.createElement(Description, null, template.attributes.duration, " min"), /* @__PURE__ */ React.createElement(Footer, null, props.editable && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(SimpleLink, {
    onClick: (e) => e.stopPropagation(),
    as: "a",
    target: "_blank",
    href: url
  }, "/", template.attributes.slug), /* @__PURE__ */ React.createElement(Button, {
    style: {width: "8rem"},
    variant: copied ? "primary" : "primary_outline",
    size: "sm",
    onClick: (e) => {
      e.preventDefault();
      const el = document.createElement("textarea");
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      copied_set(true);
      setTimeout(() => copied_set(false), 2e3);
    }
  }, copied ? "Copied!" : "Copy Link")), !props.editable && /* @__PURE__ */ React.createElement(Next, null, /* @__PURE__ */ React.createElement(NextButton, null, /* @__PURE__ */ React.createElement(FiChevronRight, null)))));
}
