import {useState} from "../../_snowpack/pkg/react.js";
import {del, put, get} from "../../_snowpack/pkg/@ironbay/dynamic.js";
import {form_mask} from "../components/util/index.js";
export function useForm(initial) {
  const [original, original_set] = useState(clone(initial));
  const [dirty, dirty_set] = useState(false);
  const [data, data_set] = useState(initial);
  const [errors, errors_set] = useState({});
  const [mutation, mutation_set] = useState({
    merge: {},
    delete: {}
  });
  function merge(path, val) {
    put(data, path, val);
    data_set(clone(data));
    dirty_set(true);
    del(errors, path);
    errors_set(clone(errors));
    put(mutation, ["merge", ...path], val);
    mutation_set(mutation);
  }
  function delete_(path) {
    del(data, path);
    dirty_set(true);
    data_set(clone(data));
    put(mutation, ["delete", ...path], 1);
    del(mutation, ["merge", ...path]);
    mutation_set(mutation);
  }
  return {
    original,
    data,
    mutation,
    dirty,
    reset(next) {
      dirty_set(false);
      original_set(clone(next));
      data_set(clone(next));
      mutation_set({
        merge: {},
        delete: {}
      });
    },
    merge,
    delete: delete_,
    error_get(path) {
      return get(errors, path);
    },
    error_process(input) {
      errors_set(input);
    },
    bind(path, opts = {}) {
      const value = get(data, path);
      const display = opts.display ? opts.display(value) : value == null ? "" : String(value);
      return {
        value: display || "",
        onBlur: () => {
          if (!opts.parser)
            return;
          const value2 = get(data, path);
          const parsed = opts.parser(value2);
          if (parsed === false) {
            merge(path, get(original, path));
            return;
          }
          merge(path, parsed);
        },
        "data-test": "form-" + path.join("-"),
        onChange: (e) => {
          const next = e.target.value;
          if (opts.mask) {
            const masked = form_mask(opts.mask, value, next);
            merge(path, masked);
            return;
          }
          merge(path, next);
          return;
        }
      };
    }
  };
}
class Helper {
  constructor() {
    this.Return = useForm(void 0);
  }
}
function clone(input) {
  return JSON.parse(JSON.stringify(input));
}
