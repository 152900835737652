import styled from "../../_snowpack/pkg/styled-components.js";
export const FormLabel = styled.label`
  position: absolute;
  height: 100%;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  pointer-events: none;
  transform-origin: top left;
  transition: 200ms all;

  ${(props) => Boolean(props.active) && `transform: scale(0.75) translateY(-4px);`}
`;
