import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./index.js";
export const TagGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${Theme.spacing.size100};
  margin-left: -${Theme.spacing.size200};
`;
export const Tag = styled.div`
  margin: ${Theme.spacing.size100};
  font-weight: 500;
  font-size: ${Theme.font.size400};
  background: ${Theme.colors.gray500};
  display: inline-block;
  padding: ${Theme.spacing.size100} ${Theme.spacing.size300};
  border-radius: 6px;
  border: 1px solid ${Theme.colors.border};
  cursor: pointer;
`;
export const TagIcon = styled.div`
  margin-left: ${Theme.spacing.size200};
`;
