class BaseSerializer {
    constructor(settings) {
        this.serializerFor = settings === null || settings === void 0 ? void 0 : settings.serializerFor;
        this.serializationOptions = settings === null || settings === void 0 ? void 0 : settings.serializationOptions;
        this.deserializationOptions = settings === null || settings === void 0 ? void 0 : settings.deserializationOptions;
    }
    buildSerializationOptions(customOptions) {
        let options = this.serializationOptions;
        if (options && customOptions) {
            return {
                ...options,
                ...customOptions
            };
        }
        else {
            return (options || customOptions || {});
        }
    }
    buildDeserializationOptions(customOptions) {
        let options = this.deserializationOptions;
        if (options && customOptions) {
            return {
                ...options,
                ...customOptions
            };
        }
        else {
            return (options || customOptions || {});
        }
    }
}

class BooleanSerializer extends BaseSerializer {
    serialize(arg) {
        return arg ? true : false;
    }
    deserialize(arg) {
        return arg ? true : false;
    }
}

class DateSerializer extends BaseSerializer {
    serialize(arg) {
        return `${arg.getFullYear()}-${arg.getMonth() + 1}-${arg.getDate()}`;
    }
    deserialize(arg) {
        const [year, month, date] = arg.split('-');
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(date));
    }
}

class DateTimeSerializer extends BaseSerializer {
    serialize(arg) {
        return arg.toISOString();
    }
    deserialize(arg) {
        let offset = arg.indexOf('+');
        if (offset !== -1 && arg.length - 5 === offset) {
            offset += 3;
            return new Date(arg.slice(0, offset) + ':' + arg.slice(offset));
        }
        return new Date(arg);
    }
}

class NoopSerializer {
    serialize(arg) {
        return arg;
    }
    deserialize(arg) {
        return arg;
    }
}

class NumberSerializer extends BaseSerializer {
    serialize(arg) {
        return arg;
    }
    deserialize(arg) {
        return arg;
    }
}

/**
 * Uppercase the first letter of a string, but don't change the remainder.
 */
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
/**
 * Convert underscored, dasherized, or space-delimited words into
 * lowerCamelCase.
 */
function camelize(str) {
    return str
        .replace(/(\-|\_|\.|\s)+(.)?/g, function (match, separator, chr) {
        return chr ? chr.toUpperCase() : '';
    })
        .replace(/(^|\/)([A-Z])/g, function (match) {
        return match.toLowerCase();
    });
}
/**
 * Converts a camelized string into all lowercase separated by underscores.
 */
function decamelize(str) {
    return str.replace(/([a-z\d])([A-Z])/g, '$1_$2').toLowerCase();
}
/**
 * Dasherize words that are underscored, space-delimited, or camelCased.
 */
function dasherize(str) {
    return decamelize(str).replace(/[ _]/g, '-');
}
/**
 * Underscore words that are dasherized, space-delimited, or camelCased.
 */
function underscore(str) {
    return str
        .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
        .replace(/\-|\s+/g, '_')
        .toLowerCase();
}
/**
 * A naive pluralization method.
 */
function pluralize(word) {
    return word + 's';
}
/**
 * A naive singularization method.
 */
function singularize(word) {
    if (word.lastIndexOf('s') === word.length - 1) {
        return word.substr(0, word.length - 1);
    }
    else {
        return word;
    }
}
const standardInflectors = {
    camelize,
    dasherize,
    underscore,
    pluralize,
    singularize
};
const standardInverseInflectors = {
    camelize: null,
    dasherize: 'camelize',
    underscore: 'camelize',
    pluralize: 'singularize',
    singularize: 'pluralize'
};

class StringSerializer extends BaseSerializer {
    constructor(settings) {
        super(settings);
        this.inflectors = (settings === null || settings === void 0 ? void 0 : settings.inflectors) || {};
        this.inverseInflectors = (settings === null || settings === void 0 ? void 0 : settings.inverseInflectors) || {};
        if (this.serializationOptions &&
            this.deserializationOptions === undefined) {
            const { inflectors } = this.serializationOptions;
            this.deserializationOptions = {
                inflectors: this.buildInverseInflectors(inflectors)
            };
        }
    }
    serialize(arg, customOptions) {
        const options = this.buildSerializationOptions(customOptions);
        const { inflectors } = options;
        let result = arg;
        if (inflectors) {
            for (let inflector of inflectors) {
                result = this.applyInflector(inflector, result);
            }
        }
        return result;
    }
    deserialize(arg, customOptions) {
        const options = this.buildDeserializationOptions(customOptions);
        const { inflectors } = options;
        let result = arg;
        if (inflectors) {
            for (let inflector of inflectors) {
                result = this.applyInflector(inflector, result);
            }
        }
        return result;
    }
    buildInverseInflectors(inflectors) {
        if (inflectors) {
            const inverseInflectors = [];
            for (let inflector of inflectors) {
                let inverseInflector;
                if (typeof inflector === 'string') {
                    inverseInflector =
                        this.inverseInflectors[inflector] ||
                            standardInverseInflectors[inflector];
                }
                if (inverseInflector) {
                    inverseInflectors.unshift(inverseInflector);
                }
                else {
                    return;
                }
            }
            return inverseInflectors;
        }
    }
    applyInflector(inflectorOrName, arg) {
        var _a;
        let inflector;
        if (typeof inflectorOrName === 'function') {
            inflector = inflectorOrName;
        }
        else {
            inflector = (_a = this.inflectors[inflectorOrName]) !== null && _a !== void 0 ? _a : standardInflectors[inflectorOrName];
            if (!inflector) {
                throw new Error(`'StringSerializer does not recognize inflector '${inflectorOrName}'`);
            }
        }
        return inflector(arg);
    }
}

function buildSerializerFor(settings) {
    var _a;
    const customSerializers = (_a = settings.serializers) !== null && _a !== void 0 ? _a : {};
    const serializers = {
        ...customSerializers
    };
    const serializerClassFor = settings.serializerClassFor;
    const serializerSettingsFor = settings.serializerSettingsFor;
    function serializerFor(type) {
        var _a;
        return (_a = serializers[type]) !== null && _a !== void 0 ? _a : createSerializer(type);
    }
    function createSerializer(type) {
        const SerializerClass = serializerClassFor && serializerClassFor(type);
        if (SerializerClass) {
            const settings = (serializerSettingsFor && serializerSettingsFor(type)) || {};
            settings.serializerFor = serializerFor;
            return (serializers[type] = new SerializerClass(settings));
        }
    }
    return serializerFor;
}
function buildSerializerClassFor(serializerClasses = {}) {
    return (type) => serializerClasses[type];
}
function buildSerializerSettingsFor(settings) {
    const serializerSettings = {};
    const sharedSettings = settings.sharedSettings || {};
    const settingsByType = settings.settingsByType || {};
    function serializerSettingsFor(type) {
        return serializerSettings[type] || createSerializerSettings(type);
    }
    function createSerializerSettings(type) {
        const settingsForType = settingsByType[type] || {};
        const settings = {
            ...sharedSettings,
            ...settingsForType
        };
        return (serializerSettings[type] = settings);
    }
    return serializerSettingsFor;
}

export { BaseSerializer as B, DateSerializer as D, NoopSerializer as N, StringSerializer as S, BooleanSerializer as a, buildSerializerClassFor as b, DateTimeSerializer as c, NumberSerializer as d, buildSerializerSettingsFor as e, buildSerializerFor as f, capitalize as g, camelize as h, decamelize as i, dasherize as j, standardInflectors as k, standardInverseInflectors as l, pluralize as p, singularize as s, underscore as u };
