import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "../components/theme.js";
const HALF = 30;
export const DurationIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  border: 2px solid ${Theme.colors.primary};
  flex-shrink: 0;
  background-image: linear-gradient(
    to right,
    transparent 50%,
    ${Theme.colors.primary} 0
  );

  &::before {
    content: "";
    display: block;
    margin-left: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%;
    background-color: ${(props) => props.duration > HALF ? Theme.colors.primary : "inherit"};
    transform-origin: left;
    transform: rotate(
      ${(props) => props.duration > HALF ? props.duration / HALF * 180 - 180 : props.duration / HALF * 180}deg
    );
  }
`;
