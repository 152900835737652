import React, {createContext, useContext, useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {Router, Redirect} from "../../../_snowpack/pkg/@reach/router.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {Header} from "./Header.js";
import {Wrap, Theme, Splash} from "../../components/index.js";
import {Templates} from "./Templates/index.js";
import {Account} from "./Account/index.js";
import {Connections} from "./Connections/index.js";
import {useAuth} from "../../../_snowpack/pkg/@ironbay/ship-react.js";
import {memory, remote} from "../../data/orbit.js";
import {Banner} from "./Banner.js";
const Root = styled.div``;
const Content = styled.div`
  padding: ${Theme.spacing.size800} 0;
`;
const BootstrapContext = createContext(false);
export function useBootstrap() {
  return useContext(BootstrapContext);
}
export function Dashboard(_props) {
  const auth = useAuth();
  const [bootstrap, bootstrap_set] = useState(false);
  async function run() {
    await remote.query((q) => [
      q.findRecord({type: "user", id: auth.user}),
      q.findRecords("ship_billing_product")
    ]);
    bootstrap_set(true);
  }
  useEffect(() => {
    run();
  }, []);
  const [self] = memory.cache.query((q) => [
    q.findRecord({type: "user", id: auth.user})
  ]);
  return /* @__PURE__ */ React.createElement(BootstrapContext.Provider, {
    value: bootstrap
  }, /* @__PURE__ */ React.createElement(Root, null, bootstrap && /* @__PURE__ */ React.createElement(Banner, null), /* @__PURE__ */ React.createElement(Header, null), /* @__PURE__ */ React.createElement(Content, null, /* @__PURE__ */ React.createElement(Wrap, {
    width: "lg"
  }, /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Templates, {
    path: "/templates/*"
  }), /* @__PURE__ */ React.createElement(Account, {
    path: "/account/*"
  }), /* @__PURE__ */ React.createElement(Connections, {
    path: "/connections/*"
  }), /* @__PURE__ */ React.createElement(Redirect, {
    noThrow: true,
    from: "/",
    to: "/dashboard/templates"
  })))), /* @__PURE__ */ React.createElement(Splash, {
    active: !self?.attributes?.email
  })));
}
