import React, {useEffect} from "../../../../_snowpack/pkg/react.js";
import {
  PageTitle,
  PageTitleStretch,
  FormGrid,
  FormSectionHeader,
  FormSectionDescription,
  FormSpacer,
  SimpleLink,
  Theme
} from "../../../components/index.js";
import {CalendarRow} from "../../../structures/CalendaroRow.js";
import {FiTrash} from "../../../../_snowpack/pkg/react-icons/fi.js";
import styled from "../../../../_snowpack/pkg/styled-components.js";
import {google_start} from "../../../data/api.js";
import {memory, remote} from "../../../data/orbit.js";
import {useAuth} from "../../../../_snowpack/pkg/@ironbay/ship-react.js";
import {useBootstrap} from "../index.js";
const Split = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Theme.spacing.size1600};
`;
const DeleteIcon = styled(FiTrash)`
  cursor: pointer;
`;
export function Connections(_props) {
  const auth = useAuth();
  const bootstrap = useBootstrap();
  useEffect(() => {
    if (bootstrap)
      remote.query((q) => q.findRelatedRecords({type: "user", id: auth.user}, "google")).catch((ex) => remote.requestQueue.skip(ex));
  }, []);
  const google = memory.cache.query((q) => q.findRelatedRecords({type: "user", id: auth.user}, "google")) || [];
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(PageTitle, null, /* @__PURE__ */ React.createElement(PageTitleStretch, null, "Connections")), /* @__PURE__ */ React.createElement(Split, null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Google Accounts"), /* @__PURE__ */ React.createElement(FormSectionDescription, null, "Connect accounts to sync your Google Calendar.", " ", /* @__PURE__ */ React.createElement(SimpleLink, {
    as: "a",
    href: google_start("/dashboard/connections", auth.user)
  }, "Add new")), /* @__PURE__ */ React.createElement(FormSpacer, null), /* @__PURE__ */ React.createElement(FormGrid, null, google.filter((item) => item.attributes).map((item) => {
    return /* @__PURE__ */ React.createElement(CalendarRow, {
      key: item.id,
      calendar: {
        attributes: {
          name: item.attributes.email
        }
      }
    }, /* @__PURE__ */ React.createElement(DeleteIcon, {
      onClick: async () => {
        memory.update((t) => t.removeRecord({type: "google", id: item.id}));
      }
    }));
  }))), /* @__PURE__ */ React.createElement("div", null)));
}
