import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./theme.js";
import {FiCheck} from "../../_snowpack/pkg/react-icons/fi.js";
import {FormEnter} from "./FormInput.js";
const Root = styled.div`
  display: flex;
`;
export const FormCheck = styled(function FormCheck2({
  value,
  children,
  disabled,
  ...rest
}) {
  return /* @__PURE__ */ React.createElement(Root, null, /* @__PURE__ */ React.createElement("div", {
    ...rest,
    tabIndex: 0,
    onKeyPress: FormEnter(() => rest.onClick && rest.onClick(null))
  }, /* @__PURE__ */ React.createElement(Check, {
    disabled,
    value
  }, /* @__PURE__ */ React.createElement(Icon, {
    value
  })), children && /* @__PURE__ */ React.createElement(Label, {
    disabled
  }, children)));
})`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Check = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms all cubic-bezier(0, 0.67, 0.27, 1);
  border: 1px solid
    ${(props) => props.value && !props.disabled ? Theme.colors.primary : Theme.colors.border};
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: ${(props) => {
  if (props.disabled)
    return Theme.colors.border;
  return props.value && Theme.colors.primary;
}};

  ${FormCheck}:hover & {
    border-color: ${(props) => !props.disabled && Theme.colors.primary};
  }
`;
const Label = styled.label`
  cursor: pointer;
  padding-left: ${Theme.spacing.size200};
  font-weight: 500;
`;
const Icon = styled(FiCheck)`
  color: white;
  transition: 300ms all cubic-bezier(0, 0.67, 0.27, 1);
  opacity: ${(props) => props.value ? 1 : 0};
  transform: ${(props) => props.value ? "initial" : "translate3d(0, 5px, 0)"};
`;
Icon.defaultProps = {
  width: 14
};
