import {Theme} from "./theme.js";
import styled from "../../_snowpack/pkg/styled-components.js";
export const FormSegment = styled.div`
  flex: 1;
`;
export const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => [].concat(props.children).length},
    1fr
  );
  grid-gap: ${Theme.spacing.size400};
`;
export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${Theme.spacing.size400};
`;
export const FormSpacer = styled.div`
  padding: ${Theme.spacing.size200} 0;
`;
export const FormText = styled.div`
  font-weight: 500;
`;
export const FormSection = styled.div`
  padding: ${Theme.spacing.size800};
  background: white;
  border: 1px solid ${Theme.colors.border};
  border-radius: 8px;
`;
export const FormSectionHeader = styled.div`
  font-size: ${Theme.font.size450};
`;
export const FormSectionDescription = styled.div`
  margin-top: ${Theme.spacing.size50};
  color: ${Theme.colors.textLight};
  line-height: ${Theme.spacing.size600};
`;
