import { A as Assertion, O as Orbit } from '../common/log-e4bf9a42.js';
import '../common/source-bf183794.js';
import { v as validateRecordOperation, e as eq, a as equalRecordIdentitySets, b as equalRecordIdentities, r as recordsInclude, Q as QueryExpressionParseError, m as mergeRecords, c as cloneRecordIdentity, d as buildQuery, f as buildTransform, g as recordsReferencedByOperations, O as OperationTerm, s as serializeRecordIdentity, h as deserializeRecordIdentity, R as RecordSource, q as queryable, u as updatable } from '../common/record-source-87b54fe8.js';
import { p as pullable, a as pushable } from '../common/pushable-a80774d7.js';
import { L as LiveQuery, g as getInverseRelationship, a as getInverseRelationships, b as getAllInverseRelationships, c as getInverseRelationshipRemovalOps, r as recordUpdated, d as recordRemoved, e as relatedRecordRemoved, f as relatedRecordsReplaced, h as relatedRecordReplaced, i as relatedRecordAdded, j as recordAdded, R as RecordCache, S as SimpleRecordTransformBuffer, s as syncable } from '../common/simple-record-transform-buffer-13615e1a.js';
import { V as ValidationError, R as RecordNotFoundException } from '../common/standard-validators-4ed2ae82.js';
import { d as deepGet, a as deepSet, i as isNone, c as clone, t as toArray } from '../common/objects-398cfae3.js';

/**
 * Operation processors are used to identify operations that should be performed
 * together to ensure that a `Cache` or other container of data remains
 * consistent and correct.
 *
 * `OperationProcessor` is an abstract base class to be extended by specific
 * operation processors.
 */
class AsyncOperationProcessor {
    constructor(accessor) {
        this._accessor = accessor;
    }
    /**
     * The `AsyncRecordAccessor` that is monitored.
     */
    get accessor() {
        return this._accessor;
    }
    /**
     * Called when all the data in a cache has been reset.
     *
     * If `base` is included, the cache is being reset to match a base cache.
     */
    reset(base) {
        return Promise.resolve();
    }
    /**
     * Allow the processor to perform an upgrade as part of a cache upgrade.
     */
    upgrade() {
        return Promise.resolve();
    }
    /**
     * Validates an operation before processing it.
     */
    validate(operation) {
        return Promise.resolve();
    }
    /**
     * Called before an `operation` has been applied.
     *
     * Returns an array of operations to be applied **BEFORE** the `operation`
     * itself is applied.
     */
    before(operation) {
        return Promise.resolve([]);
    }
    /**
     * Called before an `operation` has been applied.
     *
     * Returns an array of operations to be applied **AFTER** the `operation`
     * has been applied successfully.
     */
    after(operation) {
        return Promise.resolve([]);
    }
    /**
     * Called immediately after an `operation` has been applied and before the
     * `patch` event has been emitted (i.e. before any listeners have been
     * notified that the operation was applied).
     *
     * No operations may be returned.
     */
    immediate(operation) {
        return Promise.resolve();
    }
    /**
     * Called after an `operation` _and_ any related operations have been applied.
     *
     * Returns an array of operations to be applied **AFTER** the `operation`
     * itself and any operations returned from the `after` hook have been applied.
     */
    finally(operation) {
        return Promise.resolve([]);
    }
}

class AsyncLiveQueryUpdate {
    constructor(settings) {
        this._cache = settings.cache;
        this._query = settings.query;
    }
    query() {
        return this._cache.query(this._query);
    }
}
class AsyncLiveQuery extends LiveQuery {
    constructor(settings) {
        super(settings);
        this.cache = settings.cache;
    }
    get schema() {
        return this.cache.schema;
    }
    get _update() {
        return new AsyncLiveQueryUpdate({
            cache: this.cache,
            query: this._query
        });
    }
    query() {
        return this._update.query();
    }
    subscribe(cb) {
        return this._subscribe(() => {
            cb(this._update);
        });
    }
}

/**
 * An operation processor that ensures that a cache's data is consistent and
 * doesn't contain any dead references.
 *
 * This is achieved by maintaining a mapping of reverse relationships for each
 * record. When a record is removed, any references to it can also be identified
 * and removed.
 */
class AsyncCacheIntegrityProcessor extends AsyncOperationProcessor {
    async after(operation) {
        switch (operation.op) {
            case 'replaceRelatedRecord':
                await this.removeInverseRelationship(operation.record, operation.relationship, await this.accessor.getRelatedRecordAsync(operation.record, operation.relationship));
                return [];
            case 'replaceRelatedRecords':
                await this.removeInverseRelationships(operation.record, operation.relationship, await this.accessor.getRelatedRecordsAsync(operation.record, operation.relationship));
                return [];
            case 'removeFromRelatedRecords':
                await this.removeInverseRelationship(operation.record, operation.relationship, operation.relatedRecord);
                return [];
            case 'removeRecord':
                await this.removeAllInverseRelationships(operation.record);
                return [];
            case 'updateRecord':
                await this.removeAllInverseRelationships(operation.record);
                return [];
            default:
                return [];
        }
    }
    async finally(operation) {
        switch (operation.op) {
            case 'replaceRelatedRecord':
                await this.addInverseRelationship(operation.record, operation.relationship, operation.relatedRecord);
                return [];
            case 'replaceRelatedRecords':
                await this.addInverseRelationships(operation.record, operation.relationship, operation.relatedRecords);
                return [];
            case 'addToRelatedRecords':
                await this.addInverseRelationship(operation.record, operation.relationship, operation.relatedRecord);
                return [];
            case 'addRecord':
                await this.addAllInverseRelationships(operation.record);
                return [];
            case 'updateRecord':
                await this.addAllInverseRelationships(operation.record);
                return [];
            case 'removeRecord':
                return await this.clearInverseRelationshipOps(operation.record);
            default:
                return [];
        }
    }
    async addInverseRelationship(record, relationship, relatedRecord) {
        let inverseRelationship = getInverseRelationship(this.accessor.schema, record, relationship, relatedRecord);
        if (inverseRelationship) {
            await this.accessor.addInverseRelationshipsAsync([inverseRelationship]);
        }
    }
    async addInverseRelationships(record, relationship, relatedRecords) {
        let inverseRelationships = getInverseRelationships(this.accessor.schema, record, relationship, relatedRecords);
        if (inverseRelationships) {
            await this.accessor.addInverseRelationshipsAsync(inverseRelationships);
        }
    }
    async addAllInverseRelationships(record) {
        let inverseRelationships = getAllInverseRelationships(this.accessor.schema, record);
        if (inverseRelationships.length > 0) {
            await this.accessor.addInverseRelationshipsAsync(inverseRelationships);
        }
    }
    async removeInverseRelationship(record, relationship, relatedRecord) {
        let inverseRelationship = getInverseRelationship(this.accessor.schema, record, relationship, relatedRecord);
        if (inverseRelationship) {
            await this.accessor.removeInverseRelationshipsAsync([
                inverseRelationship
            ]);
        }
    }
    async removeInverseRelationships(record, relationship, relatedRecords) {
        let inverseRelationships = getInverseRelationships(this.accessor.schema, record, relationship, relatedRecords);
        if (inverseRelationships.length > 0) {
            await this.accessor.removeInverseRelationshipsAsync(inverseRelationships);
        }
    }
    async removeAllInverseRelationships(record) {
        const currentRecord = await this.accessor.getRecordAsync(record);
        if (currentRecord) {
            const inverseRelationships = getAllInverseRelationships(this.accessor.schema, currentRecord);
            if (inverseRelationships.length > 0) {
                await this.accessor.removeInverseRelationshipsAsync(inverseRelationships);
            }
        }
    }
    async clearInverseRelationshipOps(record) {
        return getInverseRelationshipRemovalOps(this.accessor.schema, await this.accessor.getInverseRelationshipsAsync(record));
    }
}

/**
 * An operation processor that ensures that a cache's data is consistent with
 * its associated schema. This includes maintenance of inverse and dependent
 * relationships.
 */
class AsyncSchemaConsistencyProcessor extends AsyncOperationProcessor {
    async after(operation) {
        switch (operation.op) {
            case 'addRecord':
                return recordAdded(this.accessor.schema, operation.record);
            case 'addToRelatedRecords':
                return relatedRecordAdded(this.accessor.schema, operation.record, operation.relationship, operation.relatedRecord);
            case 'replaceRelatedRecord':
                return relatedRecordReplaced(this.accessor.schema, operation.record, operation.relationship, operation.relatedRecord, await this.accessor.getRelatedRecordAsync(operation.record, operation.relationship));
            case 'replaceRelatedRecords':
                return relatedRecordsReplaced(this.accessor.schema, operation.record, operation.relationship, operation.relatedRecords, await this.accessor.getRelatedRecordsAsync(operation.record, operation.relationship));
            case 'removeFromRelatedRecords':
                return relatedRecordRemoved(this.accessor.schema, operation.record, operation.relationship, operation.relatedRecord, await this.accessor.getRecordAsync(operation.relatedRecord));
            case 'removeRecord':
                return recordRemoved(this.accessor.schema, await this.accessor.getRecordAsync(operation.record));
            case 'updateRecord':
                return recordUpdated(this.accessor.schema, operation.record, await this.accessor.getRecordAsync(operation.record));
            default:
                return [];
        }
    }
}

/**
 * An operation processor that ensures that an operation is compatible with
 * its associated schema.
 */
class AsyncSchemaValidationProcessor extends AsyncOperationProcessor {
    constructor(accessor) {
        super(accessor);
        const cache = this.accessor;
        const { schema, validatorFor } = cache;
        if (validatorFor === undefined || schema === undefined) {
            throw new Assertion('SyncSchemaValidationProcessor requires a RecordCache with both a `validationFor` and a `schema`.');
        }
        this.schema = schema;
        this.validatorFor = validatorFor;
    }
    async validate(operation) {
        const { schema, validatorFor } = this;
        const issues = validateRecordOperation(operation, { schema, validatorFor });
        if (issues) {
            throw new ValidationError('Validation failed', issues);
        }
    }
}

const AsyncInverseTransformOperators = {
    async addRecord(cache, operation, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options) {
        const op = operation;
        const { type, id } = op.record;
        const current = await cache.getRecordAsync(op.record);
        if (current) {
            if (eq(current, op.record)) {
                return;
            }
            else {
                return {
                    op: 'updateRecord',
                    record: current
                };
            }
        }
        else {
            return {
                op: 'removeRecord',
                record: { type, id }
            };
        }
    },
    async updateRecord(cache, operation, options) {
        const op = operation;
        const currentRecord = await cache.getRecordAsync(op.record);
        const replacement = op.record;
        const { type, id } = replacement;
        if (currentRecord) {
            let result = { type, id };
            let changed = false;
            ['attributes', 'keys'].forEach((grouping) => {
                if (replacement[grouping]) {
                    Object.keys(replacement[grouping]).forEach((field) => {
                        let value = replacement[grouping][field];
                        let currentValue = deepGet(currentRecord, [grouping, field]);
                        if (!eq(value, currentValue)) {
                            changed = true;
                            deepSet(result, [grouping, field], currentValue === undefined ? null : currentValue);
                        }
                    });
                }
            });
            if (replacement.relationships) {
                Object.keys(replacement.relationships).forEach((field) => {
                    let data = deepGet(replacement, ['relationships', field, 'data']);
                    if (data !== undefined) {
                        let currentData = deepGet(currentRecord, [
                            'relationships',
                            field,
                            'data'
                        ]);
                        let relationshipChanged;
                        if (Array.isArray(data)) {
                            if (currentData) {
                                relationshipChanged = !equalRecordIdentitySets(currentData, data);
                            }
                            else {
                                relationshipChanged = true;
                                currentData = [];
                            }
                        }
                        else {
                            if (currentData) {
                                relationshipChanged = !equalRecordIdentities(currentData, data);
                            }
                            else {
                                relationshipChanged = true;
                                currentData = null;
                            }
                        }
                        if (relationshipChanged) {
                            changed = true;
                            deepSet(result, ['relationships', field, 'data'], currentData);
                        }
                    }
                });
            }
            if (changed) {
                return {
                    op: 'updateRecord',
                    record: result
                };
            }
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(type, id);
            }
            else {
                return {
                    op: 'removeRecord',
                    record: { type, id }
                };
            }
        }
    },
    async removeRecord(cache, operation, options) {
        const op = operation;
        const { record } = op;
        const currentRecord = await cache.getRecordAsync(record);
        if (currentRecord) {
            return {
                op: 'addRecord',
                record: currentRecord
            };
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
    },
    async replaceKey(cache, operation, options) {
        const op = operation;
        const { record, key } = op;
        const currentRecord = await cache.getRecordAsync(record);
        if (currentRecord === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        const currentValue = currentRecord && deepGet(currentRecord, ['keys', key]);
        if (!eq(currentValue, op.value)) {
            const { type, id } = op.record;
            return {
                op: 'replaceKey',
                record: { type, id },
                key,
                value: currentValue
            };
        }
    },
    async replaceAttribute(cache, operation, options) {
        const op = operation;
        const { record, attribute } = op;
        const currentRecord = await cache.getRecordAsync(record);
        if (currentRecord === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        const currentValue = currentRecord && deepGet(currentRecord, ['attributes', attribute]);
        if (!eq(currentValue, op.value)) {
            const { type, id } = record;
            return {
                op: 'replaceAttribute',
                record: { type, id },
                attribute,
                value: currentValue
            };
        }
    },
    async addToRelatedRecords(cache, operation, options) {
        const op = operation;
        const { record, relationship, relatedRecord } = op;
        const currentRelatedRecords = await cache.getRelatedRecordsAsync(record, relationship);
        if (currentRelatedRecords === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                if ((await cache.getRecordAsync(record)) === undefined) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
            }
        }
        if (currentRelatedRecords === undefined ||
            !recordsInclude(currentRelatedRecords, relatedRecord)) {
            return {
                op: 'removeFromRelatedRecords',
                record,
                relationship,
                relatedRecord
            };
        }
    },
    async removeFromRelatedRecords(cache, operation, options) {
        const op = operation;
        const { record, relationship, relatedRecord } = op;
        const currentRelatedRecords = await cache.getRelatedRecordsAsync(record, relationship);
        if (currentRelatedRecords === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                if ((await cache.getRecordAsync(record)) === undefined) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
            }
        }
        if (currentRelatedRecords !== undefined &&
            recordsInclude(currentRelatedRecords, relatedRecord)) {
            return {
                op: 'addToRelatedRecords',
                record,
                relationship,
                relatedRecord
            };
        }
    },
    async replaceRelatedRecords(cache, operation, options) {
        const op = operation;
        const { record, relationship, relatedRecords } = op;
        const currentRelatedRecords = await cache.getRelatedRecordsAsync(record, relationship);
        if (currentRelatedRecords === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                if ((await cache.getRecordAsync(record)) === undefined) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
            }
        }
        if (currentRelatedRecords === undefined ||
            !equalRecordIdentitySets(currentRelatedRecords, relatedRecords)) {
            return {
                op: 'replaceRelatedRecords',
                record,
                relationship,
                relatedRecords: currentRelatedRecords || []
            };
        }
    },
    async replaceRelatedRecord(cache, operation, options) {
        const op = operation;
        const { record, relationship, relatedRecord } = op;
        const currentRelatedRecord = await cache.getRelatedRecordAsync(record, relationship);
        if (currentRelatedRecord === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                if ((await cache.getRecordAsync(record)) === undefined) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
            }
        }
        if (currentRelatedRecord === undefined ||
            !equalRecordIdentities(currentRelatedRecord, relatedRecord)) {
            return {
                op: 'replaceRelatedRecord',
                record,
                relationship,
                relatedRecord: currentRelatedRecord || null
            };
        }
    }
};

const AsyncQueryOperators = {
    async findRecord(cache, expression, options) {
        const { record } = expression;
        const currentRecord = await cache.getRecordAsync(record);
        if (!currentRecord) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        return currentRecord;
    },
    async findRecords(cache, expression, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options) {
        const exp = expression;
        let results = await cache.getRecordsAsync(exp.records || exp.type);
        if (exp.filter) {
            results = filterRecords(results, exp.filter);
        }
        if (exp.sort) {
            results = sortRecords(results, exp.sort);
        }
        if (exp.page) {
            results = paginateRecords(results, exp.page);
        }
        return results;
    },
    async findRelatedRecords(cache, expression, options) {
        const exp = expression;
        const { record, relationship } = exp;
        const relatedIds = await cache.getRelatedRecordsAsync(record, relationship);
        if (!relatedIds || relatedIds.length === 0) {
            if (!(await cache.getRecordAsync(record))) {
                if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
                else {
                    return undefined;
                }
            }
            return [];
        }
        let results = await cache.getRecordsAsync(relatedIds);
        if (exp.filter) {
            results = filterRecords(results, exp.filter);
        }
        if (exp.sort) {
            results = sortRecords(results, exp.sort);
        }
        if (exp.page) {
            results = paginateRecords(results, exp.page);
        }
        return results;
    },
    async findRelatedRecord(cache, expression, options) {
        const exp = expression;
        const { record, relationship } = exp;
        const relatedId = await cache.getRelatedRecordAsync(record, relationship);
        if (relatedId) {
            return (await cache.getRecordAsync(relatedId)) || null;
        }
        else {
            if (!(await cache.getRecordAsync(record))) {
                if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
                else {
                    return undefined;
                }
            }
            return null;
        }
    }
};
function filterRecords(records, filters) {
    return records.filter((record) => {
        for (let i = 0, l = filters.length; i < l; i++) {
            if (!applyFilter(record, filters[i])) {
                return false;
            }
        }
        return true;
    });
}
function applyFilter(record, filter) {
    if (filter.kind === 'attribute') {
        let actual = deepGet(record, ['attributes', filter.attribute]);
        if (actual === undefined) {
            return false;
        }
        let expected = filter.value;
        switch (filter.op) {
            case 'equal':
                return actual === expected;
            case 'gt':
                return actual > expected;
            case 'gte':
                return actual >= expected;
            case 'lt':
                return actual < expected;
            case 'lte':
                return actual <= expected;
            default:
                throw new QueryExpressionParseError('Filter operation ${filter.op} not recognized for Store.');
        }
    }
    else if (filter.kind === 'relatedRecords') {
        let actual = deepGet(record, [
            'relationships',
            filter.relation,
            'data'
        ]);
        if (actual === undefined) {
            return false;
        }
        let expected = filter.records;
        switch (filter.op) {
            case 'equal':
                return (actual.length === expected.length &&
                    expected.every((e) => actual.some((a) => a.id === e.id && a.type === e.type)));
            case 'all':
                return expected.every((e) => actual.some((a) => a.id === e.id && a.type === e.type));
            case 'some':
                return expected.some((e) => actual.some((a) => a.id === e.id && a.type === e.type));
            case 'none':
                return !expected.some((e) => actual.some((a) => a.id === e.id && a.type === e.type));
            default:
                throw new QueryExpressionParseError('Filter operation ${filter.op} not recognized for Store.');
        }
    }
    else if (filter.kind === 'relatedRecord') {
        let actual = deepGet(record, ['relationships', filter.relation, 'data']);
        if (actual === undefined) {
            return false;
        }
        let expected = filter.record;
        switch (filter.op) {
            case 'equal':
                if (actual === null) {
                    return expected === null;
                }
                else {
                    if (Array.isArray(expected)) {
                        return expected.some((e) => actual.type === e.type && actual.id === e.id);
                    }
                    else if (expected) {
                        return actual.type === expected.type && actual.id === expected.id;
                    }
                    else {
                        return false;
                    }
                }
            default:
                throw new QueryExpressionParseError('Filter operation ${filter.op} not recognized for Store.');
        }
    }
    return false;
}
function sortRecords(records, sortSpecifiers) {
    const comparisonValues = new Map();
    records.forEach((record) => {
        comparisonValues.set(record, sortSpecifiers.map((sortSpecifier) => {
            if (sortSpecifier.kind === 'attribute') {
                return deepGet(record, [
                    'attributes',
                    sortSpecifier.attribute
                ]);
            }
            else {
                throw new QueryExpressionParseError('Sort specifier ${sortSpecifier.kind} not recognized for Store.');
            }
        }));
    });
    const comparisonOrders = sortSpecifiers.map((sortExpression) => sortExpression.order === 'descending' ? -1 : 1);
    return records.sort((record1, record2) => {
        const values1 = comparisonValues.get(record1);
        const values2 = comparisonValues.get(record2);
        for (let i = 0; i < sortSpecifiers.length; i++) {
            if (values1[i] < values2[i]) {
                return -comparisonOrders[i];
            }
            else if (values1[i] > values2[i]) {
                return comparisonOrders[i];
            }
            else if (isNone(values1[i]) && !isNone(values2[i])) {
                return comparisonOrders[i];
            }
            else if (isNone(values2[i]) && !isNone(values1[i])) {
                return -comparisonOrders[i];
            }
        }
        return 0;
    });
}
function paginateRecords(records, paginationOptions) {
    if (paginationOptions.limit !== undefined) {
        let offset = paginationOptions.offset === undefined ? 0 : paginationOptions.offset;
        let limit = paginationOptions.limit;
        return records.slice(offset, offset + limit);
    }
    else {
        throw new QueryExpressionParseError('Pagination options not recognized for Store. Please specify `offset` and `limit`.');
    }
}

const AsyncTransformOperators = {
    async addRecord(cache, operation, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options) {
        const op = operation;
        const { record } = op;
        await cache.setRecordAsync(record);
        if (cache.keyMap) {
            cache.keyMap.pushRecord(record);
        }
        return record;
    },
    async updateRecord(cache, operation, options) {
        const op = operation;
        const { record } = op;
        const currentRecord = await cache.getRecordAsync(record);
        if (currentRecord === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        const mergedRecord = mergeRecords(currentRecord || null, record);
        await cache.setRecordAsync(mergedRecord);
        if (cache.keyMap) {
            cache.keyMap.pushRecord(mergedRecord);
        }
        return mergedRecord;
    },
    async removeRecord(cache, operation, options) {
        const op = operation;
        const record = await cache.removeRecordAsync(op.record);
        if (record === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(op.record.type, op.record.id);
            }
        }
        return record;
    },
    async replaceKey(cache, operation, options) {
        const op = operation;
        const currentRecord = await cache.getRecordAsync(op.record);
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        deepSet(record, ['keys', op.key], op.value);
        await cache.setRecordAsync(record);
        if (cache.keyMap) {
            cache.keyMap.pushRecord(record);
        }
        return record;
    },
    async replaceAttribute(cache, operation, options) {
        const op = operation;
        const currentRecord = await cache.getRecordAsync(op.record);
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        deepSet(record, ['attributes', op.attribute], op.value);
        await cache.setRecordAsync(record);
        return record;
    },
    async addToRelatedRecords(cache, operation, options) {
        const op = operation;
        const { relationship, relatedRecord } = op;
        const currentRecord = await cache.getRecordAsync(op.record);
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        const relatedRecords = deepGet(record, ['relationships', relationship, 'data']) || [];
        if (!recordsInclude(relatedRecords, relatedRecord)) {
            relatedRecords.push(relatedRecord);
            deepSet(record, ['relationships', relationship, 'data'], relatedRecords);
            await cache.setRecordAsync(record);
        }
        return record;
    },
    async removeFromRelatedRecords(cache, operation, options) {
        const op = operation;
        const currentRecord = await cache.getRecordAsync(op.record);
        const { relationship, relatedRecord } = op;
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
            let relatedRecords = deepGet(record, [
                'relationships',
                relationship,
                'data'
            ]);
            if (relatedRecords) {
                relatedRecords = relatedRecords.filter((r) => !equalRecordIdentities(r, relatedRecord));
                if (deepSet(record, ['relationships', relationship, 'data'], relatedRecords)) {
                    await cache.setRecordAsync(record);
                }
            }
            return record;
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(op.record.type, op.record.id);
            }
        }
    },
    async replaceRelatedRecords(cache, operation, options) {
        const op = operation;
        const currentRecord = await cache.getRecordAsync(op.record);
        const { relationship, relatedRecords } = op;
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        if (deepSet(record, ['relationships', relationship, 'data'], relatedRecords)) {
            await cache.setRecordAsync(record);
        }
        return record;
    },
    async replaceRelatedRecord(cache, operation, options) {
        const op = operation;
        const currentRecord = await cache.getRecordAsync(op.record);
        const { relationship, relatedRecord } = op;
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        if (deepSet(record, ['relationships', relationship, 'data'], relatedRecord)) {
            await cache.setRecordAsync(record);
        }
        return record;
    }
};

const { assert, deprecate } = Orbit;
class AsyncRecordCache extends RecordCache {
    constructor(settings) {
        var _a, _b, _c;
        super(settings);
        this._queryOperators = (_a = settings.queryOperators) !== null && _a !== void 0 ? _a : AsyncQueryOperators;
        this._transformOperators = (_b = settings.transformOperators) !== null && _b !== void 0 ? _b : AsyncTransformOperators;
        this._inverseTransformOperators = (_c = settings.inverseTransformOperators) !== null && _c !== void 0 ? _c : AsyncInverseTransformOperators;
        this._debounceLiveQueries = settings.debounceLiveQueries !== false;
        this._transformBuffer = settings.transformBuffer;
        const processors = settings.processors
            ? settings.processors
            : [AsyncSchemaConsistencyProcessor, AsyncCacheIntegrityProcessor];
        if (Orbit.debug && settings.processors === undefined) {
            processors.push(AsyncSchemaValidationProcessor);
        }
        this._processors = processors.map((Processor) => {
            let processor = new Processor(this);
            assert('Each processor must extend AsyncOperationProcessor', processor instanceof AsyncOperationProcessor);
            return processor;
        });
    }
    get processors() {
        return this._processors;
    }
    getQueryOperator(op) {
        return this._queryOperators[op];
    }
    getTransformOperator(op) {
        return this._transformOperators[op];
    }
    getInverseTransformOperator(op) {
        return this._inverseTransformOperators[op];
    }
    async applyRecordChangesetAsync(changeset) {
        const { setRecords, removeRecords, addInverseRelationships, removeInverseRelationships } = changeset;
        const promises = [];
        if (setRecords && setRecords.length > 0) {
            promises.push(await this.setRecordsAsync(setRecords));
        }
        if (removeRecords && removeRecords.length > 0) {
            promises.push(await this.removeRecordsAsync(removeRecords));
        }
        if (addInverseRelationships && addInverseRelationships.length > 0) {
            promises.push(await this.addInverseRelationshipsAsync(addInverseRelationships));
        }
        if (removeInverseRelationships && removeInverseRelationships.length > 0) {
            promises.push(await this.removeInverseRelationshipsAsync(removeInverseRelationships));
        }
        await Promise.all(promises);
    }
    async getRelatedRecordAsync(identity, relationship) {
        const record = await this.getRecordAsync(identity);
        if (record) {
            return deepGet(record, ['relationships', relationship, 'data']);
        }
        return undefined;
    }
    async getRelatedRecordsAsync(identity, relationship) {
        const record = await this.getRecordAsync(identity);
        if (record) {
            return deepGet(record, ['relationships', relationship, 'data']);
        }
        return undefined;
    }
    async query(queryOrExpressions, options, id) {
        const query = buildQuery(queryOrExpressions, options, id, this._queryBuilder);
        const response = await this._query(query, options);
        if (options === null || options === void 0 ? void 0 : options.fullResponse) {
            return response;
        }
        else {
            return response.data;
        }
    }
    async update(transformOrOperations, options, id) {
        const transform = buildTransform(transformOrOperations, options, id, this._transformBuilder);
        const response = await this._update(transform, options);
        if (options === null || options === void 0 ? void 0 : options.fullResponse) {
            return response;
        }
        else {
            return response.data;
        }
    }
    /**
     * Patches the cache with an operation or operations.
     *
     * @deprecated since v0.17
     */
    async patch(operationOrOperations) {
        deprecate('AsyncRecordCache#patch has been deprecated. Use AsyncRecordCache#update instead.');
        // TODO - Why is this `this` cast necessary for TS to understand the correct
        // method overload?
        const { data, details } = await this.update(operationOrOperations, {
            fullResponse: true
        });
        return {
            inverse: (details === null || details === void 0 ? void 0 : details.inverseOperations) || [],
            data: Array.isArray(data) ? data : [data]
        };
    }
    liveQuery(queryOrExpressions, options, id) {
        const query = buildQuery(queryOrExpressions, options, id, this.queryBuilder);
        let debounce = options && options.debounce;
        if (typeof debounce !== 'boolean') {
            debounce = this._debounceLiveQueries;
        }
        return new AsyncLiveQuery({
            debounce,
            cache: this,
            query
        });
    }
    /////////////////////////////////////////////////////////////////////////////
    // Protected methods
    /////////////////////////////////////////////////////////////////////////////
    async _query(query, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options) {
        let data;
        if (Array.isArray(query.expressions)) {
            data = [];
            for (let expression of query.expressions) {
                const queryOperator = this.getQueryOperator(expression.op);
                if (!queryOperator) {
                    throw new Error(`Unable to find query operator: ${expression.op}`);
                }
                data.push(await queryOperator(this, expression, this.getQueryOptions(query, expression)));
            }
        }
        else {
            const expression = query.expressions;
            const queryOperator = this.getQueryOperator(expression.op);
            if (!queryOperator) {
                throw new Error(`Unable to find query operator: ${expression.op}`);
            }
            data = await queryOperator(this, expression, this.getQueryOptions(query, expression));
        }
        return { data: data };
    }
    async _update(transform, options) {
        var _a, _b;
        if ((_a = this.getTransformOptions(transform)) === null || _a === void 0 ? void 0 : _a.useBuffer) {
            const buffer = await this._initTransformBuffer(transform);
            buffer.startTrackingChanges();
            const response = buffer.update(transform, {
                fullResponse: true
            });
            const changes = buffer.stopTrackingChanges();
            await this.applyRecordChangesetAsync(changes);
            const { appliedOperations, appliedOperationResults } = response.details;
            for (let i = 0, len = appliedOperations.length; i < len; i++) {
                this.emit('patch', appliedOperations[i], appliedOperationResults[i]);
            }
            return response;
        }
        else {
            const response = {
                data: []
            };
            if (options === null || options === void 0 ? void 0 : options.fullResponse) {
                response.details = {
                    appliedOperations: [],
                    appliedOperationResults: [],
                    inverseOperations: []
                };
            }
            let data;
            if (Array.isArray(transform.operations)) {
                await this._applyTransformOperations(transform, transform.operations, response, true);
                data = response.data;
            }
            else {
                await this._applyTransformOperation(transform, transform.operations, response, true);
                if (Array.isArray(response.data)) {
                    data = response.data[0];
                }
            }
            if (options === null || options === void 0 ? void 0 : options.fullResponse) {
                (_b = response.details) === null || _b === void 0 ? void 0 : _b.inverseOperations.reverse();
            }
            return {
                ...response,
                data
            };
        }
    }
    _getTransformBuffer() {
        if (this._transformBuffer === undefined) {
            throw new Assertion('transformBuffer must be provided to cache via constructor settings');
        }
        return this._transformBuffer;
    }
    async _initTransformBuffer(transform) {
        const buffer = this._getTransformBuffer();
        const records = recordsReferencedByOperations(toArray(transform.operations));
        const inverseRelationships = await this.getInverseRelationshipsAsync(records);
        const relatedRecords = inverseRelationships.map((ir) => ir.record);
        Array.prototype.push.apply(records, relatedRecords);
        buffer.resetState();
        buffer.setRecordsSync(await this.getRecordsAsync(records));
        buffer.addInverseRelationshipsSync(inverseRelationships);
        return buffer;
    }
    async _applyTransformOperations(transform, ops, response, primary = false) {
        for (let op of ops) {
            await this._applyTransformOperation(transform, op, response, primary);
        }
    }
    async _applyTransformOperation(transform, operation, response, primary = false) {
        var _a, _b, _c, _d;
        if (operation instanceof OperationTerm) {
            operation = operation.toOperation();
        }
        for (let processor of this._processors) {
            await processor.validate(operation);
        }
        const inverseTransformOperator = this.getInverseTransformOperator(operation.op);
        const inverseOp = await inverseTransformOperator(this, operation, this.getTransformOptions(transform, operation));
        if (inverseOp) {
            (_b = (_a = response.details) === null || _a === void 0 ? void 0 : _a.inverseOperations) === null || _b === void 0 ? void 0 : _b.push(inverseOp);
            // Query and perform related `before` operations
            for (let processor of this._processors) {
                await this._applyTransformOperations(transform, await processor.before(operation), response);
            }
            // Query related `after` operations before performing
            // the requested operation. These will be applied on success.
            let preparedOps = [];
            for (let processor of this._processors) {
                preparedOps.push(await processor.after(operation));
            }
            // Perform the requested operation
            let transformOperator = this.getTransformOperator(operation.op);
            let data = await transformOperator(this, operation, this.getTransformOptions(transform, operation));
            if (primary) {
                (_c = response.data) === null || _c === void 0 ? void 0 : _c.push(data);
            }
            if (response.details) {
                response.details.appliedOperationResults.push(data);
                response.details.appliedOperations.push(operation);
            }
            // Query and perform related `immediate` operations
            for (let processor of this._processors) {
                await processor.immediate(operation);
            }
            // Emit event
            this.emit('patch', operation, data);
            // Perform prepared operations after performing the requested operation
            for (let ops of preparedOps) {
                await this._applyTransformOperations(transform, ops, response);
            }
            // Query and perform related `finally` operations
            for (let processor of this._processors) {
                await this._applyTransformOperations(transform, await processor.finally(operation), response);
            }
        }
        else if (primary) {
            (_d = response.data) === null || _d === void 0 ? void 0 : _d.push(undefined);
        }
    }
}

function supportsIndexedDB() {
    return !!Orbit.globals.indexedDB;
}

const { assert: assert$1 } = Orbit;
const INVERSE_RELS = '__inverseRels__';
const DB_NOT_OPEN = 'IndexedDB database is not yet open';
/**
 * A cache used to access records in an IndexedDB database.
 *
 * Because IndexedDB access is async, this cache extends `AsyncRecordCache`.
 */
class IndexedDBCache extends AsyncRecordCache {
    constructor(settings) {
        assert$1('Your browser does not support IndexedDB!', supportsIndexedDB());
        super(settings);
        this._namespace = settings.namespace || 'orbit';
    }
    get namespace() {
        return this._namespace;
    }
    async upgrade() {
        await this.reopenDB();
        for (let processor of this._processors) {
            await processor.upgrade();
        }
    }
    async reset() {
        await this.deleteDB();
        for (let processor of this._processors) {
            await processor.reset();
        }
    }
    /**
     * The version to specify when opening the IndexedDB database.
     */
    get dbVersion() {
        return this._schema.version;
    }
    /**
     * IndexedDB database name.
     *
     * Defaults to the namespace of the app, which can be overridden in the constructor.
     */
    get dbName() {
        return this._namespace;
    }
    get isDBOpen() {
        return !!this._db;
    }
    openDB() {
        return (this._openingDB = new Promise((resolve, reject) => {
            if (this._db) {
                resolve(this._db);
            }
            else {
                let request = Orbit.globals.indexedDB.open(this.dbName, this.dbVersion);
                request.onsuccess = () => {
                    const db = (this._db = request.result);
                    resolve(db);
                };
                request.onerror = () => {
                    reject(request.error);
                };
                request.onupgradeneeded = (event) => {
                    // console.log('indexedDB upgrade needed');
                    const db = (this._db = event.target.result);
                    if (event && event.oldVersion > 0) {
                        this.migrateDB(db, event);
                    }
                    else {
                        this.createDB(db);
                    }
                };
            }
        }));
    }
    async closeDB() {
        if (this.isDBOpen) {
            // Finish opening DB before closing it to avoid problems
            if (this._openingDB) {
                await this._openingDB;
                this._openingDB = undefined;
            }
            if (this._db) {
                this._db.close();
                this._db = undefined;
            }
        }
    }
    async reopenDB() {
        await this.closeDB();
        return this.openDB();
    }
    /**
     * Initializes the contents of the database.
     *
     * Idempotently register models which do not yet have corresponding object
     * stores. Also, creates an object store for tracking inverse relationships,
     * if it is missing.
     *
     * Override this method and/or `registerModel` to provide more advanced
     * db initialization.
     */
    createDB(db) {
        const objectStoreNames = db.objectStoreNames;
        Object.keys(this.schema.models).forEach((model) => {
            if (!objectStoreNames.contains(model)) {
                this.registerModel(db, model);
            }
        });
        if (!objectStoreNames.contains(INVERSE_RELS)) {
            this.createInverseRelationshipStore(db);
        }
    }
    createInverseRelationshipStore(db) {
        let objectStore = db.createObjectStore(INVERSE_RELS, { keyPath: 'id' });
        objectStore.createIndex('recordIdentity', 'recordIdentity', {
            unique: false
        });
        objectStore.createIndex('relatedIdentity', 'relatedIdentity', {
            unique: false
        });
    }
    /**
     * Migrates the database to align with an updated schema.
     *
     * By default, this will attempt a naive migration by invoking `createDB`,
     * which idempotently creates object stores as needed.
     *
     * Override this method to provide more sophisticated migrations.
     */
    migrateDB(db, event) {
        if (Orbit.debug) {
            console.log(`IndexedDBCache#migrateDB - upgrading ${event.oldVersion} -> ${event.newVersion}`);
        }
        // Attempt naive migration, creating object stores as needed.
        this.createDB(db);
    }
    async deleteDB() {
        await this.closeDB();
        return new Promise((resolve, reject) => {
            let request = Orbit.globals.indexedDB.deleteDatabase(this.dbName);
            request.onsuccess = () => {
                resolve();
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }
    createTransaction(storeNames, mode) {
        if (!this._db)
            throw new Error(DB_NOT_OPEN);
        return this._db.transaction(storeNames, mode);
    }
    registerModel(db, type) {
        db.createObjectStore(type, { keyPath: 'id' });
        // TODO - override and create appropriate indices
    }
    clearRecords(type) {
        return new Promise((resolve, reject) => {
            const transaction = this.createTransaction([type], 'readwrite');
            const objectStore = transaction.objectStore(type);
            const request = objectStore.clear();
            request.onsuccess = () => {
                resolve();
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }
    getRecordAsync(record) {
        return new Promise((resolve, reject) => {
            const transaction = this.createTransaction([record.type]);
            const objectStore = transaction.objectStore(record.type);
            const request = objectStore.get(record.id);
            request.onsuccess = () => {
                let result = request.result;
                if (result) {
                    if (this._keyMap)
                        this._keyMap.pushRecord(result);
                    resolve(result);
                }
                else {
                    resolve(undefined);
                }
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }
    getRecordsAsync(typeOrIdentities) {
        if (typeOrIdentities === undefined) {
            return this._getAllRecords();
        }
        else if (typeof typeOrIdentities === 'string') {
            const type = typeOrIdentities;
            return new Promise((resolve, reject) => {
                const transaction = this.createTransaction([type]);
                const objectStore = transaction.objectStore(type);
                const records = [];
                const request = objectStore.openCursor();
                request.onsuccess = (event) => {
                    const cursor = event.target.result;
                    if (cursor) {
                        let record = cursor.value;
                        if (this._keyMap)
                            this._keyMap.pushRecord(record);
                        records.push(record);
                        cursor.continue();
                    }
                    else {
                        resolve(records);
                    }
                };
                request.onerror = () => {
                    reject(request.error);
                };
            });
        }
        else {
            const identities = typeOrIdentities;
            const records = [];
            if (identities.length > 0) {
                const types = [];
                for (let identity of identities) {
                    if (!types.includes(identity.type)) {
                        types.push(identity.type);
                    }
                }
                const transaction = this.createTransaction(types);
                return new Promise((resolve, reject) => {
                    const len = identities.length;
                    const last = len - 1;
                    for (let i = 0; i < len; i++) {
                        const identity = identities[i];
                        const objectStore = transaction.objectStore(identity.type);
                        const request = objectStore.get(identity.id);
                        request.onsuccess = () => {
                            const record = request.result;
                            if (record) {
                                if (this._keyMap)
                                    this._keyMap.pushRecord(record);
                                records.push(record);
                            }
                            if (i === last)
                                resolve(records);
                        };
                        request.onerror = () => reject(request.error);
                    }
                });
            }
            else {
                return Promise.resolve(records);
            }
        }
    }
    setRecordAsync(record) {
        const transaction = this.createTransaction([record.type], 'readwrite');
        const objectStore = transaction.objectStore(record.type);
        return new Promise((resolve, reject) => {
            const request = objectStore.put(record);
            request.onsuccess = () => {
                if (this._keyMap)
                    this._keyMap.pushRecord(record);
                resolve();
            };
            request.onerror = () => reject(request.error);
        });
    }
    setRecordsAsync(records) {
        if (records.length > 0) {
            const types = [];
            for (let record of records) {
                if (!types.includes(record.type)) {
                    types.push(record.type);
                }
            }
            const transaction = this.createTransaction(types, 'readwrite');
            return new Promise((resolve, reject) => {
                const len = records.length;
                const last = len - 1;
                for (let i = 0; i < len; i++) {
                    const record = records[i];
                    const objectStore = transaction.objectStore(record.type);
                    const request = objectStore.put(record);
                    if (i === last) {
                        request.onsuccess = () => {
                            if (this._keyMap) {
                                records.forEach((record) => { var _a; return (_a = this._keyMap) === null || _a === void 0 ? void 0 : _a.pushRecord(record); });
                            }
                            resolve();
                        };
                    }
                    request.onerror = () => reject(request.error);
                }
            });
        }
        else {
            return Promise.resolve();
        }
    }
    removeRecordAsync(recordIdentity) {
        return new Promise((resolve, reject) => {
            const transaction = this.createTransaction([recordIdentity.type], 'readwrite');
            const objectStore = transaction.objectStore(recordIdentity.type);
            const request = objectStore.delete(recordIdentity.id);
            request.onsuccess = () => resolve(undefined);
            request.onerror = () => reject(request.error);
        });
    }
    removeRecordsAsync(records) {
        if (records.length > 0) {
            const types = [];
            for (let record of records) {
                if (!types.includes(record.type)) {
                    types.push(record.type);
                }
            }
            const transaction = this.createTransaction(types, 'readwrite');
            return new Promise((resolve, reject) => {
                const len = records.length;
                const last = len - 1;
                for (let i = 0; i < len; i++) {
                    const record = records[i];
                    const objectStore = transaction.objectStore(record.type);
                    const request = objectStore.delete(record.id);
                    if (i === last) {
                        request.onsuccess = () => resolve(records);
                    }
                    request.onerror = () => reject(request.error);
                }
            });
        }
        else {
            return Promise.resolve([]);
        }
    }
    getInverseRelationshipsAsync(recordIdentityOrIdentities) {
        // console.log('getInverseRelationshipsAsync', recordIdentityOrIdentities);
        return new Promise((resolve, reject) => {
            const transaction = this.createTransaction([INVERSE_RELS]);
            const objectStore = transaction.objectStore(INVERSE_RELS);
            const results = [];
            let index;
            try {
                index = objectStore.index('relatedIdentity');
            }
            catch (e) {
                console.error(`[@orbit/indexeddb] The 'relatedIdentity' index is missing from the ${INVERSE_RELS} object store in IndexedDB. ` +
                    'Please add this index using a DB migration as described in https://github.com/orbitjs/orbit/pull/823');
                resolve([]);
                return;
            }
            const identities = Array.isArray(recordIdentityOrIdentities)
                ? recordIdentityOrIdentities
                : [recordIdentityOrIdentities];
            const len = identities.length;
            let completed = 0;
            for (let i = 0; i < len; i++) {
                const identity = identities[i];
                const keyRange = Orbit.globals.IDBKeyRange.only(serializeRecordIdentity(identity));
                const request = index.openCursor(keyRange);
                request.onsuccess = (event) => {
                    const cursor = event.target.result;
                    if (cursor) {
                        let result = this._fromInverseRelationshipForIDB(cursor.value);
                        results.push(result);
                        cursor.continue();
                    }
                    else {
                        completed += 1;
                        if (completed === len) {
                            resolve(results);
                        }
                    }
                };
                request.onerror = () => reject(request.error);
            }
        });
    }
    addInverseRelationshipsAsync(relationships) {
        // console.log('addInverseRelationshipsAsync', relationships);
        if (relationships.length > 0) {
            const transaction = this.createTransaction([INVERSE_RELS], 'readwrite');
            const objectStore = transaction.objectStore(INVERSE_RELS);
            return new Promise((resolve, reject) => {
                const len = relationships.length;
                const last = len - 1;
                for (let i = 0; i < len; i++) {
                    const relationship = relationships[i];
                    const ir = this._toInverseRelationshipForIDB(relationship);
                    const request = objectStore.put(ir);
                    if (i === last) {
                        request.onsuccess = () => resolve();
                    }
                    request.onerror = () => reject(request.error);
                }
            });
        }
        else {
            return Promise.resolve();
        }
    }
    removeInverseRelationshipsAsync(relationships) {
        // console.log('removeInverseRelationshipsAsync', relationships);
        if (relationships.length > 0) {
            const transaction = this.createTransaction([INVERSE_RELS], 'readwrite');
            const objectStore = transaction.objectStore(INVERSE_RELS);
            return new Promise((resolve, reject) => {
                const len = relationships.length;
                const last = len - 1;
                for (let i = 0; i < len; i++) {
                    const relationship = relationships[i];
                    const id = this._serializeInverseRelationshipIdentity(relationship);
                    const request = objectStore.delete(id);
                    if (i === last) {
                        request.onsuccess = () => resolve();
                    }
                    request.onerror = () => reject(request.error);
                }
            });
        }
        else {
            return Promise.resolve();
        }
    }
    /////////////////////////////////////////////////////////////////////////////
    // Protected methods
    /////////////////////////////////////////////////////////////////////////////
    /**
     * Override `_getTransformBuffer` on base `AsyncRecordCache` to provide a
     * `transformBuffer` if a custom one hasn't been provided via the constructor
     * setting.
     */
    _getTransformBuffer() {
        if (this._transformBuffer === undefined) {
            const { schema, keyMap } = this;
            this._transformBuffer = new SimpleRecordTransformBuffer({
                schema,
                keyMap
            });
        }
        return this._transformBuffer;
    }
    async _getAllRecords() {
        const types = Object.keys(this.schema.models);
        const recordsets = await Promise.all(types.map((type) => this.getRecordsAsync(type)));
        const allRecords = [];
        recordsets.forEach((records) => Array.prototype.push.apply(allRecords, records));
        return allRecords;
    }
    _serializeInverseRelationshipIdentity(ri) {
        return [
            serializeRecordIdentity(ri.record),
            ri.relationship,
            serializeRecordIdentity(ri.relatedRecord)
        ].join('::');
    }
    _toInverseRelationshipForIDB(ri) {
        return {
            id: this._serializeInverseRelationshipIdentity(ri),
            recordIdentity: serializeRecordIdentity(ri.record),
            relationship: ri.relationship,
            relatedIdentity: serializeRecordIdentity(ri.relatedRecord),
            type: ri.record.type,
            relatedType: ri.relatedRecord.type
        };
    }
    _fromInverseRelationshipForIDB(ir) {
        return {
            record: deserializeRecordIdentity(ir.recordIdentity),
            relatedRecord: deserializeRecordIdentity(ir.relatedIdentity),
            relationship: ir.relationship
        };
    }
}

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const { assert: assert$2 } = Orbit;
/**
 * Source for storing data in IndexedDB.
 */
let IndexedDBSource = class IndexedDBSource extends RecordSource {
    constructor(settings) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        assert$2("IndexedDBSource's `schema` must be specified in `settings.schema` constructor argument", !!settings.schema);
        assert$2('Your browser does not support IndexedDB!', supportsIndexedDB());
        settings.name = (_a = settings.name) !== null && _a !== void 0 ? _a : 'indexedDB';
        const autoActivate = settings.autoActivate !== false;
        settings.autoActivate = false;
        super(settings);
        const cacheSettings = (_b = settings.cacheSettings) !== null && _b !== void 0 ? _b : {};
        cacheSettings.schema = settings.schema;
        cacheSettings.keyMap = settings.keyMap;
        cacheSettings.queryBuilder = (_c = cacheSettings.queryBuilder) !== null && _c !== void 0 ? _c : this.queryBuilder;
        cacheSettings.transformBuilder = (_d = cacheSettings.transformBuilder) !== null && _d !== void 0 ? _d : this.transformBuilder;
        cacheSettings.namespace = (_e = cacheSettings.namespace) !== null && _e !== void 0 ? _e : settings.namespace;
        cacheSettings.defaultQueryOptions = (_f = cacheSettings.defaultQueryOptions) !== null && _f !== void 0 ? _f : settings.defaultQueryOptions;
        cacheSettings.defaultTransformOptions = (_g = cacheSettings.defaultTransformOptions) !== null && _g !== void 0 ? _g : settings.defaultTransformOptions;
        if (cacheSettings.validatorFor === undefined &&
            cacheSettings.validators === undefined) {
            cacheSettings.validatorFor = this._validatorFor;
        }
        const cacheClass = (_h = settings.cacheClass) !== null && _h !== void 0 ? _h : IndexedDBCache;
        this._cache = new cacheClass(cacheSettings);
        if (autoActivate) {
            this.activate();
        }
    }
    get cache() {
        return this._cache;
    }
    get defaultQueryOptions() {
        return super.defaultQueryOptions;
    }
    set defaultQueryOptions(options) {
        super.defaultQueryOptions = this.cache.defaultQueryOptions = options;
    }
    get defaultTransformOptions() {
        return super.defaultTransformOptions;
    }
    set defaultTransformOptions(options) {
        this._defaultTransformOptions = this.cache.defaultTransformOptions = options;
    }
    async upgrade() {
        await this._cache.upgrade();
    }
    async _activate() {
        await super._activate();
        await this.cache.openDB();
    }
    async deactivate() {
        await super.deactivate();
        await this.cache.closeDB();
    }
    /////////////////////////////////////////////////////////////////////////////
    // Resettable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async reset() {
        await this._cache.reset();
    }
    /////////////////////////////////////////////////////////////////////////////
    // Syncable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async _sync(transform) {
        if (!this.transformLog.contains(transform.id)) {
            await this._applyTransform(transform);
            await this.transformed([transform]);
        }
    }
    /////////////////////////////////////////////////////////////////////////////
    // Updatable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async _update(transform, hints) {
        let results;
        const response = {};
        if (!this.transformLog.contains(transform.id)) {
            results = await this._applyTransform(transform);
            response.transforms = [transform];
        }
        if (hints === null || hints === void 0 ? void 0 : hints.data) {
            if (Array.isArray(transform.operations)) {
                assert$2('IndexedDBSource#update: `hints.data` must be an array if `transform.operations` is an array', Array.isArray(hints.data));
                const responseData = [];
                const hintsData = hints.data;
                for (let h of hintsData) {
                    responseData.push(await this._retrieveOperationResult(h));
                }
                response.data = responseData;
            }
            else {
                response.data = await this._retrieveOperationResult(hints.data);
            }
        }
        else if (results) {
            response.data = results;
        }
        if (hints === null || hints === void 0 ? void 0 : hints.details) {
            response.details = hints.details;
        }
        return response;
    }
    /////////////////////////////////////////////////////////////////////////////
    // Queryable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async _query(query, hints) {
        let response;
        if (hints === null || hints === void 0 ? void 0 : hints.data) {
            response = {};
            if (Array.isArray(query.expressions)) {
                assert$2('IndexedDBSource#query: `hints.data` must be an array if `query.expressions` is an array', Array.isArray(hints.data));
                const responseData = [];
                const hintsData = hints.data;
                for (let h of hintsData) {
                    responseData.push(await this._retrieveQueryExpressionResult(h));
                }
                response.data = responseData;
            }
            else {
                response.data = await this._retrieveQueryExpressionResult(hints.data);
            }
        }
        else {
            response = await this._cache.query(query, {
                fullResponse: true
            });
        }
        if (hints === null || hints === void 0 ? void 0 : hints.details) {
            response.details = hints.details;
        }
        return response;
    }
    /////////////////////////////////////////////////////////////////////////////
    // Pushable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async _push(transform) {
        const fullResponse = {};
        if (!this.transformLog.contains(transform.id)) {
            await this._cache.update(transform);
            fullResponse.transforms = [transform];
        }
        return fullResponse;
    }
    /////////////////////////////////////////////////////////////////////////////
    // Pullable implementation
    /////////////////////////////////////////////////////////////////////////////
    async _pull(query) {
        const fullResponse = {};
        let operations;
        const results = await this._cache.query(query);
        if (Array.isArray(query.expressions)) {
            operations = [];
            for (let result of results) {
                operations.push(...this._operationsFromQueryResult(result));
            }
        }
        else {
            operations = this._operationsFromQueryResult(results);
        }
        fullResponse.transforms = [buildTransform(operations)];
        return fullResponse;
    }
    /////////////////////////////////////////////////////////////////////////////
    // Protected methods
    /////////////////////////////////////////////////////////////////////////////
    async _retrieveQueryExpressionResult(result) {
        if (Array.isArray(result)) {
            return this._cache.getRecordsAsync(result);
        }
        else if (result) {
            return this._cache.getRecordAsync(result);
        }
        else {
            return result;
        }
    }
    async _retrieveOperationResult(result) {
        if (result) {
            return this._cache.getRecordAsync(result);
        }
        else {
            return result;
        }
    }
    async _applyTransform(transform) {
        return await this.cache.update(transform);
    }
    _operationsFromQueryResult(result) {
        if (Array.isArray(result)) {
            return result.map((r) => {
                return {
                    op: 'updateRecord',
                    record: r
                };
            });
        }
        else if (result) {
            return [
                {
                    op: 'updateRecord',
                    record: result
                }
            ];
        }
        else {
            return [];
        }
    }
};
IndexedDBSource = __decorate([
    pullable,
    pushable,
    queryable,
    updatable,
    syncable
], IndexedDBSource);

var __pika_web_default_export_for_treeshaking__ = IndexedDBSource;

export default __pika_web_default_export_for_treeshaking__;
