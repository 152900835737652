function put(input, path, value) {
    const [head, ...tail] = path;
    if (tail.length === 0) {
        input[path[0]] = value;
        return input;
    }
    let child = input[head] || {};
    if (typeof child !== "object")
        child = {};
    input[head] = put(child, tail, value);
    return input;
}
function del(input, path) {
    const [head, ...tail] = path;
    if (tail.length === 0) {
        delete input[head];
        return input;
    }
    const child = input[head];
    if (child == null || typeof child !== "object")
        return input;
    input[head] = del(child, tail);
    return input;
}
function get(input, path) {
    const [head, ...tail] = path;
    if (head == null)
        return input;
    const child = input[head];
    if (child == null)
        return undefined;
    return get(child, tail);
}

export { del, get, put };
