import styled, {css} from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./theme.js";
import {FormLabel} from "./FormLabel.js";
const primary = css`
  border-color: ${Theme.colors.primary};
`;
const danger = css`
  border-color: ${Theme.colors.danger};

  ${FormLabel} {
    color: ${Theme.colors.danger};
  }
`;
const variants = {
  primary,
  danger
};
export const FormOutline = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  height: ${(props) => props.grow ? "auto" : "50px"};
  border-radius: 8px;
  border: 1px solid;
  border-color: ${Theme.colors.border};
  transition: 300ms all ${Theme.easing.default};
  background: white;

  ${(props) => props.highlight && variants[props.variant || "primary"]}

  &:focus-within {
    ${(props) => variants[props.variant || "primary"]};

    ${FormLabel} {
      transform: scale(0.75) translateY(-4px);
    }
  }
`;
