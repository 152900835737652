import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./index.js";
const css = `
  font-size: ${Theme.font.size375};
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  flex-grow: 1;
  width: 0;
  height: 100%;
  padding-top: 16px;
  transition: 300ms all;
  font-weight: 500;
  font-family: Avenir Next;

  &::placeholder {
    opacity: 0;
    transition: 300ms all;
  }
  &:focus::placeholder {
    opacity: 0.5;
  }
`;
export const FormInput = styled.input`
  ${css}
`;
export const FormTextArea = styled.textarea`
  ${css}
  height: auto;
  &::placeholder {
    opacity: 0.5;
  }
`;
export const FormSelect = styled.select`
  ${css}
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: auto;
  padding-top: 19px;
  margin-left: -4px;
  line-height: 18px;
  cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};
`;
export const FormStatic = styled.div`
  ${css}
  padding-top: 24px;
  flex-grow: initial;
  width: auto;
`;
export function FormEnter(cb) {
  return (e) => {
    if (e.which !== 13)
      return;
    cb();
  };
}
export function FormSelectValue(input) {
  return input == null ? "" : String(input);
}
