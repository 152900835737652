import {Link} from "../../_snowpack/pkg/@reach/router.js";
import styled, {css} from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./theme.js";
const primary = css`
  color: ${Theme.colors.primary};
`;
const danger = css`
  color: ${Theme.colors.danger};
`;
const variants = {
  primary,
  danger
};
export const SimpleLink = styled(Link)`
  ${(props) => variants[props.variant || "primary"]}
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;
