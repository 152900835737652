import {Theme} from "./theme.js";
import styled, {css} from "../../_snowpack/pkg/styled-components.js";
const primary = css`
  border-color: ${Theme.colors.primary};
  color: ${Theme.colors.primary};
`;
const danger = css`
  border-color: ${Theme.colors.danger};
  color: ${Theme.colors.danger};
`;
const variants = {
  primary,
  danger
};
export const FormChoice = styled.div`
  line-height: 150%;
  display: flex;
  flex-direction: column;
  min-height: 50px;
  justify-content: center;
  position: relative;
  padding: ${Theme.spacing.size300} ${Theme.spacing.size400};
  border-radius: 8px;
  align-self: flex-start;
  border: 1px solid;
  background: white;
  font-weight: 500;
  cursor: pointer;
  border-color: ${Theme.colors.border};
  transition: 300ms all ${Theme.easing.default};

  ${(props) => props.value && variants[props.variant || "primary"]}
`;
export const FormChoiceDescription = styled.div`
  margin-top: ${Theme.spacing.size100};
  font-weight: 400;
  line-height: 150%;
`;
