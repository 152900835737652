import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./theme.js";
const WrapOuter = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px ${Theme.spacing.size800};
`;
const WrapInner = styled.div`
  width: 100%;
  max-width: ${(props) => Theme.breakpoints[props.width] || props.width};
`;
export function Wrap(props) {
  return /* @__PURE__ */ React.createElement(WrapOuter, {
    className: props.className
  }, /* @__PURE__ */ React.createElement(WrapInner, {
    width: props.width
  }, props.children));
}
