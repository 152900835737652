import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./index.js";
export const TableFloating = styled.div`
  width: 100%;
  border-radius: 6px;
  background: white;
  overflow: hidden;
`;
export const Table = styled(TableFloating)`
  border: 1px solid ${Theme.colors.border};
`;
export const TableInner = styled.table`
  width: 100%;
`;
export const TableHead = styled.thead``;
export const TableBody = styled.tbody``;
export const TableHeader = styled.th`
  padding: 0 ${Theme.spacing.size500};
  text-align: left;
  height: 40px;
  font-weight: 500;
  font-size: ${Theme.font.size325};
  color: ${Theme.colors.textLight};
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(207, 207, 211, 0.077) 68.23%
    ),
    #f9f9fb;
`;
export const TableCell = styled.td`
  border-top: 1px solid ${Theme.colors.border};
  padding: ${Theme.spacing.size500};
  font-size: ${Theme.font.size350};
  font-weight: 500;
`;
export const TableRow = styled.tr`
  &:first-child {
    ${TableCell} {
      border-top: 0;
    }
  }
`;
export const TableToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
`;
