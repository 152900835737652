import styled from "../../../_snowpack/pkg/styled-components.js";
import {Theme, Button} from "../../components/index.js";
export const Root = styled.div`
  width: 25rem;
  max-width: 100%;
  padding: 0 ${Theme.spacing.size600};
`;
export const Header = styled.div``;
export const Title = styled.div`
  margin-top: ${Theme.spacing.size800};
  font-size: ${Theme.font.size600};
  font-weight: 600;
`;
export const Description = styled.div`
  margin-top: ${Theme.spacing.size100};
  color: ${Theme.colors.textLight};
  line-height: 150%;
`;
export const Form = styled.div`
  margin-top: ${Theme.spacing.size600};
  display: grid;
  grid-gap: ${Theme.spacing.size300};
`;
export const Continue = styled(Button)`
  margin-top: ${Theme.spacing.size600};
`;
