import { O as Orbit, A as Assertion } from '../common/log-e4bf9a42.js';
import { o as objectValues } from '../common/objects-398cfae3.js';
import '../common/source-bf183794.js';

const { assert } = Orbit;
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["None"] = 0] = "None";
    LogLevel[LogLevel["Errors"] = 1] = "Errors";
    LogLevel[LogLevel["Warnings"] = 2] = "Warnings";
    LogLevel[LogLevel["Info"] = 3] = "Info";
})(LogLevel || (LogLevel = {}));
/**
 * The Coordinator class manages a set of sources to which it applies a set of
 * coordination strategies.
 */
class Coordinator {
    constructor(options = {}) {
        this._sources = {};
        this._strategies = {};
        if (options.sources) {
            options.sources.forEach((source) => this.addSource(source));
        }
        if (options.strategies) {
            options.strategies.forEach((strategy) => this.addStrategy(strategy));
        }
        this._defaultActivationOptions = options.defaultActivationOptions || {};
        if (this._defaultActivationOptions.logLevel === undefined) {
            this._defaultActivationOptions.logLevel = LogLevel.Info;
        }
    }
    addSource(source) {
        const name = source.name;
        if (name) {
            assert(`A source named '${name}' has already been added to this coordinator.`, !this._sources[name]);
            assert(`A coordinator's sources can not be changed while it is active.`, !this._activated);
            this._sources[name] = source;
        }
        else {
            assert(`Sources require a 'name' to be added to a coordinator.`, !!name);
        }
    }
    removeSource(name) {
        let source = this._sources[name];
        assert(`Source '${name}' has not been added to this coordinator.`, !!source);
        assert(`A coordinator's sources can not be changed while it is active.`, !this._activated);
        delete this._sources[name];
    }
    getSource(name) {
        return this._sources[name];
    }
    get sources() {
        return objectValues(this._sources);
    }
    get sourceNames() {
        return Object.keys(this._sources);
    }
    addStrategy(strategy) {
        const name = strategy.name;
        assert(`A strategy named '${name}' has already been added to this coordinator.`, !this._strategies[name]);
        assert(`A coordinator's strategies can not be changed while it is active.`, !this._activated);
        this._strategies[name] = strategy;
    }
    removeStrategy(name) {
        let strategy = this._strategies[name];
        assert(`Strategy '${name}' has not been added to this coordinator.`, !!strategy);
        assert(`A coordinator's strategies can not be changed while it is active.`, !this._activated);
        delete this._strategies[name];
    }
    getStrategy(name) {
        return this._strategies[name];
    }
    get strategies() {
        return objectValues(this._strategies);
    }
    get strategyNames() {
        return Object.keys(this._strategies);
    }
    get activated() {
        return this._activated;
    }
    async activate(options = {}) {
        if (!this._activated) {
            this._activated = this._activate(options);
        }
        await this._activated;
    }
    async deactivate() {
        if (this._activated) {
            await this._activated;
            await this._deactivate();
        }
        this._activated = undefined;
    }
    async _activate(options = {}) {
        if (options.logLevel === undefined) {
            options.logLevel = this._defaultActivationOptions.logLevel;
        }
        this._currentActivationOptions = options;
        for (let strategy of this.strategies) {
            await strategy.activate(this, options);
        }
        for (let strategy of this.strategies) {
            await strategy.beforeSourceActivation();
        }
        for (let source of this.sources) {
            await source.activate();
        }
        for (let strategy of this.strategies) {
            await strategy.afterSourceActivation();
        }
    }
    async _deactivate() {
        const strategies = this.strategies.reverse();
        const sources = this.sources.reverse();
        for (let strategy of strategies) {
            await strategy.beforeSourceDeactivation();
        }
        for (let source of sources) {
            await source.deactivate();
        }
        for (let strategy of strategies) {
            await strategy.afterSourceDeactivation();
        }
        for (let strategy of strategies) {
            await strategy.deactivate();
        }
    }
}

const { assert: assert$1 } = Orbit;
class Strategy {
    constructor(options = {}) {
        this._sources = [];
        if (options.name) {
            this._name = options.name;
        }
        else {
            assert$1('Strategy requires a name', false);
        }
        this._sourceNames = options.sources;
        this._logPrefix = options.logPrefix || `[${this._name}]`;
        this._logLevel = this._customLogLevel = options.logLevel;
    }
    async activate(coordinator, options = {}) {
        this._coordinator = coordinator;
        if (this._customLogLevel === undefined) {
            this._logLevel = options.logLevel;
        }
        if (this._sourceNames) {
            this._sources = this._sourceNames.map((name) => coordinator.getSource(name));
        }
        else {
            this._sources = coordinator.sources;
        }
    }
    async deactivate() {
        this._coordinator = undefined;
    }
    async beforeSourceActivation() { }
    async afterSourceActivation() { }
    async beforeSourceDeactivation() { }
    async afterSourceDeactivation() { }
    get name() {
        return this._name;
    }
    get coordinator() {
        return this._coordinator;
    }
    get sources() {
        return this._sources;
    }
    get logPrefix() {
        return this._logPrefix;
    }
    get logLevel() {
        return this._logLevel;
    }
    getSourceName(source) {
        if (source.name) {
            return source.name;
        }
        else {
            throw new Assertion(`Sources require a 'name' to be used by a coordination strategy.`);
        }
    }
}

const { assert: assert$2 } = Orbit;
class ConnectionStrategy extends Strategy {
    constructor(options) {
        assert$2('A `source` must be specified for a ConnectionStrategy', !!options.source);
        assert$2('`source` should be a Source name specified as a string', typeof options.source === 'string');
        assert$2('`on` should be specified as the name of the event a ConnectionStrategy listens for', typeof options.on === 'string');
        options.sources = [options.source];
        let defaultName = `${options.source}:${options.on}`;
        delete options.source;
        if (options.target) {
            assert$2('`target` should be a Source name specified as a string', typeof options.target === 'string');
            options.sources.push(options.target);
            defaultName += ` -> ${options.target}`;
            if (typeof options.action === 'string') {
                defaultName += `:${options.action}`;
            }
            delete options.target;
        }
        options.name = options.name || defaultName;
        super(options);
        this._event = options.on;
        this._action = options.action;
        this._catch = options.catch;
        this._filter = options.filter;
        this._blocking =
            typeof options.blocking === 'function'
                ? options.blocking
                : !!options.blocking;
    }
    get source() {
        return this._sources[0];
    }
    get target() {
        return this._sources[1];
    }
    get blocking() {
        return this._blocking;
    }
    async activate(coordinator, options = {}) {
        await super.activate(coordinator, options);
        this._listener = this.generateListener();
        this.source.on(this._event, this._listener);
    }
    async deactivate() {
        await super.deactivate();
        this.source.off(this._event, this._listener);
        this._listener = undefined;
    }
    generateListener() {
        return (...args) => this.defaultListener(...args);
    }
    async defaultListener(...args) {
        if (this._filter) {
            if (!this._filter.apply(this, args)) {
                return;
            }
        }
        let result = this.invokeAction(...args);
        if (this._catch && result && result.catch) {
            result = result.catch((e) => {
                return this._catch.apply(this, [e, ...args]);
            });
        }
        if (result) {
            let blocking = false;
            if (typeof this._blocking === 'function') {
                if (this._blocking(...args)) {
                    blocking = true;
                }
            }
            else if (this._blocking) {
                blocking = true;
            }
            if (blocking) {
                return this.handleBlockingResponse(result, ...args);
            }
        }
    }
    invokeAction(...args) {
        const target = this.target;
        if (typeof this._action === 'string') {
            return target[this._action](...args);
        }
        else {
            return this._action.apply(this, args);
        }
    }
    async handleBlockingResponse(result, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...args) {
        await result;
    }
}

class RequestStrategy extends ConnectionStrategy {
    constructor(options) {
        super(options);
        this.passHints = !!options.passHints;
    }
    invokeAction(request) {
        if (typeof this._action === 'string') {
            return this.target[this._action](request, {
                fullResponse: true
            });
        }
        else {
            return super.invokeAction(...arguments);
        }
    }
    async handleBlockingResponse(result, request, hints) {
        const fullResponse = await result;
        if (this.passHints && hints) {
            this.assignHints(hints, fullResponse);
        }
        return [this.target.name, fullResponse];
    }
    assignHints(hints, fullResponse) {
        if (fullResponse.data !== undefined) {
            hints.data = fullResponse.data;
        }
        if (fullResponse.details !== undefined) {
            hints.details = fullResponse.details;
        }
    }
}

const { assert: assert$3 } = Orbit;
class SyncStrategy extends ConnectionStrategy {
    constructor(options) {
        let opts = options;
        assert$3('A `source` must be specified for a SyncStrategy', !!opts.source);
        assert$3('A `target` must be specified for a SyncStrategy', !!opts.target);
        assert$3('`source` should be a Source name specified as a string', typeof opts.source === 'string');
        assert$3('`target` should be a Source name specified as a string', typeof opts.target === 'string');
        opts.on = opts.on || 'transform';
        opts.action = opts.action || 'sync';
        super(opts);
    }
}

export default Coordinator;
export { RequestStrategy, SyncStrategy };
