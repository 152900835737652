import React, {useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {
  PageTitle,
  PageTitleStretch,
  Button,
  FormGrid,
  FormSectionHeader,
  Theme,
  FormRow,
  FormStatic,
  AnimateAppear,
  FormSimpleInput3
} from "../../../components/index.js";
import styled from "../../../../_snowpack/pkg/styled-components.js";
import {memory, remote} from "../../../data/orbit.js";
import {useForm, useFormString} from "../../../components/util/index.js";
import {useAuth} from "../../../../_snowpack/pkg/@ironbay/ship-react.js";
import {useBootstrap} from "../index.js";
const LinkPrefix = styled(FormStatic)`
  color: ${Theme.colors.primary};
  font-weight: 600;
`;
export function Account(_props) {
  const auth = useAuth();
  const [ready, ready_set] = useState(false);
  const form = useForm({
    name_first: useFormString(),
    name_last: useFormString(),
    slug: useFormString(),
    email: useFormString()
  });
  function reset(user) {
    form.reset({
      ...user.attributes,
      name_first: user.attributes.name_first || "",
      name_last: user.attributes.name_last || ""
    });
    ready_set(true);
  }
  const bootstrap = useBootstrap();
  useEffect(() => {
    if (bootstrap)
      remote.query((q) => q.findRecord({type: "user", id: auth.user}));
  }, []);
  const original = memory.cache.query((q) => q.findRecord({type: "user", id: auth.user}));
  useEffect(() => {
    reset(original);
  }, [original]);
  async function save() {
    remote.update((t) => t.updateRecord({
      type: "user",
      id: auth.user,
      attributes: form.patch()
    })).then(() => {
    }).catch(() => {
      alert("Failed");
    });
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(PageTitle, null, /* @__PURE__ */ React.createElement(PageTitleStretch, null, "Account"), /* @__PURE__ */ React.createElement(AnimateAppear, {
    visible: true
  }, /* @__PURE__ */ React.createElement(Button, {
    onClick: () => save(),
    "data-test": "button-save",
    size: "sm"
  }, "Save Changes"))), ready && /* @__PURE__ */ React.createElement(FormGrid, null, /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Profile"), /* @__PURE__ */ React.createElement(FormRow, null, /* @__PURE__ */ React.createElement(FormSimpleInput3, {
    ...form.hooks.name_first.bind,
    label: "First Name"
  }), /* @__PURE__ */ React.createElement(FormSimpleInput3, {
    ...form.hooks.name_last.bind,
    label: "Last Name"
  }), /* @__PURE__ */ React.createElement("div", null)), /* @__PURE__ */ React.createElement(FormRow, null, /* @__PURE__ */ React.createElement(FormSimpleInput3, {
    ...form.hooks.slug.bind,
    label: "Your unique url",
    before: /* @__PURE__ */ React.createElement(LinkPrefix, null, "https://withnori.com/")
  }), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement("div", null)), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Contact"), /* @__PURE__ */ React.createElement(FormRow, null, /* @__PURE__ */ React.createElement(FormSimpleInput3, {
    ...form.hooks.email.bind,
    label: "Email Address"
  }), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement("div", null))));
}
