import {useState} from "../../../_snowpack/pkg/react.js";
export function useForm(hooks) {
  return {
    hooks,
    patch() {
      const result = {};
      for (let [key, value] of Object.entries(hooks)) {
        result[key] = value.patch();
      }
      return JSON.parse(JSON.stringify(result));
    },
    reset(reset) {
      for (let [key, value] of Object.entries(reset)) {
        if (!hooks[key])
          continue;
        hooks[key].reset(value);
      }
    }
  };
}
export function useFormValue(opts) {
  const [value, value_set] = useState(opts.initial);
  const [original, original_set] = useState(null);
  const [error, error_set] = useState(null);
  function set(input) {
    value_set(input);
  }
  function is_dirty() {
    return JSON.stringify(original) !== JSON.stringify(value);
  }
  return {
    value,
    original,
    set,
    is_dirty,
    error,
    reset(input) {
      value_set(input);
      original_set(input && JSON.parse(JSON.stringify(input)));
    },
    patch() {
      if (!is_dirty())
        return void 0;
      return value;
    },
    validate(input) {
      const parsed = opts?.schema?.safeParse(input);
      const result = parsed && !parsed.success ? parsed?.error : null;
      error_set(result);
      return result;
    }
  };
}
export function useFormString(opts = {}) {
  const hook = useFormValue(opts);
  return {
    ...hook,
    bind: {
      onChange: (e) => {
        hook.set(e.target.value);
        if (hook.error)
          hook.validate(e.target.value);
      },
      onBlur: () => hook.validate(hook.value),
      error: hook.error,
      value: hook.value || ""
    }
  };
}
export function useFormNumber(opts = {}) {
  const [temporary, temporary_set] = useState(null);
  const hook = useFormValue(opts);
  return {
    ...hook,
    bind: {
      onChange: (e) => {
        temporary_set(e.target.value);
      },
      onBlur: () => {
        const parsed = parseFloat(temporary || "");
        temporary_set(null);
        if (!parsed)
          return;
        hook.validate(parsed);
        hook.set(parsed);
      },
      error: hook.error,
      value: temporary || hook.value?.toString() || ""
    }
  };
}
export function useFormBoolean(opts = {}) {
  const hook = useFormValue(opts);
  return {
    ...hook,
    toggle() {
      hook.set(!hook.value);
    }
  };
}
export function useFormSet(opts = {}) {
  opts.initial = opts.initial || [];
  opts.is_equal = opts.is_equal || ((a, b) => a === b);
  const hook = useFormValue(opts);
  function remove(val) {
    hook.set(hook.value.filter((i) => !opts.is_equal?.(i, val)));
  }
  function add(val) {
    const removed = hook.value.filter((i) => !opts.is_equal?.(i, val));
    hook.set([...removed, val]);
  }
  function includes(val) {
    return hook.value.filter((i) => opts.is_equal?.(i, val)).length > 0;
  }
  return {
    ...hook,
    add,
    remove,
    includes
  };
}
