import React from "../../_snowpack/pkg/react.js";
import {FormOutline} from "./FormOutline.js";
import {FiAlertCircle} from "../../_snowpack/pkg/react-icons/fi.js";
import {FormInput} from "./FormInput.js";
import {FormLabel} from "./FormLabel.js";
import {get} from "../../_snowpack/pkg/@ironbay/dynamic.js";
import {FormError, Theme} from "./index.js";
export function FormSimpleInput({
  formHook,
  path,
  label,
  mask,
  parser,
  display,
  ...rest
}) {
  const error = formHook.error_get(path);
  return /* @__PURE__ */ React.createElement(FormOutline, {
    variant: error ? "danger" : "primary",
    highlight: error != null
  }, /* @__PURE__ */ React.createElement(FormLabel, {
    active: get(formHook.data, path)
  }, label), /* @__PURE__ */ React.createElement(FormInput, {
    ...formHook.bind(path, {
      mask,
      parser,
      display
    }),
    ...rest
  }), error && /* @__PURE__ */ React.createElement(FiAlertCircle, {
    title: error,
    color: Theme.colors.danger
  }));
}
export function FormSimpleInput2({
  label,
  mask,
  parser,
  display,
  value,
  ...rest
}) {
  const error = null;
  return /* @__PURE__ */ React.createElement(FormOutline, {
    variant: error ? "danger" : "primary",
    highlight: error != null
  }, /* @__PURE__ */ React.createElement(FormLabel, {
    active: value
  }, label), /* @__PURE__ */ React.createElement(FormInput, {
    value,
    ...rest
  }), error && /* @__PURE__ */ React.createElement(FiAlertCircle, {
    title: error,
    color: Theme.colors.danger
  }));
}
export function FormSimpleInput3(props) {
  const {label, error, before, ...rest} = props;
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(FormOutline, {
    variant: error ? "danger" : "primary",
    highlight: error != null
  }, /* @__PURE__ */ React.createElement(FormLabel, {
    active: before || props.value
  }, label), before && before, /* @__PURE__ */ React.createElement(FormInput, {
    ...rest
  }), error && /* @__PURE__ */ React.createElement(FiAlertCircle, {
    title: error.message,
    color: Theme.colors.danger
  })), error && /* @__PURE__ */ React.createElement(FormError, null, error.issues[0].message));
}
