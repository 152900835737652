import React from "../../_snowpack/pkg/react.js";
import styled, {css} from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./index.js";
const AnimateAppearRoot = styled.div`
  opacity: 0;
  pointer-events: none;
  transition: 300ms all ${Theme.easing.default};
  transform: translate3d(0, 10px, 0);

  ${(props) => props.visible && css`
      opacity: 1;
      pointer-events: all;
      transform: initial;
    `}
`;
export function AnimateAppear(props) {
  return /* @__PURE__ */ React.createElement(AnimateAppearRoot, {
    visible: props.visible
  }, props.children);
}
