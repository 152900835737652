import {Logo} from "../structures/Logo.js";
import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./theme.js";
const Root = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms all ${Theme.easing.default};
  opacity: ${(props) => props.active ? 1 : 0};
  pointer-events: none;
  background: white;
`;
export function Splash(props) {
  return /* @__PURE__ */ React.createElement(Root, {
    active: props.active
  }, /* @__PURE__ */ React.createElement(Logo, null));
}
