import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {Redirect, Router} from "../../../_snowpack/pkg/@reach/router.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {Theme} from "../../components/index.js";
import {Select} from "./Select.js";
import {Schedule} from "./Schedule.js";
import {Footer} from "./common.js";
import {Confirm} from "./Confirm.js";
import {Complete} from "./Complete.js";
import {memory, remote} from "../../data/orbit.js";
import {Loader} from "../../structures/Loader.js";
const Root = styled.div`
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  margin-top: ${Theme.spacing.size1600};
  flex-grow: 1;
`;
export function Book(props) {
  const [direct, direct_set] = useState(false);
  if (props.slug === "lizraad")
    return /* @__PURE__ */ React.createElement(Redirect, {
      to: "/liz"
    });
  if (props.slug === "yousef")
    return /* @__PURE__ */ React.createElement(Redirect, {
      to: "/chef"
    });
  useEffect(() => {
    if (props["*"])
      direct_set(true);
    remote.query((q) => q.findRecords("template").filter({attribute: "user.slug", value: props.slug}), {
      sources: {
        remote: {
          include: ["user"]
        }
      }
    });
  }, []);
  const [user] = memory.cache.query((q) => q.findRecords("user").filter({
    attribute: "slug",
    value: props.slug
  }));
  if (!user)
    return null;
  const templates = memory.cache.query((q) => q.findRelatedRecords({type: "user", id: user?.id}, "templates"));
  return /* @__PURE__ */ React.createElement(Root, null, /* @__PURE__ */ React.createElement(Loader, null), /* @__PURE__ */ React.createElement(Content, null, user && /* @__PURE__ */ React.createElement(Router, {
    style: {height: "100%"}
  }, /* @__PURE__ */ React.createElement(Select, {
    user,
    templates,
    path: "/"
  }), /* @__PURE__ */ React.createElement(WithTemplate, {
    user,
    direct,
    templates,
    path: "/:template_slug/*"
  }), /* @__PURE__ */ React.createElement(Complete, {
    path: "/:booking_id/complete"
  }))), /* @__PURE__ */ React.createElement(Footer, null));
}
function WithTemplate(props) {
  const template = props.templates.find((item) => item.attributes.slug === props.template_slug);
  if (!template)
    return null;
  return /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Schedule, {
    direct: props.direct,
    user: props.user,
    template,
    path: "/"
  }), /* @__PURE__ */ React.createElement(Confirm, {
    user: props.user,
    template,
    path: "/:time"
  }));
}
