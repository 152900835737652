import React, {useEffect} from "../../../../_snowpack/pkg/react.js";
import {Link} from "../../../../_snowpack/pkg/@reach/router.js";
import {PageTitle, PageTitleStretch, Button, Theme} from "../../../components/index.js";
import styled from "../../../../_snowpack/pkg/styled-components.js";
import {TemplateCard} from "../../../structures/TemplateCard.js";
import {memory, remote} from "../../../data/orbit.js";
import {v4} from "../../../../_snowpack/pkg/uuid.js";
import {useAuth} from "../../../../_snowpack/pkg/@ironbay/ship-react.js";
import {useBootstrap} from "../index.js";
const ProfileLink = styled.a`
  font-weight: 500;
  color: ${Theme.colors.textLight};
  font-size: ${Theme.font.size400};
  background: ${Theme.colors.gray500};
  display: inline-block;
  padding: ${Theme.spacing.size100} ${Theme.spacing.size300};
  margin-top: ${Theme.spacing.size200};
  border-radius: 6px;
  border: 1px solid ${Theme.colors.border};
`;
const TemplateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${Theme.spacing.size600};
`;
export function Listing(props) {
  const auth = useAuth();
  const bootstrap = useBootstrap();
  useEffect(() => {
    if (bootstrap)
      remote.query((q) => [
        q.findRelatedRecords({type: "user", id: auth.user}, "templates"),
        q.findRelatedRecords({type: "user", id: auth.user}, "calendars")
      ]);
  }, []);
  const [self, templates = [], calendars = []] = memory.cache.query((q) => [
    q.findRecord({type: "user", id: auth.user}),
    q.findRelatedRecords({type: "user", id: auth.user}, "templates"),
    q.findRelatedRecords({type: "user", id: auth.user}, "calendars")
  ]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(PageTitle, null, /* @__PURE__ */ React.createElement(PageTitleStretch, null, /* @__PURE__ */ React.createElement("div", null, "Templates"), /* @__PURE__ */ React.createElement(ProfileLink, {
    href: `/${self?.attributes?.slug}`,
    target: "_blank"
  }, "withnori.com/", self?.attributes?.slug)), /* @__PURE__ */ React.createElement(Button, {
    onClick: async () => {
      const id = v4();
      if (!calendars.length) {
        props.navigate("/auth/connect");
        return;
      }
      const record = {
        id,
        type: "template",
        attributes: {
          name: "New template",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          slug: id.split("-")[0],
          duration: 15,
          buffer: 0,
          optimize: true,
          availability: [
            {dow: 1, start: 9, end: 17},
            {dow: 2, start: 9, end: 17},
            {dow: 3, start: 9, end: 17},
            {dow: 4, start: 9, end: 17},
            {dow: 5, start: 9, end: 17}
          ]
        },
        relationships: {
          user: {
            data: {
              type: "user",
              id: auth.user
            }
          },
          constraints: {
            data: calendars.map((item) => ({
              type: "calendar",
              id: item.id
            }))
          },
          target: {
            data: calendars[0]?.id ? {
              type: "calendar",
              id: calendars[0].id
            } : void 0
          }
        }
      };
      try {
        await memory.update((t) => t.addRecord(record));
        props.navigate?.(`/dashboard/templates/${id}`);
      } catch (ex) {
        console.log(ex.issues);
      }
      return;
    },
    "data-test": "button",
    size: "sm"
  }, "New Template")), /* @__PURE__ */ React.createElement(TemplateGrid, null, templates.sort((a, b) => a.attributes.name > b.attributes.name ? 1 : -1).map((template) => /* @__PURE__ */ React.createElement(Link, {
    key: template.id,
    to: `/dashboard/templates/${template.id}`
  }, /* @__PURE__ */ React.createElement(TemplateCard, {
    slug: self?.attributes?.slug,
    template,
    editable: true
  })))));
}
