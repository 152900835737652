import { E as Exception, O as Orbit, a as fulfillInSeries, s as settleInSeries, A as Assertion } from './log-e4bf9a42.js';
import { S as Source } from './source-bf183794.js';
import { b as deepMerge, i as isNone, c as clone, a as deepSet, d as deepGet, e as isObject } from './objects-398cfae3.js';
import { S as StandardRecordValidators, V as ValidationError, a as StandardValidators, s as standardValidators, M as ModelNotDefined, K as KeyNotDefined } from './standard-validators-4ed2ae82.js';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, eqeqeq, no-eq-null, valid-jsdoc */
/**
 * `eq` checks the equality of two objects.
 *
 * The properties belonging to objects (but not their prototypes) will be
 * traversed deeply and compared.
 *
 * Includes special handling for strings, numbers, dates, booleans, regexes, and
 * arrays
 */
function eq(a, b) {
    // Some elements of this function come from underscore
    // (c) 2009-2013 Jeremy Ashkenas, DocumentCloud and Investigative Reporters & Editors
    //
    // https://github.com/jashkenas/underscore/blob/master/underscore.js
    // Identical objects are equal. `0 === -0`, but they aren't identical.
    // See the [Harmony `egal` proposal](http://wiki.ecmascript.org/doku.php?id=harmony:egal).
    if (a === b) {
        return a !== 0 || 1 / a == 1 / b;
    }
    // A strict comparison is necessary because `null == undefined`.
    if (a == null || b == null) {
        return a === b;
    }
    let type = Object.prototype.toString.call(a);
    if (type !== Object.prototype.toString.call(b)) {
        return false;
    }
    switch (type) {
        case '[object String]':
            return a == String(b);
        case '[object Number]':
            // `NaN`s are equivalent, but non-reflexive. An `egal` comparison is performed for
            // other numeric values.
            return a != +a ? b != +b : a == 0 ? 1 / a == 1 / b : a == +b;
        case '[object Date]':
        case '[object Boolean]':
            // Coerce dates and booleans to numeric primitive values. Dates are compared by their
            // millisecond representations. Note that invalid dates with millisecond representations
            // of `NaN` are not equivalent.
            return +a == +b;
        // RegExps are compared by their source patterns and flags.
        case '[object RegExp]':
            return (a.source == b.source &&
                a.global == b.global &&
                a.multiline == b.multiline &&
                a.ignoreCase == b.ignoreCase);
    }
    if (typeof a != 'object' || typeof b != 'object') {
        return false;
    }
    if (type === '[object Array]') {
        if (a.length !== b.length) {
            return false;
        }
    }
    let i;
    for (i in b) {
        if (b.hasOwnProperty(i)) {
            if (!eq(a[i], b[i])) {
                return false;
            }
        }
    }
    for (i in a) {
        if (a.hasOwnProperty(i)) {
            if (!eq(a[i], b[i])) {
                return false;
            }
        }
    }
    return true;
}

/**
 * An client-side error occurred while communicating with a remote server.
 */
class ClientError extends Exception {
    constructor(description) {
        super(`Client error: ${description}`);
        this.description = description;
    }
}
/**
 * A server-side error occurred while communicating with a remote server.
 */
class ServerError extends Exception {
    constructor(description) {
        super(`Server error: ${description}`);
        this.description = description;
    }
}
/**
 * A networking error occurred while attempting to communicate with a remote
 * server.
 */
class NetworkError extends Exception {
    constructor(description) {
        super(`Network error: ${description}`);
        this.description = description;
    }
}
/**
 * A query expression could not be parsed.
 */
class QueryExpressionParseError extends Exception {
    constructor(description, expression) {
        super(`Query expression parse error: ${description}`);
        this.description = description;
        this.expression = expression;
    }
}
/**
 * A query is invalid for a particular source.
 */
class QueryNotAllowed extends Exception {
    constructor(description, query) {
        super(`Query not allowed: ${description}`);
        this.description = description;
        this.query = query;
    }
}
/**
 * A transform is invalid for a particular source.
 */
class TransformNotAllowed extends Exception {
    constructor(description, transform) {
        super(`Transform not allowed: ${description}`);
        this.description = description;
        this.transform = transform;
    }
}

/**
 * Operation terms are used by transform builders to allow for the construction of
 * operations in composable patterns.
 */
class OperationTerm {
    constructor(operation) {
        this._operation = operation;
    }
    toOperation() {
        return this._operation;
    }
    options(options) {
        this._operation.options = deepMerge(this._operation.options || {}, options);
        return this;
    }
}

/**
 * Query terms are used by query builders to allow for the construction of
 * query expressions in composable patterns.
 */
class QueryTerm {
    constructor(expression) {
        this._expression = expression;
    }
    toQueryExpression() {
        return this._expression;
    }
    options(options) {
        this._expression.options = deepMerge(this._expression.options || {}, options);
        return this;
    }
}

/**
 * A builder function for creating a Query from its constituent parts.
 *
 * If a `Query` is passed in with an `id` and `expression`, and no replacement
 * `id` or `options` are also passed in, then the `Query` will be returned
 * unchanged.
 *
 * For all other cases, a new `Query` object will be created and returned.
 *
 * Queries will be assigned the specified `queryId` as `id`. If none is
 * specified, a UUID will be generated.
 */
function buildQuery(queryOrExpressions, queryOptions, queryId, queryBuilder) {
    if (typeof queryOrExpressions === 'function') {
        const queryBuilderFn = queryOrExpressions;
        return buildQuery(queryBuilderFn(queryBuilder), queryOptions, queryId);
    }
    else {
        let query = queryOrExpressions;
        let expressions;
        let options;
        let id;
        if (isQuery(query)) {
            if (queryOptions || queryId) {
                expressions = query.expressions;
                if (query.options && queryOptions) {
                    options = {
                        ...query.options,
                        ...queryOptions
                    };
                }
                else {
                    options = queryOptions !== null && queryOptions !== void 0 ? queryOptions : query.options;
                }
                id = queryId !== null && queryId !== void 0 ? queryId : query.id;
            }
            else {
                return query;
            }
        }
        else {
            if (Array.isArray(queryOrExpressions)) {
                expressions = [];
                for (let qe of queryOrExpressions) {
                    expressions.push(toQueryExpression(qe));
                }
            }
            else {
                expressions = toQueryExpression(queryOrExpressions);
            }
            options = queryOptions;
            id = queryId !== null && queryId !== void 0 ? queryId : Orbit.uuid();
        }
        return { expressions, options, id };
    }
}
function toQueryExpression(expression) {
    if (isQueryTerm(expression)) {
        return expression.toQueryExpression();
    }
    else {
        return expression;
    }
}
function isQueryTerm(expression) {
    return typeof expression.toQueryExpression === 'function';
}
function isQuery(query) {
    return query.expressions !== undefined;
}

function mapNamedFullResponses(responses) {
    let map = {};
    for (let r of responses) {
        if (typeof (r === null || r === void 0 ? void 0 : r[0]) === 'string' && (r === null || r === void 0 ? void 0 : r[1]) !== undefined) {
            map[r[0]] = r[1];
        }
    }
    return map;
}

/* eslint-disable valid-jsdoc */
/**
 * A builder function for creating a Transform from its constituent parts.
 *
 * If a `Transform` is passed in with an `id` and `operations`, and no
 * replacement `id` or `options` are also passed in, then the `Transform`
 * will be returned unchanged.
 *
 * For all other cases, a new `Transform` object will be created and returned.
 *
 * Transforms will be assigned the specified `transformId` as `id`. If none
 * is specified, a UUID will be generated.
 */
function buildTransform(transformOrOperations, transformOptions, transformId, transformBuilder) {
    if (typeof transformOrOperations === 'function') {
        const transformBuilderFn = transformOrOperations;
        return buildTransform(transformBuilderFn(transformBuilder), transformOptions, transformId);
    }
    else {
        let transform = transformOrOperations;
        let operations;
        let options;
        let id;
        if (isTransform(transform)) {
            if (transformOptions || transformId) {
                operations = transform.operations;
                if (transform.options && transformOptions) {
                    options = {
                        ...transform.options,
                        ...transformOptions
                    };
                }
                else {
                    options = transformOptions !== null && transformOptions !== void 0 ? transformOptions : transform.options;
                }
                id = transformId !== null && transformId !== void 0 ? transformId : transform.id;
            }
            else {
                return transform;
            }
        }
        else {
            if (Array.isArray(transformOrOperations)) {
                operations = [];
                for (let o of transformOrOperations) {
                    operations.push(toOperation(o));
                }
            }
            else {
                operations = toOperation(transformOrOperations);
            }
            options = transformOptions;
            id = transformId !== null && transformId !== void 0 ? transformId : Orbit.uuid();
        }
        return { operations, options, id };
    }
}
function toOperation(operation) {
    if (isOperationTerm(operation)) {
        return operation.toOperation();
    }
    else {
        return operation;
    }
}
function isOperationTerm(operation) {
    return typeof operation.toOperation === 'function';
}
function isTransform(transform) {
    return transform.operations !== undefined;
}

const { assert } = Orbit;
const QUERYABLE = '__queryable__';
/**
 * Has a source been decorated as `@queryable`?
 */
function isQueryable(source) {
    return !!source[QUERYABLE];
}
/**
 * Marks a source as "queryable" and adds an implementation of the `Queryable`
 * interface.
 *
 * The `query` method is part of the "request flow" in Orbit. Requests trigger
 * events before and after processing of each request. Observers can delay the
 * resolution of a request by returning a promise in an event listener.
 *
 * The `Queryable` interface introduces the following events:
 *
 * - `beforeQuery` - emitted prior to the processing of `query`, this event
 * includes the requested `Query` as an argument.
 *
 * - `query` - emitted after a `query` has successfully returned, this event's
 * arguments include both the requested `Query` and the results.
 *
 * - `queryFail` - emitted when an error has occurred processing a query, this
 * event's arguments include both the requested `Query` and the error.
 *
 * A queryable source must implement a private method `_query`, which performs
 * the processing required for `query` and returns a promise that resolves to a
 * set of results.
 */
function queryable(Klass) {
    let proto = Klass.prototype;
    if (isQueryable(proto)) {
        return;
    }
    assert('Queryable interface can only be applied to a Source', proto instanceof Source);
    proto[QUERYABLE] = true;
    proto.query = async function (queryOrExpressions, options, id) {
        await this.activated;
        const query = buildQuery(queryOrExpressions, options, id, this.queryBuilder);
        const response = await this._requestQueue.push({
            type: 'query',
            data: query
        });
        return (options === null || options === void 0 ? void 0 : options.fullResponse) ? response : response.data;
    };
    proto.__query__ = async function (query) {
        var _a;
        try {
            const hints = {};
            const otherResponses = (await fulfillInSeries(this, 'beforeQuery', query, hints));
            const fullResponse = await this._query(query, hints);
            if (otherResponses.length > 0) {
                fullResponse.sources = mapNamedFullResponses(otherResponses);
            }
            if (((_a = fullResponse.transforms) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                await this.transformed(fullResponse.transforms);
            }
            await settleInSeries(this, 'query', query, fullResponse);
            return fullResponse;
        }
        catch (error) {
            await settleInSeries(this, 'queryFail', query, error);
            throw error;
        }
    };
}

const { assert: assert$1 } = Orbit;
const UPDATABLE = '__updatable__';
/**
 * Has a source been decorated as `@updatable`?
 */
function isUpdatable(source) {
    return !!source[UPDATABLE];
}
/**
 * Marks a source as "updatable" and adds an implementation of the `Updatable`
 * interface.
 *
 * The `update` method is part of the "request flow" in Orbit. Requests trigger
 * events before and after processing of each request. Observers can delay the
 * resolution of a request by returning a promise in an event listener.
 *
 * An updatable source emits the following events:
 *
 * - `beforeUpdate` - emitted prior to the processing of `update`, this event
 * includes the requested `Transform` as an argument.
 *
 * - `update` - emitted after an `update` has successfully been applied, this
 * event includes the requested `Transform` as an argument.
 *
 * - `updateFail` - emitted when an error has occurred applying an update, this
 * event's arguments include both the requested `Transform` and the error.
 *
 * An updatable source must implement a private method `_update`, which performs
 * the processing required for `update` and returns a promise that resolves when
 * complete.
 */
function updatable(Klass) {
    let proto = Klass.prototype;
    if (isUpdatable(proto)) {
        return;
    }
    assert$1('Updatable interface can only be applied to a Source', proto instanceof Source);
    proto[UPDATABLE] = true;
    proto.update = async function (transformOrOperations, options, id) {
        await this.activated;
        const transform = buildTransform(transformOrOperations, options, id, this.transformBuilder);
        if (this.transformLog.contains(transform.id)) {
            return (options === null || options === void 0 ? void 0 : options.fullResponse) ? { transforms: [] } : undefined;
        }
        else {
            const response = await this._requestQueue.push({
                type: 'update',
                data: transform
            });
            return (options === null || options === void 0 ? void 0 : options.fullResponse) ? response : response.data;
        }
    };
    proto.__update__ = async function (transform) {
        var _a;
        if (this.transformLog.contains(transform.id)) {
            return { transforms: [] };
        }
        try {
            const hints = {};
            const otherResponses = (await fulfillInSeries(this, 'beforeUpdate', transform, hints));
            const fullResponse = await this._update(transform, hints);
            if (otherResponses.length > 0) {
                fullResponse.sources = mapNamedFullResponses(otherResponses);
            }
            if (((_a = fullResponse.transforms) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                await this.transformed(fullResponse.transforms);
            }
            await settleInSeries(this, 'update', transform, fullResponse);
            return fullResponse;
        }
        catch (error) {
            await settleInSeries(this, 'updateFail', transform, error);
            throw error;
        }
    };
}

function cloneRecordIdentity(identity) {
    const { type, id } = identity;
    return { type, id };
}
function equalRecordIdentities(record1, record2) {
    return ((isNone(record1) && isNone(record2)) ||
        (!!record1 &&
            !!record2 &&
            record1.type === record2.type &&
            record1.id === record2.id));
}
function equalRecordIdentitySets(set1, set2) {
    if (set1.length === set2.length) {
        if (set1.length === 0) {
            return true;
        }
        const serialized1 = serializeRecordIdentities(set1);
        const serialized2 = serializeRecordIdentities(set2);
        return (exclusiveIdentities(serialized1, serialized2).length === 0 &&
            exclusiveIdentities(serialized2, serialized1).length === 0);
    }
    return false;
}
function uniqueRecordIdentities(set1, set2) {
    return exclusiveIdentities(serializeRecordIdentities(set1), serializeRecordIdentities(set2)).map((id) => deserializeRecordIdentity(id));
}
function dedupeRecordIdentities(recordIdentities) {
    let dict = {};
    let deduped = [];
    recordIdentities.forEach((ri) => {
        const sri = serializeRecordIdentity(ri);
        if (dict[sri] === undefined) {
            deduped.push(ri);
            dict[sri] = true;
        }
    });
    return deduped;
}
function recordsInclude(records, match) {
    for (let r of records) {
        if (equalRecordIdentities(r, match)) {
            return true;
        }
    }
    return false;
}
function mergeRecords(current, updates) {
    if (current) {
        let record = cloneRecordIdentity(current);
        // Merge `meta` and `links`, replacing whole sections rather than merging
        // individual members
        mergeRecordSection(record, current, updates, 'meta', 0);
        mergeRecordSection(record, current, updates, 'links', 0);
        // Merge attributes and keys, replacing at the individual field level
        mergeRecordSection(record, current, updates, 'attributes', 1);
        mergeRecordSection(record, current, updates, 'keys', 1);
        // Merge relationships, replacing at the `data`, `links`, and `meta` level
        // for each relationship
        mergeRecordSection(record, current, updates, 'relationships', 2);
        return record;
    }
    else {
        return clone(updates);
    }
}
function mergeRecordSection(record, current, update, section, replacementDepth) {
    if (current[section] && update[section]) {
        if (replacementDepth === 0) {
            record[section] = clone(update[section]);
        }
        else if (replacementDepth === 1) {
            record[section] = Object.assign({}, current[section], update[section]);
        }
        else {
            record[section] = {};
            for (let name of Object.keys(current[section])) {
                mergeRecordSection(record[section], current[section], update[section], name, replacementDepth - 1);
            }
            for (let name of Object.keys(update[section])) {
                if (!record[section][name]) {
                    record[section][name] = clone(update[section][name]);
                }
            }
        }
    }
    else if (current[section]) {
        record[section] = clone(current[section]);
    }
    else if (update[section]) {
        record[section] = clone(update[section]);
    }
}
function isRecordIdentity(identity) {
    const { id, type } = identity;
    return typeof id === 'string' && typeof type === 'string';
}
function serializeRecordIdentity(record) {
    return `${record.type}:${record.id}`;
}
function deserializeRecordIdentity(identity) {
    const [type, id] = identity.split(':');
    return { type, id };
}
function serializeRecordIdentities(recordIdentities) {
    return recordIdentities.map((r) => serializeRecordIdentity(r));
}
function exclusiveIdentities(identities1, identities2) {
    return identities1.filter((i) => !identities2.includes(i));
}

function markOperationToDelete(operation) {
    const o = operation;
    o._deleted = true;
}
function isOperationMarkedToDelete(operation) {
    const o = operation;
    return o._deleted === true;
}
function mergeOperations(superceded, superceding, consecutiveOps) {
    var _a, _b, _c, _d;
    if (superceded.options || superceding.options) {
        // do not merge if one of the operations have options
        return;
    }
    else if (equalRecordIdentities(superceded.record, superceding.record)) {
        if (superceding.op === 'removeRecord') {
            markOperationToDelete(superceded);
            if (superceded.op === 'addRecord') {
                markOperationToDelete(superceding);
            }
        }
        else if (!isOperationMarkedToDelete(superceding) &&
            (consecutiveOps || superceding.op === 'replaceAttribute')) {
            if (isReplaceFieldOp(superceded.op) && isReplaceFieldOp(superceding.op)) {
                if (superceded.op === 'replaceAttribute' &&
                    superceding.op === 'replaceAttribute' &&
                    superceded.attribute === superceding.attribute) {
                    markOperationToDelete(superceded);
                }
                else if (superceded.op === 'replaceRelatedRecord' &&
                    superceding.op === 'replaceRelatedRecord' &&
                    superceded.relationship === superceding.relationship) {
                    markOperationToDelete(superceded);
                }
                else if (superceded.op === 'replaceRelatedRecords' &&
                    superceding.op === 'replaceRelatedRecords' &&
                    superceded.relationship === superceding.relationship) {
                    markOperationToDelete(superceded);
                }
                else {
                    if (superceded.op === 'replaceAttribute') {
                        updateRecordReplaceAttribute(superceded.record, superceded.attribute, superceded.value);
                        delete superceded.attribute;
                        delete superceded.value;
                    }
                    else if (superceded.op === 'replaceRelatedRecord') {
                        updateRecordReplaceHasOne(superceded.record, superceded.relationship, superceded.relatedRecord);
                        delete superceded.relationship;
                        delete superceded.relatedRecord;
                    }
                    else if (superceded.op === 'replaceRelatedRecords') {
                        updateRecordReplaceHasMany(superceded.record, superceded.relationship, superceded.relatedRecords);
                        delete superceded.relationship;
                        delete superceded.relatedRecords;
                    }
                    if (superceding.op === 'replaceAttribute') {
                        updateRecordReplaceAttribute(superceded.record, superceding.attribute, superceding.value);
                    }
                    else if (superceding.op === 'replaceRelatedRecord') {
                        updateRecordReplaceHasOne(superceded.record, superceding.relationship, superceding.relatedRecord);
                    }
                    else if (superceding.op === 'replaceRelatedRecords') {
                        updateRecordReplaceHasMany(superceded.record, superceding.relationship, superceding.relatedRecords);
                    }
                    superceded.op = 'updateRecord';
                    markOperationToDelete(superceding);
                }
            }
            else if ((superceded.op === 'addRecord' ||
                superceded.op === 'updateRecord' ||
                superceded.op === 'replaceRecord') &&
                (superceding.op === 'updateRecord' ||
                    superceding.op === 'replaceRecord')) {
                superceded.record = mergeRecords(superceded.record, superceding.record);
                markOperationToDelete(superceding);
            }
            else if ((superceded.op === 'addRecord' ||
                superceded.op === 'updateRecord' ||
                superceded.op === 'replaceRecord') &&
                isReplaceFieldOp(superceding.op)) {
                if (superceding.op === 'replaceAttribute') {
                    updateRecordReplaceAttribute(superceded.record, superceding.attribute, superceding.value);
                }
                else if (superceding.op === 'replaceRelatedRecord') {
                    updateRecordReplaceHasOne(superceded.record, superceding.relationship, superceding.relatedRecord);
                }
                else if (superceding.op === 'replaceRelatedRecords') {
                    updateRecordReplaceHasMany(superceded.record, superceding.relationship, superceding.relatedRecords);
                }
                markOperationToDelete(superceding);
            }
            else if (superceding.op === 'addToRelatedRecords') {
                if (superceded.op === 'addRecord') {
                    updateRecordAddToHasMany(superceded.record, superceding.relationship, superceding.relatedRecord);
                    markOperationToDelete(superceding);
                }
                else if (superceded.op === 'updateRecord' ||
                    superceded.op === 'replaceRecord') {
                    let record = superceded.record;
                    if ((_b = (_a = record.relationships) === null || _a === void 0 ? void 0 : _a[superceding.relationship]) === null || _b === void 0 ? void 0 : _b.data) {
                        updateRecordAddToHasMany(superceded.record, superceding.relationship, superceding.relatedRecord);
                        markOperationToDelete(superceding);
                    }
                }
            }
            else if (superceding.op === 'removeFromRelatedRecords') {
                if (superceded.op === 'addToRelatedRecords' &&
                    superceded.relationship === superceding.relationship &&
                    equalRecordIdentities(superceded.relatedRecord, superceding.relatedRecord)) {
                    markOperationToDelete(superceded);
                    markOperationToDelete(superceding);
                }
                else if (superceded.op === 'addRecord' ||
                    superceded.op === 'updateRecord' ||
                    superceded.op === 'replaceRecord') {
                    let record = superceded.record;
                    if ((_d = (_c = record.relationships) === null || _c === void 0 ? void 0 : _c[superceding.relationship]) === null || _d === void 0 ? void 0 : _d.data) {
                        updateRecordRemoveFromHasMany(superceded.record, superceding.relationship, superceding.relatedRecord);
                        markOperationToDelete(superceding);
                    }
                }
            }
        }
    }
    else if (superceding.record && superceding.op === 'removeRecord') {
        if (superceded.relatedRecord &&
            equalRecordIdentities(superceded
                .relatedRecord, superceding.record)) {
            markOperationToDelete(superceded);
        }
    }
}
function isReplaceFieldOp(op) {
    return (op === 'replaceAttribute' ||
        op === 'replaceRelatedRecord' ||
        op === 'replaceRelatedRecords');
}
function updateRecordReplaceAttribute(record, attribute, value) {
    deepSet(record, ['attributes', attribute], value);
}
function updateRecordReplaceHasOne(record, relationship, relatedRecord) {
    deepSet(record, ['relationships', relationship, 'data'], relatedRecord ? cloneRecordIdentity(relatedRecord) : null);
}
function updateRecordReplaceHasMany(record, relationship, relatedRecords) {
    deepSet(record, ['relationships', relationship, 'data'], relatedRecords.map(cloneRecordIdentity));
}
function updateRecordAddToHasMany(record, relationship, relatedRecord) {
    const data = deepGet(record, ['relationships', relationship, 'data']) || [];
    data.push(cloneRecordIdentity(relatedRecord));
    deepSet(record, ['relationships', relationship, 'data'], data);
}
function updateRecordRemoveFromHasMany(record, relationship, relatedRecord) {
    const data = deepGet(record, [
        'relationships',
        relationship,
        'data'
    ]);
    if (data) {
        for (let i = 0, l = data.length; i < l; i++) {
            let r = data[i];
            if (equalRecordIdentities(r, relatedRecord)) {
                data.splice(i, 1);
                break;
            }
        }
    }
}
/**
 * Coalesces operations into a minimal set of equivalent operations.
 *
 * This method respects the order of the operations array and does not allow
 * reordering of operations that affect relationships.
 */
function coalesceRecordOperations(operations) {
    for (let i = 0, l = operations.length; i < l; i++) {
        let currentOp = operations[i];
        let consecutiveOps = true;
        for (let j = i + 1; j < l; j++) {
            let nextOp = operations[j];
            mergeOperations(currentOp, nextOp, consecutiveOps);
            if (isOperationMarkedToDelete(currentOp)) {
                break;
            }
            else if (!isOperationMarkedToDelete(nextOp)) {
                consecutiveOps = false;
            }
        }
    }
    return operations.filter((o) => !isOperationMarkedToDelete(o));
}
/**
 * Determine the differences between a record and its updated version in terms
 * of a set of operations.
 */
function recordDiffs(record, updatedRecord) {
    var _a;
    const ops = [];
    if (record && updatedRecord) {
        let fullRecordUpdate = false;
        const recordIdentity = cloneRecordIdentity(record);
        const diffRecord = { ...recordIdentity };
        for (let member in updatedRecord) {
            if (member !== 'id' && member !== 'type') {
                let value;
                let updatedValue;
                switch (member) {
                    case 'attributes':
                    case 'keys':
                    case 'relationships':
                        for (let field in updatedRecord[member]) {
                            value = (_a = record[member]) === null || _a === void 0 ? void 0 : _a[field];
                            updatedValue = updatedRecord[member][field];
                            if (!eq(value, updatedValue)) {
                                if (member === 'relationships') {
                                    fullRecordUpdate = true;
                                }
                                deepSet(diffRecord, [member, field], updatedValue);
                            }
                        }
                        break;
                    default:
                        value = record[member];
                        updatedValue = updatedRecord[member];
                        if (!eq(updatedValue, value)) {
                            diffRecord[member] = updatedValue;
                            fullRecordUpdate = true;
                        }
                }
            }
        }
        // If updates consist solely of attributes and keys, update fields
        // with individual operations. Otherwise, update the record as a
        // whole.
        if (fullRecordUpdate) {
            let op = {
                op: 'updateRecord',
                record: diffRecord
            };
            ops.push(op);
        }
        else {
            if (diffRecord.attributes) {
                for (let attribute in diffRecord.attributes) {
                    let value = diffRecord.attributes[attribute];
                    let op = {
                        op: 'replaceAttribute',
                        record: recordIdentity,
                        attribute,
                        value
                    };
                    ops.push(op);
                }
            }
            if (diffRecord.keys) {
                for (let key in diffRecord.keys) {
                    let value = diffRecord.keys[key];
                    let op = {
                        op: 'replaceKey',
                        record: recordIdentity,
                        key,
                        value
                    };
                    ops.push(op);
                }
            }
        }
    }
    return ops;
}
/**
 * Returns the deduped identities of all the records directly referenced by an
 * array of operations.
 */
function recordsReferencedByOperations(operations) {
    const records = [];
    for (let operation of operations) {
        if (operation.record) {
            records.push(operation.record);
            if (operation.op === 'addRecord' || operation.op === 'updateRecord') {
                let record = operation.record;
                if (record.relationships) {
                    for (let relName in record.relationships) {
                        let rel = record.relationships[relName];
                        if (rel === null || rel === void 0 ? void 0 : rel.data) {
                            if (Array.isArray(rel.data)) {
                                Array.prototype.push.apply(records, rel.data);
                            }
                            else {
                                records.push(rel.data);
                            }
                        }
                    }
                }
            }
        }
        if (operation.op === 'addToRelatedRecords' ||
            operation.op === 'removeFromRelatedRecords' ||
            operation.op === 'replaceRelatedRecord') {
            if (operation.relatedRecord) {
                records.push(operation.relatedRecord);
            }
        }
        else if (operation.op === 'replaceRelatedRecords') {
            Array.prototype.push.apply(records, operation.relatedRecords);
        }
    }
    return dedupeRecordIdentities(records);
}

class BaseRecordOperationTerm extends OperationTerm {
    constructor(transformBuilder, operation) {
        super(operation);
        this.$transformBuilder = transformBuilder;
    }
    toOperation() {
        const operation = super.toOperation();
        const validatorFor = this.$transformBuilder.$validatorFor;
        if (validatorFor) {
            const schema = this.$transformBuilder.$schema;
            const validateRecordOperation = validatorFor(StandardRecordValidators.RecordOperation);
            const issues = validateRecordOperation(operation, {
                validatorFor,
                schema
            });
            if (issues !== undefined) {
                throw new ValidationError('Validation isssues encountered while building a transform operation', issues);
            }
        }
        return operation;
    }
}
class AddRecordTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record) {
        super(transformBuilder, {
            op: 'addRecord',
            record
        });
    }
}
class UpdateRecordTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record) {
        super(transformBuilder, {
            op: 'updateRecord',
            record
        });
    }
}
class RemoveRecordTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record) {
        super(transformBuilder, {
            op: 'removeRecord',
            record
        });
    }
}
class ReplaceAttributeTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record, attribute, value) {
        super(transformBuilder, {
            op: 'replaceAttribute',
            record,
            attribute,
            value
        });
    }
}
class ReplaceKeyTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record, key, value) {
        super(transformBuilder, {
            op: 'replaceKey',
            record,
            key,
            value
        });
    }
}
class AddToRelatedRecordsTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record, relationship, relatedRecord) {
        super(transformBuilder, {
            op: 'addToRelatedRecords',
            record,
            relationship,
            relatedRecord
        });
    }
}
class RemoveFromRelatedRecordsTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record, relationship, relatedRecord) {
        super(transformBuilder, {
            op: 'removeFromRelatedRecords',
            record,
            relationship,
            relatedRecord
        });
    }
}
class ReplaceRelatedRecordsTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record, relationship, relatedRecords) {
        super(transformBuilder, {
            op: 'replaceRelatedRecords',
            record,
            relationship,
            relatedRecords
        });
    }
}
class ReplaceRelatedRecordTerm extends BaseRecordOperationTerm {
    constructor(transformBuilder, record, relationship, relatedRecord) {
        super(transformBuilder, {
            op: 'replaceRelatedRecord',
            record,
            relationship,
            relatedRecord
        });
    }
}

function isFilterSpecifier(param) {
    return param.kind !== undefined;
}
function isAttributeFilterParam(param) {
    return param.attribute !== undefined;
}
function isRelatedRecordFilterParam(param) {
    return (param.relation !== undefined &&
        param.record !== undefined);
}
function isRelatedRecordsFilterParam(param) {
    return (param.relation !== undefined &&
        param.records !== undefined);
}
class BaseRecordQueryTerm extends QueryTerm {
    constructor(queryBuilder, expression) {
        super(expression);
        this.$queryBuilder = queryBuilder;
    }
    toQueryExpression() {
        const expression = super.toQueryExpression();
        const validatorFor = this.$queryBuilder.$validatorFor;
        if (validatorFor) {
            const schema = this.$queryBuilder.$schema;
            const validateRecordQueryExpression = validatorFor(StandardRecordValidators.RecordQueryExpression);
            const issues = validateRecordQueryExpression(expression, {
                validatorFor,
                schema
            });
            if (issues !== undefined) {
                throw new ValidationError('Validation isssues encountered while building a query expression', issues);
            }
        }
        return expression;
    }
}
/**
 * A query term representing a single record.
 */
class FindRecordTerm extends BaseRecordQueryTerm {
    constructor(queryBuilder, record) {
        super(queryBuilder, {
            op: 'findRecord',
            record
        });
    }
}
class FindRelatedRecordTerm extends BaseRecordQueryTerm {
    constructor(queryBuilder, record, relationship) {
        super(queryBuilder, {
            op: 'findRelatedRecord',
            record,
            relationship
        });
    }
}
class FindRecordsTerm extends BaseRecordQueryTerm {
    /**
     * Applies sorting to a collection query.
     *
     * Sort specifiers can be expressed in object form, like:
     *
     * ```ts
     * { attribute: 'name', order: 'descending' }
     * { attribute: 'name', order: 'ascending' }
     * ```
     *
     * Or in string form, like:
     *
     * ```ts
     * '-name' // descending order
     * 'name'  // ascending order
     * ```
     */
    sort(...params) {
        var _a;
        const specifiers = params.map((p) => this.$sortParamToSpecifier(p));
        this._expression.sort = ((_a = this._expression.sort) !== null && _a !== void 0 ? _a : []).concat(specifiers);
        return this;
    }
    /**
     * Applies pagination to a collection query.
     */
    page(param) {
        this._expression.page = this.$pageParamToSpecifier(param);
        return this;
    }
    /**
     * Apply a filter expression.
     *
     * For example:
     *
     * ```ts
     * oqb
     *   .records('planet')
     *   .filter({ attribute: 'atmosphere', value: true },
     *           { attribute: 'classification', value: 'terrestrial' });
     * ```
     */
    filter(...params) {
        var _a;
        const specifiers = params.map((p) => this.$filterParamToSpecifier(p));
        this._expression.filter = ((_a = this._expression.filter) !== null && _a !== void 0 ? _a : []).concat(specifiers);
        return this;
    }
    $filterParamToSpecifier(param) {
        var _a;
        let specifier;
        if (isFilterSpecifier(param)) {
            specifier = param;
        }
        else {
            const op = (_a = param.op) !== null && _a !== void 0 ? _a : 'equal';
            if (isAttributeFilterParam(param)) {
                const { attribute, value } = param;
                specifier = {
                    kind: 'attribute',
                    op,
                    attribute,
                    value
                };
            }
            else if (isRelatedRecordFilterParam(param)) {
                const { relation } = param;
                let record;
                if (Array.isArray(param.record)) {
                    record = param.record.map((ri) => this.$queryBuilder.$normalizeRecordIdentity(ri));
                }
                else if (param.record) {
                    record = this.$queryBuilder.$normalizeRecordIdentity(param.record);
                }
                else {
                    record = null;
                }
                specifier = {
                    kind: 'relatedRecord',
                    op,
                    relation,
                    record
                };
            }
            else if (isRelatedRecordsFilterParam(param)) {
                const { relation } = param;
                let records = param.records.map((ri) => this.$queryBuilder.$normalizeRecordIdentity(ri));
                specifier = {
                    kind: 'relatedRecords',
                    op,
                    relation,
                    records
                };
            }
        }
        if (specifier === undefined) {
            throw new ValidationError('Unrecognized `filter` param encountered while building query expression');
        }
        return specifier;
    }
    $pageParamToSpecifier(param) {
        let specifier;
        if (param.hasOwnProperty('offset') || param.hasOwnProperty('limit')) {
            specifier = {
                kind: 'offsetLimit',
                offset: param.offset,
                limit: param.limit
            };
        }
        if (specifier === undefined) {
            throw new ValidationError('Unrecognized `page` param encountered while building query expression');
        }
        return specifier;
    }
    $sortParamToSpecifier(param) {
        let specifier;
        if (isObject(param)) {
            if (param.hasOwnProperty('kind')) {
                specifier = param;
            }
            else if (param.hasOwnProperty('attribute')) {
                specifier = {
                    kind: 'attribute',
                    attribute: param.attribute,
                    order: param.order || 'ascending'
                };
            }
        }
        else if (typeof param === 'string') {
            specifier = this.$parseSortParamString(param);
        }
        if (specifier === undefined) {
            throw new ValidationError('Unrecognized `sort` param encountered while building query expression');
        }
        return specifier;
    }
    $parseSortParamString(sortSpecifier) {
        let attribute;
        let order;
        if (sortSpecifier[0] === '-') {
            attribute = sortSpecifier.slice(1);
            order = 'descending';
        }
        else {
            attribute = sortSpecifier;
            order = 'ascending';
        }
        return {
            kind: 'attribute',
            attribute,
            order
        };
    }
}

const { assert: assert$2 } = Orbit;
class RecordQueryBuilder {
    constructor(settings = {}) {
        const { schema, normalizer, validatorFor } = settings;
        if (validatorFor) {
            assert$2('A RecordQueryBuilder that has been assigned a `validatorFor` requires a `schema`', schema !== undefined);
        }
        this.$schema = schema;
        this.$normalizer = normalizer;
        this.$validatorFor = validatorFor;
    }
    /**
     * Find a record by its identity.
     */
    findRecord(record) {
        return new FindRecordTerm(this, this.$normalizeRecordIdentity(record));
    }
    /**
     * Find all records of a specific type.
     *
     * If `type` is unspecified, find all records unfiltered by type.
     */
    findRecords(typeOrIdentities) {
        const expression = {
            op: 'findRecords'
        };
        if (Array.isArray(typeOrIdentities)) {
            expression.records = typeOrIdentities.map((ri) => this.$normalizeRecordIdentity(ri));
        }
        else if (typeOrIdentities !== undefined) {
            expression.type = this.$normalizeRecordType(typeOrIdentities);
        }
        return new FindRecordsTerm(this, expression);
    }
    /**
     * Find a record in a to-one relationship.
     */
    findRelatedRecord(record, relationship) {
        return new FindRelatedRecordTerm(this, this.$normalizeRecordIdentity(record), relationship);
    }
    /**
     * Find records in a to-many relationship.
     */
    findRelatedRecords(record, relationship) {
        const expression = {
            op: 'findRelatedRecords',
            record: this.$normalizeRecordIdentity(record),
            relationship
        };
        return new FindRecordsTerm(this, expression);
    }
    $normalizeRecordType(rt) {
        if (this.$normalizer !== undefined) {
            return this.$normalizer.normalizeRecordType(rt);
        }
        else {
            return rt;
        }
    }
    $normalizeRecordIdentity(ri) {
        if (this.$normalizer !== undefined) {
            return this.$normalizer.normalizeRecordIdentity(ri);
        }
        else {
            return ri;
        }
    }
}

const { assert: assert$3, deprecate } = Orbit;
class RecordTransformBuilder {
    constructor(settings = {}) {
        const { schema, normalizer, validatorFor, recordInitializer } = settings;
        if (validatorFor) {
            assert$3('A RecordTransformBuilder that has been assigned a `validatorFor` requires a `schema`', schema !== undefined);
        }
        this.$schema = schema;
        this.$normalizer = normalizer;
        this.$validatorFor = validatorFor;
        if (recordInitializer) {
            if (this.$normalizer !== undefined) {
                deprecate('A `normalizer` and `recordInitializer` have both been assigned to the `TransformBuilder`. Only the `normalizer` will be used.');
            }
            else {
                deprecate('A `recordInitializer` has been assigned to the `TransformBuilder`. The `recordInitializer` setting has been deprecated in favor of `normalizer`, and will be treated as if it were a `RecordNormalizer`.');
                this.$normalizer = {
                    normalizeRecordType(type) {
                        return type;
                    },
                    normalizeRecord(record) {
                        return recordInitializer.initializeRecord(record);
                    },
                    normalizeRecordIdentity(recordIdentity) {
                        return recordIdentity;
                    }
                };
            }
        }
    }
    /**
     * Instantiate a new `addRecord` operation.
     */
    addRecord(record) {
        return new AddRecordTerm(this, this.$normalizeRecord(record));
    }
    /**
     * Instantiate a new `updateRecord` operation.
     */
    updateRecord(record) {
        return new UpdateRecordTerm(this, this.$normalizeRecord(record));
    }
    /**
     * Instantiate a new `removeRecord` operation.
     */
    removeRecord(record) {
        return new RemoveRecordTerm(this, this.$normalizeRecordIdentity(record));
    }
    /**
     * Instantiate a new `replaceKey` operation.
     */
    replaceKey(record, key, value) {
        return new ReplaceKeyTerm(this, this.$normalizeRecordIdentity(record), key, value);
    }
    /**
     * Instantiate a new `replaceAttribute` operation.
     */
    replaceAttribute(record, attribute, value) {
        return new ReplaceAttributeTerm(this, this.$normalizeRecordIdentity(record), attribute, value);
    }
    /**
     * Instantiate a new `addToRelatedRecords` operation.
     */
    addToRelatedRecords(record, relationship, relatedRecord) {
        return new AddToRelatedRecordsTerm(this, this.$normalizeRecordIdentity(record), relationship, this.$normalizeRecordIdentity(relatedRecord));
    }
    /**
     * Instantiate a new `removeFromRelatedRecords` operation.
     */
    removeFromRelatedRecords(record, relationship, relatedRecord) {
        return new RemoveFromRelatedRecordsTerm(this, this.$normalizeRecordIdentity(record), relationship, this.$normalizeRecordIdentity(relatedRecord));
    }
    /**
     * Instantiate a new `replaceRelatedRecords` operation.
     */
    replaceRelatedRecords(record, relationship, relatedRecords) {
        return new ReplaceRelatedRecordsTerm(this, this.$normalizeRecordIdentity(record), relationship, relatedRecords.map((ri) => this.$normalizeRecordIdentity(ri)));
    }
    /**
     * Instantiate a new `replaceRelatedRecord` operation.
     */
    replaceRelatedRecord(record, relationship, relatedRecord) {
        return new ReplaceRelatedRecordTerm(this, this.$normalizeRecordIdentity(record), relationship, relatedRecord ? this.$normalizeRecordIdentity(relatedRecord) : null);
    }
    $normalizeRecord(r) {
        if (this.$normalizer) {
            return this.$normalizer.normalizeRecord(r);
        }
        else {
            return r;
        }
    }
    $normalizeRecordIdentity(ri) {
        if (this.$normalizer !== undefined) {
            return this.$normalizer.normalizeRecordIdentity(ri);
        }
        else {
            return ri;
        }
    }
}

function buildValidatorFor(settings) {
    const { validators } = settings;
    function validatorFor(type) {
        var _a;
        return (_a = validators[type]) !== null && _a !== void 0 ? _a : validators['unknown'];
    }
    return validatorFor;
}

const validateRecordAttribute = (input, options) => {
    var _a, _b;
    if ((options === null || options === void 0 ? void 0 : options.validatorFor) === undefined) {
        throw new Assertion('validateRecordAttribute requires a `validatorFor`');
    }
    const { record, attribute, value } = input;
    const { type } = record;
    const { validatorFor, schema } = options;
    let { attributeDef } = options;
    // Validate attribute definition, if one is not provided
    if (attributeDef === undefined) {
        if (schema === undefined) {
            throw new Assertion('validateRecordAttribute requires either a `schema` or `attributeDef`');
        }
        const validateRecordFieldDefinition = validatorFor(StandardRecordValidators.RecordFieldDefinition);
        const defIssues = validateRecordFieldDefinition({ kind: 'attribute', type, field: attribute }, { schema });
        if (defIssues)
            return defIssues;
        attributeDef = schema.getAttribute(type, attribute);
    }
    // Validate value
    if (value === undefined) {
        if ((_a = attributeDef.validation) === null || _a === void 0 ? void 0 : _a.required) {
            return [
                {
                    validator: StandardRecordValidators.RecordAttribute,
                    validation: 'valueRequired',
                    description: 'value is required',
                    ref: {
                        record,
                        attribute,
                        value
                    }
                }
            ];
        }
    }
    else if (value === null) {
        if ((_b = attributeDef.validation) === null || _b === void 0 ? void 0 : _b.notNull) {
            return [
                {
                    validator: StandardRecordValidators.RecordAttribute,
                    validation: 'valueNotNull',
                    description: 'value can not be null',
                    ref: {
                        record,
                        attribute,
                        value
                    }
                }
            ];
        }
    }
    else if (attributeDef.type) {
        const validateRecordValue = validatorFor(attributeDef.type);
        if (validateRecordValue === undefined) {
            return [
                {
                    validator: StandardRecordValidators.RecordAttribute,
                    ref: {
                        record,
                        attribute,
                        value
                    },
                    validation: 'type',
                    description: `validator has not been provided for attribute '${attribute}' of \`type\` '${attributeDef.type}'`
                }
            ];
        }
        else {
            const valueIssues = validateRecordValue(value, attributeDef.validation);
            if (valueIssues) {
                return [
                    {
                        validator: StandardRecordValidators.RecordAttribute,
                        ref: {
                            record,
                            attribute,
                            value
                        },
                        validation: 'valueValid',
                        description: 'value is invalid',
                        details: valueIssues
                    }
                ];
            }
        }
    }
};

const validateRecordFieldDefinition = (input, options) => {
    if ((options === null || options === void 0 ? void 0 : options.schema) === undefined) {
        throw new Assertion('validateRecordFieldDefinition requires a `schema`');
    }
    const { schema } = options;
    const { kind, type, field } = input;
    if (!((kind === 'attribute' && schema.hasAttribute(type, field)) ||
        (kind === 'key' && schema.hasKey(type, field)) ||
        (kind === 'relationship' && schema.hasRelationship(type, field)))) {
        return [
            {
                validator: StandardRecordValidators.RecordFieldDefinition,
                validation: 'fieldDefined',
                ref: input,
                description: `${kind} '${field}' for type '${type}' is not defined in schema`
            }
        ];
    }
};

const validateRecordIdentity = (record, options) => {
    const { type, id } = record;
    if (typeof type !== 'string' || typeof id !== 'string') {
        return [
            {
                validator: StandardRecordValidators.RecordIdentity,
                validation: 'type',
                ref: record,
                description: 'Record identities must be in the form `{ type, id }`, with string values for both `type` and `id`.'
            }
        ];
    }
    // Only check type if a `modelDef` has not been provided
    if ((options === null || options === void 0 ? void 0 : options.modelDef) === undefined) {
        if ((options === null || options === void 0 ? void 0 : options.validatorFor) === undefined || (options === null || options === void 0 ? void 0 : options.schema) === undefined) {
            throw new Assertion('validateRecordIdentity requires either a `modelDef` or both a `validatorFor` and a `schema`');
        }
        const { validatorFor, schema } = options;
        const validateRecordType = validatorFor(StandardRecordValidators.RecordType);
        return validateRecordType(type, { schema });
    }
};

const validateRecordKey = (input, options) => {
    var _a, _b;
    if ((options === null || options === void 0 ? void 0 : options.validatorFor) === undefined) {
        throw new Assertion('validateRecordKey requires a `validatorFor`');
    }
    const { record, key, value } = input;
    const { type } = record;
    const { validatorFor, schema } = options;
    let { keyDef } = options;
    // Validate key definition, if one is not provided
    if (keyDef === undefined) {
        if (schema === undefined) {
            throw new Assertion('validateRecordKey requires either a `schema` or `keyDef`');
        }
        const validateRecordFieldDefinition = validatorFor(StandardRecordValidators.RecordFieldDefinition);
        const defIssues = validateRecordFieldDefinition({ kind: 'key', type, field: key }, { schema });
        if (defIssues)
            return defIssues;
        keyDef = schema.getKey(type, key);
    }
    // Validate value
    if (value === undefined) {
        if ((_a = keyDef.validation) === null || _a === void 0 ? void 0 : _a.required) {
            return [
                {
                    validator: StandardRecordValidators.RecordKey,
                    validation: 'valueRequired',
                    description: 'value is required',
                    ref: {
                        record,
                        key,
                        value
                    }
                }
            ];
        }
    }
    else if (value === null) {
        if ((_b = keyDef.validation) === null || _b === void 0 ? void 0 : _b.notNull) {
            return [
                {
                    validator: StandardRecordValidators.RecordKey,
                    validation: 'valueNotNull',
                    description: 'value can not be null',
                    ref: {
                        record,
                        key,
                        value
                    }
                }
            ];
        }
    }
    else if (typeof value !== 'string') {
        return [
            {
                validator: StandardRecordValidators.RecordKey,
                ref: {
                    record,
                    key,
                    value
                },
                validation: 'valueValid',
                description: 'value is invalid'
            }
        ];
    }
};

const validateRecordOperation = (operation, options) => {
    if ((options === null || options === void 0 ? void 0 : options.validatorFor) === undefined || (options === null || options === void 0 ? void 0 : options.schema) === undefined) {
        throw new Assertion('validateRecordOperation requires both a `validationFor` and a `schema`.');
    }
    const { schema, validatorFor } = options;
    let issues;
    switch (operation.op) {
        case 'addRecord':
            issues = validatorFor(StandardRecordValidators.Record)(operation.record, { schema, validatorFor });
            break;
        case 'updateRecord':
            issues = validatorFor(StandardRecordValidators.Record)(operation.record, {
                schema,
                validatorFor,
                partialRecord: true
            });
            break;
        case 'removeRecord':
            issues = validatorFor(StandardRecordValidators.RecordIdentity)(operation.record, { schema, validatorFor });
            break;
        case 'replaceKey':
            issues = validatorFor(StandardRecordValidators.RecordKey)(operation, { schema, validatorFor });
            break;
        case 'replaceAttribute':
            issues = validatorFor(StandardRecordValidators.RecordAttribute)(operation, { schema, validatorFor });
            break;
        case 'addToRelatedRecords':
            issues = validatorFor(StandardRecordValidators.RelatedRecord)(operation, { schema, validatorFor });
            break;
        case 'removeFromRelatedRecords':
            issues = validatorFor(StandardRecordValidators.RelatedRecord)(operation, { schema, validatorFor });
            break;
        case 'replaceRelatedRecords':
            issues = validatorFor(StandardRecordValidators.RecordRelationship)({
                record: operation.record,
                relationship: operation.relationship,
                data: operation.relatedRecords
            }, { schema, validatorFor });
            break;
        case 'replaceRelatedRecord':
            issues = validatorFor(StandardRecordValidators.RecordRelationship)({
                record: operation.record,
                relationship: operation.relationship,
                data: operation.relatedRecord
            }, { schema, validatorFor });
            break;
        default:
            return [
                {
                    validator: StandardRecordValidators.RecordOperation,
                    validation: 'operationAllowed',
                    ref: operation,
                    description: `record operation '${operation.op}' is not recognized`
                }
            ];
    }
    if (issues !== undefined) {
        return [
            {
                validator: StandardRecordValidators.RecordOperation,
                validation: 'operationValid',
                ref: operation,
                details: issues,
                description: 'record operation is invalid'
            }
        ];
    }
};

function validateFindRecordQueryExpression(expression, options) {
    const { schema, validatorFor } = options;
    const validateRecordIdentity = validatorFor(StandardRecordValidators.RecordIdentity);
    return validateRecordIdentity(expression.record, { schema, validatorFor });
}
function validateFindRelatedRecordQueryExpression(expression, options) {
    const { schema, validatorFor } = options;
    const issues = [];
    const validateRecordIdentity = validatorFor(StandardRecordValidators.RecordIdentity);
    const recordIssues = validateRecordIdentity(expression.record, {
        schema,
        validatorFor
    });
    if (recordIssues)
        issues.push(...recordIssues);
    const validateRecordFieldDefinition = validatorFor(StandardRecordValidators.RecordFieldDefinition);
    const fieldIssues = validateRecordFieldDefinition({
        kind: 'relationship',
        type: expression.record.type,
        field: expression.relationship
    }, { schema });
    if (fieldIssues)
        issues.push(...fieldIssues);
    if (issues.length > 0)
        return issues;
}
function validateFindRelatedRecordsQueryExpression(expression, options) {
    const { schema, validatorFor } = options;
    const issues = [];
    const validateRecordIdentity = validatorFor(StandardRecordValidators.RecordIdentity);
    const recordIssues = validateRecordIdentity(expression.record, {
        schema,
        validatorFor
    });
    if (recordIssues)
        issues.push(...recordIssues);
    const validateRecordFieldDefinition = validatorFor(StandardRecordValidators.RecordFieldDefinition);
    const fieldIssues = validateRecordFieldDefinition({
        kind: 'relationship',
        type: expression.record.type,
        field: expression.relationship
    }, { schema });
    if (fieldIssues)
        issues.push(...fieldIssues);
    // TODO - validate `sort`, `filter`, and `page`
    if (issues.length > 0)
        return issues;
}
function validateFindRecordsQueryExpression(expression, options) {
    const { schema, validatorFor } = options;
    const issues = [];
    if (expression.records) {
        const validateRecordIdentity = validatorFor(StandardRecordValidators.RecordIdentity);
        expression.records.forEach((r) => {
            let i = validateRecordIdentity(r, {
                schema,
                validatorFor
            });
            if (i)
                issues.push(...i);
        });
    }
    if (expression.type) {
        const validateRecordType = validatorFor(StandardRecordValidators.RecordType);
        let i = validateRecordType(expression.type, {
            schema
        });
        if (i)
            issues.push(...i);
    }
    // TODO - validate `sort`, `filter`, and `page`
    if (issues.length > 0)
        return issues;
}
const validateRecordQueryExpression = (expression, options) => {
    if ((options === null || options === void 0 ? void 0 : options.validatorFor) === undefined || (options === null || options === void 0 ? void 0 : options.schema) === undefined) {
        throw new Assertion('validateRecordQueryExpression requires both a `validationFor` and a `schema`.');
    }
    const { schema, validatorFor } = options;
    let issues;
    switch (expression.op) {
        case 'findRecord':
            issues = validateFindRecordQueryExpression(expression, {
                schema,
                validatorFor
            });
            break;
        case 'findRelatedRecord':
            issues = validateFindRelatedRecordQueryExpression(expression, {
                schema,
                validatorFor
            });
            break;
        case 'findRelatedRecords':
            issues = validateFindRelatedRecordsQueryExpression(expression, {
                schema,
                validatorFor
            });
            break;
        case 'findRecords':
            issues = validateFindRecordsQueryExpression(expression, {
                schema,
                validatorFor
            });
            break;
        default:
            return [
                {
                    validator: StandardRecordValidators.RecordQueryExpression,
                    validation: 'queryExpressionAllowed',
                    ref: expression,
                    description: `record query expression '${expression.op}' is not recognized`
                }
            ];
    }
    if (issues !== undefined) {
        return [
            {
                validator: StandardRecordValidators.RecordQueryExpression,
                validation: 'queryExpressionValid',
                ref: expression,
                details: issues,
                description: 'record query expression is invalid'
            }
        ];
    }
};

const validateRecordRelationship = (input, options) => {
    var _a, _b;
    if ((options === null || options === void 0 ? void 0 : options.validatorFor) === undefined || (options === null || options === void 0 ? void 0 : options.schema) === undefined) {
        throw new Assertion('validateRecordRelationship requires both a `validatorFor` and `schema`');
    }
    const { record, relationship, data } = input;
    const { type } = record;
    const { validatorFor, schema } = options;
    let { relationshipDef } = options;
    // Validate relationship definition, if one is not provided
    if (relationshipDef === undefined) {
        const validateRecordFieldDefinition = validatorFor(StandardRecordValidators.RecordFieldDefinition);
        const defIssues = validateRecordFieldDefinition({ kind: 'relationship', type, field: relationship }, { schema });
        if (defIssues)
            return defIssues;
        relationshipDef = schema.getRelationship(type, relationship);
    }
    // Validate data
    if (data === undefined) {
        if ((_a = relationshipDef.validation) === null || _a === void 0 ? void 0 : _a.required) {
            return [
                {
                    validator: StandardRecordValidators.RecordRelationship,
                    validation: 'dataRequired',
                    description: 'data is required',
                    ref: {
                        record,
                        relationship,
                        data
                    }
                }
            ];
        }
    }
    else {
        const dataIssues = [];
        const validateRelatedRecord = validatorFor(StandardRecordValidators.RelatedRecord);
        if (relationshipDef.kind === 'hasMany') {
            if (!Array.isArray(data)) {
                return [
                    {
                        validator: StandardRecordValidators.RecordRelationship,
                        ref: {
                            record,
                            relationship,
                            data
                        },
                        validation: 'dataValid',
                        description: 'data for a hasMany relationship must be an array'
                    }
                ];
            }
            else {
                if (relationshipDef.validation) {
                    // Validate any array constraints
                    const validateArray = validatorFor(StandardValidators.Array);
                    const arrayIssues = validateArray(data, relationshipDef.validation);
                    if (arrayIssues)
                        dataIssues.push(...arrayIssues);
                }
                for (let identity of data) {
                    let issues = validateRelatedRecord({
                        record,
                        relationship,
                        relatedRecord: identity
                    }, {
                        validatorFor,
                        schema,
                        relationshipDef
                    });
                    if (issues)
                        dataIssues.push(...issues);
                }
            }
        }
        else if (relationshipDef.kind === 'hasOne') {
            if (data === null) {
                if ((_b = relationshipDef.validation) === null || _b === void 0 ? void 0 : _b.notNull) {
                    return [
                        {
                            validator: StandardRecordValidators.RecordRelationship,
                            validation: 'dataNotNull',
                            description: 'data can not be null',
                            ref: {
                                record,
                                relationship,
                                data
                            }
                        }
                    ];
                }
            }
            else {
                if (Array.isArray(data)) {
                    return [
                        {
                            validator: StandardRecordValidators.RecordRelationship,
                            ref: {
                                record,
                                relationship,
                                data
                            },
                            validation: 'dataValid',
                            description: 'data for a hasOne relationship can not be an array'
                        }
                    ];
                }
                else {
                    let issues = validateRelatedRecord({
                        record,
                        relationship,
                        relatedRecord: data
                    }, {
                        validatorFor,
                        schema,
                        relationshipDef
                    });
                    if (issues)
                        dataIssues.push(...issues);
                }
            }
        }
        if (dataIssues.length > 0) {
            return [
                {
                    validator: StandardRecordValidators.RecordRelationship,
                    ref: {
                        record,
                        relationship,
                        data
                    },
                    validation: 'dataValid',
                    description: 'relationship data is invalid',
                    details: dataIssues
                }
            ];
        }
    }
};

const validateRecordType = (type, options) => {
    if (typeof type !== 'string') {
        return [
            {
                validator: StandardRecordValidators.RecordType,
                validation: 'type',
                ref: type,
                description: 'Record `type` must be a string.'
            }
        ];
    }
    else {
        if ((options === null || options === void 0 ? void 0 : options.schema) === undefined) {
            throw new Assertion('validateRecordType requires a `schema`');
        }
        if (!options.schema.hasModel(type)) {
            // Return early if model is not defined in schema
            return [
                {
                    validator: StandardRecordValidators.RecordType,
                    validation: 'recordTypeDefined',
                    ref: type,
                    description: `Record type '${type}' does not exist in schema`
                }
            ];
        }
    }
};

const validateRecord = (record, options) => {
    var _a, _b, _c, _d, _e, _f;
    if ((options === null || options === void 0 ? void 0 : options.validatorFor) === undefined || (options === null || options === void 0 ? void 0 : options.schema) === undefined) {
        throw new Assertion('validateRecord requires both a `validatorFor` and `schema`');
    }
    const { validatorFor, schema, partialRecord } = options;
    let { modelDef } = options;
    // Validate record identity
    const validateRecordIdentity = validatorFor(StandardRecordValidators.RecordIdentity);
    const recordIdentityIssues = validateRecordIdentity(record, {
        validatorFor,
        modelDef,
        schema
    });
    // Return early if there are any identity issue
    if (recordIdentityIssues)
        return recordIdentityIssues;
    const { type, id } = record;
    const issues = [];
    modelDef !== null && modelDef !== void 0 ? modelDef : (modelDef = schema.getModel(type));
    // Validate keys
    if (record.keys || modelDef.keys) {
        const validateKey = validatorFor(StandardRecordValidators.RecordKey);
        if (record.keys) {
            for (let k in record.keys) {
                const keyDef = (_a = modelDef.keys) === null || _a === void 0 ? void 0 : _a[k];
                const keyIssues = validateKey({ record: { type, id }, key: k, value: record.keys[k] }, { schema, keyDef, validatorFor });
                if (keyIssues)
                    issues.push(...keyIssues);
            }
        }
        // For records that are supposed to be "full" (i.e. non-partial), verify
        // all defined keys to check if any are missing
        if (!partialRecord && modelDef.keys) {
            for (let k in modelDef.keys) {
                if (((_b = record.keys) === null || _b === void 0 ? void 0 : _b[k]) === undefined) {
                    const keyDef = modelDef.keys[k];
                    const keyIssues = validateKey({ record: { type, id }, key: k, value: undefined }, { schema, keyDef, validatorFor });
                    if (keyIssues)
                        issues.push(...keyIssues);
                }
            }
        }
    }
    // Validate attributes
    if (record.attributes || modelDef.attributes) {
        const validateAttribute = validatorFor(StandardRecordValidators.RecordAttribute);
        if (record.attributes) {
            for (let a in record.attributes) {
                const attributeDef = (_c = modelDef.attributes) === null || _c === void 0 ? void 0 : _c[a];
                const attrIssues = validateAttribute({ record: { type, id }, attribute: a, value: record.attributes[a] }, { schema, attributeDef, validatorFor });
                if (attrIssues)
                    issues.push(...attrIssues);
            }
        }
        // For records that are supposed to be "full" (i.e. non-partial), verify
        // all defined attributes to check if any are missing
        if (!partialRecord && modelDef.attributes) {
            for (let a in modelDef.attributes) {
                if (((_d = record.attributes) === null || _d === void 0 ? void 0 : _d[a]) === undefined) {
                    const attributeDef = modelDef.attributes[a];
                    const attrIssues = validateAttribute({ record: { type, id }, attribute: a, value: undefined }, { schema, attributeDef, validatorFor });
                    if (attrIssues)
                        issues.push(...attrIssues);
                }
            }
        }
    }
    // Validate relationships
    if (record.relationships || modelDef.relationships) {
        const validateRelationship = validatorFor(StandardRecordValidators.RecordRelationship);
        if (record.relationships) {
            for (let r in record.relationships) {
                const relationshipDef = (_e = modelDef.relationships) === null || _e === void 0 ? void 0 : _e[r];
                const relationshipIssues = validateRelationship({
                    record: { type, id },
                    relationship: r,
                    data: record.relationships[r].data
                }, { schema, relationshipDef, validatorFor });
                if (relationshipIssues)
                    issues.push(...relationshipIssues);
            }
        }
        // For records that are supposed to be "full" (i.e. non-partial), verify
        // all defined relationships to check if any are missing
        if (!partialRecord && modelDef.relationships) {
            for (let r in modelDef.relationships) {
                if (((_f = record.relationships) === null || _f === void 0 ? void 0 : _f[r]) === undefined) {
                    const relationshipDef = modelDef.relationships[r];
                    const relationshipIssues = validateRelationship({ record: { type, id }, relationship: r, data: undefined }, { schema, relationshipDef, validatorFor });
                    if (relationshipIssues)
                        issues.push(...relationshipIssues);
                }
            }
        }
    }
    if (issues.length > 0)
        return issues;
};

const validateRelatedRecord = (input, options) => {
    if ((options === null || options === void 0 ? void 0 : options.validatorFor) === undefined || (options === null || options === void 0 ? void 0 : options.schema) === undefined) {
        throw new Assertion('validateRelatedRecord requires both a `validatorFor` and `schema`');
    }
    const { record, relationship, relatedRecord } = input;
    const { type } = record;
    const { validatorFor, schema } = options;
    let { relationshipDef } = options;
    // Validate relationship definition, if one is not provided
    if (relationshipDef === undefined) {
        const validateRecordFieldDefinition = validatorFor(StandardRecordValidators.RecordFieldDefinition);
        const defIssues = validateRecordFieldDefinition({ kind: 'relationship', type, field: relationship }, { schema });
        if (defIssues)
            return defIssues;
        relationshipDef = schema.getRelationship(type, relationship);
    }
    // Validate that relatedRecord is a valid record identity
    const validateRecordIdentity = validatorFor(StandardRecordValidators.RecordIdentity);
    const relatedRecordIssues = validateRecordIdentity(relatedRecord, {
        schema,
        validatorFor
    });
    if (relatedRecordIssues) {
        return [
            {
                validator: StandardRecordValidators.RelatedRecord,
                validation: 'relatedRecordValid',
                ref: {
                    record,
                    relationship,
                    relatedRecord
                },
                details: relatedRecordIssues,
                description: 'relatedRecord is not a valid record identity'
            }
        ];
    }
    // Validate that relatedRecord.type is allowed in relationship
    const allowedType = relationshipDef.type;
    if (allowedType) {
        const relatedType = relatedRecord.type;
        const allowedTypes = Array.isArray(allowedType)
            ? allowedType
            : [allowedType];
        if (!allowedTypes.includes(relatedType)) {
            return [
                {
                    validator: StandardRecordValidators.RelatedRecord,
                    validation: 'relatedRecordType',
                    ref: {
                        record,
                        relationship,
                        relatedRecord
                    },
                    details: {
                        allowedTypes
                    },
                    description: `relatedRecord has a type '${relatedType}' which is not an allowed type for this relationship`
                }
            ];
        }
    }
};

const standardRecordValidators = {
    record: validateRecord,
    recordAttribute: validateRecordAttribute,
    recordIdentity: validateRecordIdentity,
    recordKey: validateRecordKey,
    recordOperation: validateRecordOperation,
    recordQueryExpression: validateRecordQueryExpression,
    recordRelationship: validateRecordRelationship,
    recordType: validateRecordType,
    relatedRecord: validateRelatedRecord,
    recordFieldDefinition: validateRecordFieldDefinition
};

function buildRecordValidatorFor(settings) {
    const validators = {};
    Object.assign(validators, standardValidators);
    Object.assign(validators, standardRecordValidators);
    const customValidators = settings === null || settings === void 0 ? void 0 : settings.validators;
    if (customValidators) {
        Object.assign(validators, customValidators);
    }
    return buildValidatorFor({
        validators
    });
}

const { assert: assert$4, deprecate: deprecate$1 } = Orbit;
class StandardRecordNormalizer {
    constructor(settings) {
        const { schema, keyMap, cloneInputs, validateInputs } = settings;
        assert$4("StandardRecordNormalizer's `schema` must be specified in the `settings.schema` constructor argument", !!schema);
        this.schema = schema;
        this.keyMap = keyMap;
        this.cloneInputs = cloneInputs;
        if (validateInputs === undefined) {
            this.validateInputs = Orbit.debug ? true : false;
        }
        else {
            this.validateInputs = validateInputs;
        }
    }
    normalizeRecordType(type) {
        if (this.validateInputs) {
            if (typeof type !== 'string') {
                throw new Assertion('StandardRecordNormalizer expects record types to be strings');
            }
            if (!this.schema.hasModel(type)) {
                throw new ModelNotDefined(type);
            }
        }
        return type;
    }
    normalizeRecordIdentity(identity) {
        const { schema, keyMap, cloneInputs, validateInputs } = this;
        const { type } = identity;
        if (validateInputs && !schema.hasModel(type)) {
            throw new ModelNotDefined(type);
        }
        if (isRecordIdentity(identity)) {
            return cloneInputs ? this.cloneRecordIdentity(identity) : identity;
        }
        else {
            if (keyMap === undefined) {
                throw new Assertion("StandardRecordNormalizer's `keyMap` must be specified in order to lookup an `id` from a `key`");
            }
            const { key, value } = identity;
            if (typeof key !== 'string' || typeof value !== 'string') {
                throw new Assertion('StandardRecordNormalizer expects record identities in the form `{ type: string, id: string }` or `{ type: string, key: string, value: string }`');
            }
            if (validateInputs && !schema.hasKey(type, key)) {
                throw new KeyNotDefined(type, key);
            }
            let id = keyMap.keyToId(type, key, value);
            if (id === undefined) {
                id = this.schema.generateId(type);
                keyMap.pushRecord({ type, id, keys: { [key]: value } });
            }
            return { type, id };
        }
    }
    normalizeRecord(record) {
        const { keyMap, schema, cloneInputs, validateInputs } = this;
        const { type } = record;
        if (validateInputs && !schema.hasModel(type)) {
            throw new ModelNotDefined(type);
        }
        let result = cloneInputs ? this.cloneRecord(record) : record;
        // If `initializeRecord` has been defined on the schema, continue to call it
        // but issue a deprecation warning.
        // TODO: Remove in v0.18
        if (schema.initializeRecord !== undefined) {
            deprecate$1("RecordSchema's `initializeRecord` method should NOT be defined. Instead override `normalizeRecord` on the RecordNormalizer to initialize records.");
            result = schema.initializeRecord(result);
        }
        if (result.id === undefined) {
            const { keys } = result;
            // Lookup id from keys if possible
            if (keyMap !== undefined && keys !== undefined) {
                if (validateInputs) {
                    Object.keys(keys).forEach((key) => {
                        if (!schema.hasKey(type, key)) {
                            throw new KeyNotDefined(type, key);
                        }
                    });
                }
                const id = keyMap.idFromKeys(result.type, keys);
                if (id) {
                    result.id = id;
                }
            }
            // Generate an id if one has still not been assigned
            if (result.id === undefined) {
                result.id = schema.generateId(result.type);
                // Register any generated ids in the keyMap, if present
                if (keyMap && result.keys !== undefined) {
                    keyMap.pushRecord(result);
                }
            }
        }
        return result;
    }
    cloneRecordIdentity(rid) {
        return cloneRecordIdentity(rid);
    }
    cloneRecord(record) {
        return clone(record);
    }
}

const { assert: assert$5 } = Orbit;
/**
 * Abstract base class for record-based sources.
 */
class RecordSource extends Source {
    constructor(settings) {
        const autoActivate = settings.autoActivate === undefined || settings.autoActivate;
        const { schema } = settings;
        let { validatorFor, validators } = settings;
        if (validatorFor) {
            assert$5('RecordSource can be constructed with either a `validatorFor` or `validators`, but not both', validators === undefined);
        }
        else if (Orbit.debug || validators !== undefined) {
            validatorFor = buildRecordValidatorFor({ validators });
        }
        assert$5("RecordSource's `schema` must be specified in `settings.schema` constructor argument", !!schema);
        if (settings.queryBuilder === undefined ||
            settings.transformBuilder === undefined) {
            let { normalizer } = settings;
            if (normalizer === undefined) {
                normalizer = new StandardRecordNormalizer({
                    schema,
                    keyMap: settings.keyMap
                });
            }
            if (settings.queryBuilder === undefined) {
                settings.queryBuilder = new RecordQueryBuilder({
                    schema,
                    normalizer,
                    validatorFor
                });
            }
            if (settings.transformBuilder === undefined) {
                settings.transformBuilder = new RecordTransformBuilder({
                    schema,
                    normalizer,
                    validatorFor
                });
            }
        }
        super({ ...settings, autoActivate: false });
        this._schema = schema;
        this._keyMap = settings.keyMap;
        this._validatorFor = validatorFor;
        if (settings.autoUpgrade === undefined || settings.autoUpgrade) {
            this._schema.on('upgrade', () => this.upgrade());
        }
        if (autoActivate) {
            this.activate();
        }
    }
    get schema() {
        return this._schema;
    }
    get keyMap() {
        return this._keyMap;
    }
    get validatorFor() {
        return this._validatorFor;
    }
    get queryBuilder() {
        return this._queryBuilder;
    }
    get transformBuilder() {
        return this._transformBuilder;
    }
    /**
     * Upgrade source as part of a schema upgrade.
     */
    async upgrade() {
        return;
    }
}

export { ClientError as C, NetworkError as N, OperationTerm as O, QueryExpressionParseError as Q, RecordSource as R, StandardRecordNormalizer as S, TransformNotAllowed as T, equalRecordIdentitySets as a, equalRecordIdentities as b, cloneRecordIdentity as c, buildQuery as d, eq as e, buildTransform as f, recordsReferencedByOperations as g, deserializeRecordIdentity as h, buildRecordValidatorFor as i, RecordQueryBuilder as j, RecordTransformBuilder as k, uniqueRecordIdentities as l, mergeRecords as m, mapNamedFullResponses as n, ServerError as o, recordDiffs as p, queryable as q, recordsInclude as r, serializeRecordIdentity as s, QueryNotAllowed as t, updatable as u, validateRecordOperation as v, coalesceRecordOperations as w };
