import {Theme, Wrap} from "../../components/index.js";
import {Config} from "../../data/config.js";
import {memory, remote} from "../../data/orbit.js";
import {useAuth} from "../../../_snowpack/pkg/@ironbay/ship-react.js";
import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
const BannerRoot = styled.a`
  background: ${Theme.colors.primary};
  display: block;
  cursor: pointer;
`;
const BannerCenter = styled.div`
  padding: ${Theme.spacing.size400} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-weight: 600;
`;
export function Banner() {
  const auth = useAuth();
  const [checkout, checkout_set] = useState("");
  async function loadCheckout() {
    const data = {
      id: "current",
      type: "ship_billing_checkout",
      attributes: {
        callbacks: {
          cancel: location.href,
          success: location.href
        },
        price_id: Config.STRIPE_PRODUCT
      },
      relationships: {}
    };
    const result = await remote.update((q) => q.addRecord(data));
    checkout_set(result.attributes.url);
  }
  useEffect(() => {
    const product = memory.cache.query((q) => q.findRecords("ship_billing_product")).find((i) => i.id === Config.STRIPE_PRODUCT);
    if (product)
      return;
    loadCheckout();
  }, []);
  if (!checkout)
    return null;
  return /* @__PURE__ */ React.createElement(BannerRoot, {
    href: checkout
  }, /* @__PURE__ */ React.createElement(Wrap, {
    width: "lg"
  }, /* @__PURE__ */ React.createElement(BannerCenter, null, "Enjoying using Nori? We'd really appreciate it if you subscribed")));
}
