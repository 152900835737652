import styled, {css} from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./index.js";
const sm = `
    height: 35px;
    font-size: ${Theme.font.size350};
    min-width: 6rem;
  `;
const md = css`
  height: 50px;
  font-size: ${Theme.font.size400};
  min-width: 8rem;
`;
const sizes = {
  sm,
  md
};
const primary = css`
  background: ${Theme.colors.green500};

  &:active {
    background: ${Theme.colors.green600};
  }
`;
const primary_outline = css`
  background: transparent;
  border: 1px solid ${Theme.colors.green500};
  color: ${Theme.colors.green500};
`;
const secondary = css`
  background: ${Theme.colors.gray500};
  border: 1px solid ${Theme.colors.border};
  color: ${Theme.colors.gray900};

  &:active {
    background: ${Theme.colors.gray600};
  }
`;
const danger = css`
  background: ${Theme.colors.red500};

  &:active {
    background: ${Theme.colors.red600};
  }
`;
const variants = {
  primary,
  primary_outline,
  secondary,
  danger
};
export const Button = styled.div`
  border-radius: 8px;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: ${(props) => props.onClick ? "pointer" : "initial"};
  user-select: none;

  padding: 0 ${Theme.spacing.size600};
  transition: 300ms background ${Theme.easing.default};

  ${(props) => sizes[props.size || "md"]}
  ${(props) => variants[props.variant || "primary"]}


  &:active {
    transform: translate3d(0, 2px, 0);
  }
`;
