import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {Theme} from "./theme.js";
export const FormToggle = styled(function FormCheck({
  value,
  children,
  disabled,
  ...rest
}) {
  return /* @__PURE__ */ React.createElement("div", {
    ...rest
  }, /* @__PURE__ */ React.createElement(Toggle, {
    disabled,
    value
  }, /* @__PURE__ */ React.createElement(Block, {
    value
  })), children && /* @__PURE__ */ React.createElement(Text, null, children));
})`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Toggle = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 3px;
  transition: 300ms all cubic-bezier(0, 0.67, 0.27, 1);
  width: 36px;
  height: 20px;
  border-radius: 6px;
  background-color: ${(props) => {
  if (props.disabled)
    return Theme.colors.border;
  if (props.value)
    return Theme.colors.primary;
  return Theme.colors.border;
}};

  ${FormToggle}:hover & {
    border-color: ${(props) => !props.disabled && Theme.colors.primary};
  }
`;
const Block = styled.div`
  background: white;
  border-radius: 4px;
  width: 14px;
  height: 14px;
  transition: 300ms all cubic-bezier(0, 0.67, 0.27, 1);
  transform: ${(props) => props.value && "translateX(100%)"};
  background: white;
`;
const Text = styled.div`
  margin-left: ${Theme.spacing.size300};
  font-weight: 500;
`;
