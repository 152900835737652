import JsonApi, {
  JSONAPIRequestProcessor,
  JSONAPIURLBuilder
} from "../../_snowpack/pkg/@orbit/jsonapi.js";
import MemorySource from "../../_snowpack/pkg/@orbit/memory.js";
import {RecordSchema} from "../../_snowpack/pkg/@orbit/records.js";
import Coordinator, {SyncStrategy, RequestStrategy} from "../../_snowpack/pkg/@orbit/coordinator.js";
import IndexedDBSource from "../../_snowpack/pkg/@orbit/indexeddb.js";
import {v4} from "../../_snowpack/pkg/uuid.js";
import * as Serializers from "../../_snowpack/pkg/@orbit/serializers.js";
import {Models} from "./models.js";
import {Config} from "./config.js";
import {Orbit} from "../../_snowpack/pkg/@orbit/core.js";
import {Api} from "../../_snowpack/pkg/@ironbay/ship-client.js";
Orbit.debug = false;
console.log(Api.Models);
export const schema = new RecordSchema({
  singularize: (word) => {
    return word;
  },
  pluralize: (word) => {
    return word;
  },
  models: {
    ...Models,
    ...Api.Models
  }
});
class RemoteRequestProcessor extends JSONAPIRequestProcessor {
  initFetchSettings(customsettings = {}) {
    const settings = super.initFetchSettings(customsettings);
    settings.timeout = 3e4;
    settings.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return settings;
  }
}
class UrlBuilder extends JSONAPIURLBuilder {
  resourceURL(type, id) {
    const result = super.resourceURL(type, id);
    if (type.startsWith("ship_"))
      return result.replace(Config.API_URL, Config.SHIP_API_URL);
    return result;
  }
}
export const remote = new JsonApi({
  schema,
  host: Config.API_URL,
  name: "remote",
  URLBuilderClass: UrlBuilder,
  RequestProcessorClass: RemoteRequestProcessor,
  serializerFor: () => new Serializers.NoopSerializer()
});
export const memory = new MemorySource({
  schema,
  name: "memory"
});
export const backup = new IndexedDBSource({
  schema,
  name: "backup",
  namespace: "nori"
});
export const coordinator = new Coordinator({
  sources: [memory, remote, backup]
});
coordinator.addStrategy(new RequestStrategy({
  source: "memory",
  on: "beforeQuery",
  target: "remote",
  action: "query",
  blocking: false
}));
coordinator.addStrategy(new RequestStrategy({
  source: "memory",
  on: "beforeUpdate",
  target: "remote",
  action: "update",
  blocking: false
}));
coordinator.addStrategy(new RequestStrategy({
  on: "queryFail",
  source: "remote",
  action(_op, _error) {
  }
}));
coordinator.addStrategy(new RequestStrategy({
  on: "updateFail",
  source: "remote",
  action(op, error) {
    remote.requestQueue.skip(error);
    const inverse = {
      id: v4(),
      operations: memory.getInverseOperations(op.id)
    };
    memory.sync(inverse).catch(() => {
    });
  }
}));
coordinator.addStrategy(new SyncStrategy({
  source: "remote",
  target: "memory",
  blocking: true
}));
coordinator.addStrategy(new SyncStrategy({
  source: "memory",
  target: "backup",
  blocking: false,
  filter: (input) => {
    if (input.operations.some((i) => ["ship_billing_product"].includes(i.record.type))) {
      return false;
    }
    return true;
  }
}));
