export const Models = {
  ship_user: {
    attributes: {
      email: {
        type: "string"
      },
      name_first: {
        type: "string"
      },
      name_last: {
        type: "string"
      },
      slug: {
        type: "string"
      }
    },
    relationships: {
      templates: {
        inverse: "user",
        model: "template",
        kind: "hasMany"
      },
      sessions: {
        inverse: "user",
        model: "session",
        kind: "hasMany"
      },
      google: {
        inverse: "user",
        model: "google",
        kind: "hasMany"
      },
      calendars: {
        inverse: "user",
        model: "calendar",
        kind: "hasMany"
      }
    }
  },
  ship_billing_product: {
    attributes: {},
    relationships: {
      user: {
        model: "ship_user",
        kind: "hasOne"
      }
    }
  },
  ship_billing_session: {
    attributes: {
      url: {
        type: "string"
      },
      return: {
        type: "string"
      }
    },
    relationships: {
      user: {
        model: "ship_user",
        kind: "hasOne"
      }
    }
  },
  user: {
    attributes: {
      email: {
        type: "string"
      },
      name_first: {
        type: "string"
      },
      name_last: {
        type: "string"
      },
      slug: {
        type: "string"
      }
    },
    relationships: {
      templates: {
        inverse: "user",
        model: "template",
        kind: "hasMany"
      },
      sessions: {
        inverse: "user",
        model: "session",
        kind: "hasMany"
      },
      google: {
        inverse: "user",
        model: "google",
        kind: "hasMany"
      },
      calendars: {
        inverse: "user",
        model: "calendar",
        kind: "hasMany"
      }
    }
  },
  booking: {
    attributes: {
      name: {
        type: "string"
      },
      description: {
        type: "string"
      },
      start: {
        type: "string"
      },
      end: {
        type: "string"
      },
      guests: {
        type: "array"
      },
      from: {
        type: "object"
      }
    },
    relationships: {
      template: {
        model: "template",
        kind: "hasOne"
      },
      calendar: {
        model: "calendar",
        kind: "hasOne"
      }
    }
  },
  template: {
    attributes: {
      name: {
        type: "string"
      },
      alias: {
        type: "string"
      },
      timezone: {
        type: "string"
      },
      description: {
        type: "string"
      },
      slug: {
        type: "string"
      },
      duration: {
        type: "number"
      },
      buffer: {
        type: "number"
      },
      location_type: {
        type: "string"
      },
      location_data: {
        type: "string"
      },
      private: {
        type: "boolean"
      },
      unnamed: {
        type: "boolean"
      },
      optimize: {
        type: "boolean"
      },
      notify: {
        type: "boolean"
      },
      guests: {
        type: "array"
      },
      availability: {
        type: "array"
      }
    },
    relationships: {
      constraints: {
        model: "calendar",
        kind: "hasMany"
      },
      target: {
        model: "calendar",
        kind: "hasOne"
      },
      user: {
        inverse: "templates",
        model: "user",
        kind: "hasOne"
      },
      openings: {
        inverse: "template",
        model: "opening",
        kind: "hasMany"
      }
    }
  },
  calendar: {
    attributes: {
      connection: {
        type: "string"
      },
      name: {
        type: "string"
      }
    },
    relationships: {
      user: {
        inverse: "calendars",
        model: "user",
        kind: "hasOne"
      }
    }
  },
  google: {
    attributes: {
      email: {
        type: "string"
      },
      google_id: {
        type: "string"
      }
    },
    relationships: {
      user: {
        inverse: "google",
        model: "user",
        kind: "hasOne"
      }
    }
  },
  login: {
    attributes: {
      email: {
        type: "string"
      },
      times_expiry: {
        type: "string"
      },
      times_created: {
        type: "string"
      }
    },
    relationships: {}
  },
  session: {
    attributes: {
      times_expiry: {
        type: "string"
      },
      times_created: {
        type: "string"
      }
    },
    relationships: {
      user: {
        inverse: "sessions",
        model: "user",
        kind: "hasOne"
      }
    }
  },
  opening: {
    attributes: {
      start: {
        type: "string"
      },
      end: {
        type: "string"
      },
      date: {
        type: "string"
      },
      preferred: {
        type: "boolean"
      }
    },
    relationships: {
      template: {
        inverse: "openings",
        model: "template",
        kind: "hasOne"
      }
    }
  }
};
