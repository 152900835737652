import React from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {Theme, Wrap} from "../../components/index.js";
import {Link} from "../../../_snowpack/pkg/@reach/router.js";
import {Logo} from "../../structures/Logo.js";
import {memory, remote} from "../../data/orbit.js";
import {useAuth} from "../../../_snowpack/pkg/@ironbay/ship-react.js";
import {Config} from "../../data/config.js";
const Root = styled.div`
  padding: ${Theme.spacing.size600};
  border-bottom: 1px solid ${Theme.colors.border};
  background: white;
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
`;
const Navigation = styled.div`
  flex-grow: 1;
  margin-left: ${Theme.spacing.size600};
`;
const NavigationLink = styled(Link)`
  font-weight: 600;
  font-size: ${Theme.font.size375};
  margin-left: ${Theme.spacing.size600};
  text-decoration: none;
  color: ${Theme.colors.textLight};
  cursor: pointer;
`;
NavigationLink.defaultProps = {
  getProps: (e) => {
    return {
      style: {
        color: e.isPartiallyCurrent ? Theme.colors.textDark : Theme.colors.textLight
      }
    };
  }
};
const Name = styled.div`
  padding-right: ${Theme.spacing.size600};
  border-right: 1px solid ${Theme.colors.border};
  font-weight: 500;
`;
export function Header() {
  const auth = useAuth();
  const self = memory.cache.query((q) => q.findRecord({type: "user", id: auth.user}));
  const product = memory.cache.query((q) => q.findRecords("ship_billing_product")).find((i) => i.id === Config.STRIPE_PRODUCT);
  return /* @__PURE__ */ React.createElement(Root, null, /* @__PURE__ */ React.createElement(Wrap, {
    width: "lg"
  }, /* @__PURE__ */ React.createElement(Inner, null, /* @__PURE__ */ React.createElement(Logo, {
    height: 30
  }), /* @__PURE__ */ React.createElement(Navigation, null, /* @__PURE__ */ React.createElement(NavigationLink, {
    to: "/dashboard/templates"
  }, "Templates"), /* @__PURE__ */ React.createElement(NavigationLink, {
    to: "/dashboard/connections"
  }, "Connections"), /* @__PURE__ */ React.createElement(NavigationLink, {
    to: "/dashboard/account"
  }, "Account"), product && /* @__PURE__ */ React.createElement(NavigationLink, {
    as: "span",
    onClick: async () => {
      try {
        const result = await remote.update((q) => q.addRecord({
          id: "current",
          type: "ship_billing_session",
          attributes: {
            return: location.href
          },
          relationships: {}
        }));
        location.href = result.attributes.url;
      } catch (ex) {
        console.log(ex);
      }
    }
  }, "Billing")), /* @__PURE__ */ React.createElement(Name, null, self?.attributes?.slug), /* @__PURE__ */ React.createElement(NavigationLink, {
    to: "/auth/logout"
  }, "Logout"))));
}
