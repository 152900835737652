import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {Link} from "../../../_snowpack/pkg/@reach/router.js";
import {Title, Description, Back} from "./common.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {Theme, Wrap, Button} from "../../components/index.js";
import {FiChevronLeft, FiChevronRight} from "../../../_snowpack/pkg/react-icons/fi.js";
import {format} from "../../../_snowpack/pkg/date-fns.js";
import {remote} from "../../data/orbit.js";
import {DateTime, Interval} from "../../../_snowpack/pkg/luxon.js";
const Flex = styled.div`
  display: flex;
  margin-top: ${Theme.spacing.size800};
  flex-direction: column;

  ${Theme.media.md} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;
const Calendar = styled.div`
  width: 450px;
  max-width: 100%;
  background: white;
  border: 1px solid ${Theme.colors.border};
  padding: ${Theme.spacing.size800};
  border-radius: ${Theme.spacing.size400};
`;
const CalendarHeader = styled.div`
  padding: ${Theme.spacing.size400} 0;
  border-bottom: 1px solid ${Theme.colors.border};
  display: flex;
`;
const CalendarHeaderMonth = styled.div`
  flex-grow: 1;
  font-weight: 600;
  font-size: ${Theme.font.size500};
`;
const CalendarHeaderControls = styled.div`
  display: flex;
  align-items: center;

  & > * {
    width: 25px;
    height: 25px;
    margin-left: ${Theme.spacing.size200};
    cursor: pointer;
  }
`;
const CalendarGrid = styled.div`
  margin-top: ${Theme.spacing.size600};
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: ${Theme.spacing.size200};
  ${Theme.media.md} {
    gap: ${Theme.spacing.size500};
  }
`;
const CalendarDow = styled.div`
  color: ${Theme.colors.textLight};
  text-align: center;
  font-size: ${Theme.font.size375};

  ${Theme.media.md} {
    font-size: ${Theme.font.size400};
  }
`;
const CalendarDate = styled.div`
  transition: 300ms all ${Theme.easing.default};
  position: relative;
  width: 100%;
  border: 1px solid ${Theme.colors.primary};
  transition: 300ms all ${Theme.easing.default};
  border-color: ${(props) => !props.available && "transparent"};
  color: ${(props) => !props.available && Theme.colors.gray600};
  border-radius: 6px;
  cursor: ${(props) => props.available && "pointer"};
  font-weight: 600;
  color: ${Theme.colors.textDark};
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.selected && `
      background: ${Theme.colors.primary};
      color: white;
    `}
  ${(props) => props.available && `&:hover {
      background: ${Theme.colors.primary};
      color: white;
    }`}

    &:before {
    content: "";
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
  }
`;
const Intervals = styled.div`
  overflow-x: hidden;
  overflow-y: auto;

  ${Theme.media.md} {
    height: 450px;
    padding-left: ${Theme.spacing.size800};
    padding-right: ${Theme.spacing.size800};
  }
`;
const IntervalsScroller = styled.div``;
const IntervalsHeader = styled.div`
  padding: ${Theme.spacing.size800} 0;
  display: flex;
  align-items: center;
`;
const IntervalsTimezone = styled.div`
  padding: 0.4rem 0.6rem;
  border-radius: 6px;
  background: ${Theme.colors.gray500};
  font-weight: 600;
  color: ${Theme.colors.textLight};
  font-size: ${Theme.font.size325};
`;
const IntervalsText = styled.div`
  flex-grow: 1;
  font-size: ${Theme.font.size400};
  font-weight: 600;
`;
const IntervalsRow = styled.div`
  white-space: nowrap;
`;
const IntervalsButton = styled(Button)`
  display: inline-flex;
  padding-left: 0;
  padding-right: 0;
  transition: 300ms all ${Theme.easing.default};
  width: ${(props) => props.selected ? `calc(50% - ${Theme.spacing.size200})` : "100%"};
  min-width: initial;
  flex: 1;
`;
const IntervalsConfirm = styled(Button)`
  display: inline-flex;
  transition: 300ms all ${Theme.easing.default};
  min-width: initial;
  width: calc(50% - ${Theme.spacing.size200});
  margin-left: ${(props) => props.selected ? Theme.spacing.size400 : Theme.spacing.size800};
`;
const IntervalsGrid = styled.div`
  display: grid;
  grid-gap: ${Theme.spacing.size400};
`;
export function Schedule(props) {
  const {template} = props;
  const [confirm, confirm_set] = useState("");
  const [openings, openings_set] = useState({});
  const [start, start_set] = React.useState(DateTime.now().startOf("month"));
  const end = start.endOf("month");
  const [selected, selected_set] = React.useState(DateTime.now().startOf("day"));
  useEffect(() => {
    openings_set({});
    remote.query((q) => q.findRelatedRecords({type: "template", id: template.id}, "openings").filter({
      attribute: "start",
      value: start.toISO()
    }, {
      attribute: "end",
      value: end.toISO()
    })).then((r) => {
      const next = {};
      for (let o of r) {
        const arr = next[o.attributes.date] || [];
        arr.push(o);
        next[o.attributes.date] = arr;
      }
      openings_set(next);
    });
  }, [start]);
  const selected_date = selected.toISODate();
  const selected_openings = openings[selected_date] || [];
  const has_preferred = selected_openings.some((item) => item.attributes.preferred);
  return /* @__PURE__ */ React.createElement(Wrap, {
    width: "md"
  }, !props.direct && /* @__PURE__ */ React.createElement(Back, {
    to: "../"
  }, "Back to ", props.user.attributes.name_first, "'s events"), /* @__PURE__ */ React.createElement(Title, null, template.attributes.name), /* @__PURE__ */ React.createElement(Description, null, "Select a date and time for this event"), /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(Calendar, null, /* @__PURE__ */ React.createElement(CalendarHeader, null, /* @__PURE__ */ React.createElement(CalendarHeaderMonth, null, start.toFormat("MMMM yyyy")), /* @__PURE__ */ React.createElement(CalendarHeaderControls, null, /* @__PURE__ */ React.createElement(FiChevronLeft, {
    onClick: () => {
      const next = start.minus({month: 1});
      if (next.diff(DateTime.now().startOf("month")).toMillis() < 0)
        return;
      start_set(next);
    }
  }), /* @__PURE__ */ React.createElement(FiChevronRight, {
    onClick: () => start_set(start.plus({month: 1}))
  }))), /* @__PURE__ */ React.createElement(CalendarGrid, null, /* @__PURE__ */ React.createElement(CalendarDow, null, "Sun"), /* @__PURE__ */ React.createElement(CalendarDow, null, "Mon"), /* @__PURE__ */ React.createElement(CalendarDow, null, "Tue"), /* @__PURE__ */ React.createElement(CalendarDow, null, "Wed"), /* @__PURE__ */ React.createElement(CalendarDow, null, "Thu"), /* @__PURE__ */ React.createElement(CalendarDow, null, "Fri"), /* @__PURE__ */ React.createElement(CalendarDow, null, "Sat"), Interval.fromDateTimes(start.startOf("week").minus({day: 1}), end).splitBy({
    day: 1
  }).map((item) => {
    const current = item.start;
    if (current.month !== start.month)
      return /* @__PURE__ */ React.createElement("div", {
        key: current.toISO()
      });
    const current_openings = openings[current.toISODate()] || [];
    const available = current_openings.length > 0;
    return /* @__PURE__ */ React.createElement(CalendarDate, {
      onClick: available ? () => selected_set(current) : void 0,
      available,
      selected: current.equals(selected) && available
    }, current.day);
  }))), selected_openings.length > 0 && /* @__PURE__ */ React.createElement(Intervals, null, /* @__PURE__ */ React.createElement(IntervalsScroller, null, ["preferred", "all"].map((type) => {
    const values = selected_openings.filter((item) => type === "all" || item.attributes.preferred);
    if (type === "preferred" && values.length === 0)
      return null;
    return /* @__PURE__ */ React.createElement(React.Fragment, {
      key: type
    }, /* @__PURE__ */ React.createElement(IntervalsHeader, null, /* @__PURE__ */ React.createElement(IntervalsText, null, type === "preferred" ? "Preferred times" : has_preferred ? "All times" : "Select a time"), /* @__PURE__ */ React.createElement(IntervalsTimezone, null, new Date().toLocaleTimeString("en-us", {
      timeZoneName: "short"
    }).split(" ")[2])), /* @__PURE__ */ React.createElement(IntervalsGrid, null, values.map((val) => {
      const active = val.id === confirm;
      return /* @__PURE__ */ React.createElement(IntervalsRow, {
        key: val.id
      }, /* @__PURE__ */ React.createElement(IntervalsButton, {
        selected: active,
        onClick: () => {
          confirm_set(active ? "" : val.id);
        },
        variant: "primary_outline"
      }, format(new Date(val.attributes.start), "hh:mm a")), /* @__PURE__ */ React.createElement(IntervalsConfirm, {
        selected: active,
        as: Link,
        to: `./${new Date(val.attributes.start).getTime()}`,
        variant: "primary"
      }, "Confirm"));
    }), /* @__PURE__ */ React.createElement("div", null)));
  })))));
}
