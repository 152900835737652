import { E as Exception, O as Orbit, A as Assertion } from '../common/log-e4bf9a42.js';
import { Q as QueryExpressionParseError, N as NetworkError, C as ClientError, o as ServerError, c as cloneRecordIdentity, f as buildTransform, p as recordDiffs, b as equalRecordIdentities, R as RecordSource, t as QueryNotAllowed, T as TransformNotAllowed, q as queryable, u as updatable } from '../common/record-source-87b54fe8.js';
import { r as requestOptionsForSource } from '../common/source-bf183794.js';
import { p as pullable, a as pushable } from '../common/pushable-a80774d7.js';
import { R as RecordNotFoundException } from '../common/standard-validators-4ed2ae82.js';
import { c as clone, b as deepMerge, a as deepSet, t as toArray } from '../common/objects-398cfae3.js';
import { B as BaseSerializer, S as StringSerializer, b as buildSerializerClassFor, N as NoopSerializer, a as BooleanSerializer, D as DateSerializer, c as DateTimeSerializer, d as NumberSerializer, e as buildSerializerSettingsFor, f as buildSerializerFor } from '../common/serializer-builders-f96d2a83.js';

class InvalidServerResponse extends Exception {
    constructor(response) {
        super(`Invalid server response: ${response}`);
        this.response = response;
    }
}

function buildFetchSettings(options = {}, customSettings) {
    let settings = options.settings ? clone(options.settings) : {};
    if (customSettings) {
        deepMerge(settings, customSettings);
    }
    ['filter', 'include', 'page', 'sort'].forEach((param) => {
        let value = options[param];
        if (value) {
            if (param === 'include' && Array.isArray(value)) {
                value = value.join(',');
            }
            deepSet(settings, ['params', param], value);
        }
    });
    return settings;
}
function mergeJSONAPIRequestOptions(options, customOptions) {
    const result = Object.assign({}, options, customOptions);
    if (options.include && customOptions.include) {
        result.include = mergeIncludePaths(options.include, customOptions.include);
    }
    if (options.filter && customOptions.filter) {
        result.filter = mergeFilters(options.filter, customOptions.filter);
    }
    return result;
}
function mergeIncludePaths(paths, customPaths) {
    const result = clone(paths);
    for (let customPath of customPaths) {
        if (!paths.includes(customPath)) {
            result.push(customPath);
        }
    }
    return result;
}
function mergeFilters(filters, customFilters) {
    const result = clone(filters);
    let filterKeys = filters.map((f) => filterKey(f));
    for (let customFilter of customFilters) {
        let customerFilterKey = filterKey(customFilter);
        let filterToOverride;
        if (filterKeys.includes(customerFilterKey)) {
            filterToOverride = result.find((f) => filterKey(f) === customerFilterKey);
        }
        if (filterToOverride) {
            setFilterValue(filterToOverride, filterValue(customFilter));
        }
        else {
            result.push(customFilter);
        }
    }
    return result;
}
function filterKey(filter) {
    return Object.keys(filter)[0];
}
function filterValue(filter) {
    return Object.values(filter)[0];
}
function setFilterValue(filter, value) {
    filter[filterKey(filter)] = value;
}

function flattenObjectToParams(obj, path = []) {
    let params = [];
    Object.keys(obj).forEach((key) => {
        if (!obj.hasOwnProperty(key)) {
            return;
        }
        let newPath = path.slice();
        newPath.push(key);
        if (obj[key] !== null && typeof obj[key] === 'object') {
            Array.prototype.push.apply(params, flattenObjectToParams(obj[key], newPath));
        }
        else {
            params.push({
                path: newPath,
                val: obj[key]
            });
        }
    });
    return params;
}
function encodeQueryParams(obj) {
    return flattenObjectToParams(obj)
        .map((rawParam) => {
        let path;
        let val = rawParam.val;
        if (val === null) {
            val = 'null';
        }
        if (rawParam.path.length === 1) {
            path = rawParam.path[0];
        }
        else {
            let firstSegment = rawParam.path[0];
            let remainingSegments = rawParam.path.slice(1);
            path = firstSegment + '[' + remainingSegments.join('][') + ']';
        }
        return { path, val };
    })
        .map((param) => encodeURIComponent(param.path) + '=' + encodeURIComponent(param.val))
        .join('&');
}
function appendQueryParams(url, obj) {
    let fullUrl = url;
    if (obj.filter && Array.isArray(obj.filter)) {
        let filter = obj.filter;
        delete obj.filter;
        filter.forEach((filterOption) => {
            fullUrl = appendQueryParams(fullUrl, { filter: filterOption });
        });
    }
    let queryParams = encodeQueryParams(obj);
    if (queryParams.length > 0) {
        fullUrl += nextQueryParamIndicator(fullUrl);
        fullUrl += queryParams;
    }
    return fullUrl;
}
function nextQueryParamIndicator(url) {
    if (url.indexOf('?') === -1) {
        return '?';
    }
    return '&';
}

var JSONAPISerializers;
(function (JSONAPISerializers) {
    JSONAPISerializers["Resource"] = "resource";
    JSONAPISerializers["ResourceField"] = "resourceField";
    JSONAPISerializers["ResourceIdentity"] = "resourceIdentity";
    JSONAPISerializers["ResourceType"] = "resourceType";
    JSONAPISerializers["ResourceDocument"] = "resourceDocument";
    JSONAPISerializers["ResourceTypePath"] = "resourceTypePath";
    JSONAPISerializers["ResourceFieldPath"] = "resourceFieldPath";
    JSONAPISerializers["ResourceFieldParam"] = "resourceFieldParam";
    JSONAPISerializers["ResourceAtomicOperation"] = "resourceAtomicOperation";
    JSONAPISerializers["ResourceAtomicOperationsDocument"] = "resourceAtomicOperationsDocument";
    JSONAPISerializers["ResourceAtomicResultsDocument"] = "resourceAtomicResultsDocument";
})(JSONAPISerializers || (JSONAPISerializers = {}));

const { deprecate } = Orbit;
class JSONAPIURLBuilder {
    constructor(settings) {
        this.host = settings.host;
        this.namespace = settings.namespace;
        this.serializerFor = settings.serializerFor;
        if (settings.serializer) {
            this.serializer = settings.serializer;
            deprecate("The 'serializer' setting for 'JSONAPIURLBuilder' has been deprecated. Pass 'serializerFor' instead.");
        }
        this.keyMap = settings.keyMap;
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    resourceNamespace(type) {
        return this.namespace;
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    resourceHost(type) {
        return this.host;
    }
    resourceURL(type, id) {
        let host = this.resourceHost(type);
        let namespace = this.resourceNamespace(type);
        let url = [];
        if (host) {
            url.push(host);
        }
        if (namespace) {
            url.push(namespace);
        }
        url.push(this.resourcePath(type, id));
        if (!host) {
            url.unshift('');
        }
        return url.join('/');
    }
    resourcePath(type, id) {
        let resourceType, resourceId;
        if (this.serializer) {
            resourceType = this.serializer.resourceType(type);
            if (id) {
                resourceId = this.serializer.resourceId(type, id);
            }
        }
        else {
            const resourceTypeSerializer = this.serializerFor(JSONAPISerializers.ResourceTypePath);
            resourceType = resourceTypeSerializer.serialize(type);
            if (id) {
                const resourceIdentitySerializer = this.serializerFor(JSONAPISerializers.ResourceIdentity);
                const identity = resourceIdentitySerializer.serialize({
                    type,
                    id
                });
                resourceId = identity.id;
            }
        }
        let path = [resourceType];
        if (resourceId) {
            path.push(resourceId);
        }
        return path.join('/');
    }
    resourceRelationshipURL(type, id, relationship) {
        return (this.resourceURL(type, id) +
            '/relationships/' +
            this.serializeRelationshipInPath(type, relationship));
    }
    relatedResourceURL(type, id, relationship) {
        return (this.resourceURL(type, id) +
            '/' +
            this.serializeRelationshipInPath(type, relationship));
    }
    buildFilterParam(filterSpecifiers) {
        const filters = [];
        filterSpecifiers.forEach((filterSpecifier) => {
            var _a;
            if (filterSpecifier.kind === 'attribute' &&
                filterSpecifier.op === 'equal') {
                const attributeFilter = filterSpecifier;
                // Note: We don't know the `type` of the attribute here, so passing `undefined`
                const resourceAttribute = this.serializeAttributeAsParam(undefined, attributeFilter.attribute);
                filters.push({ [resourceAttribute]: attributeFilter.value });
            }
            else if (filterSpecifier.kind === 'relatedRecord') {
                const relatedRecordFilter = filterSpecifier;
                if (Array.isArray(relatedRecordFilter.record)) {
                    filters.push({
                        [relatedRecordFilter.relation]: relatedRecordFilter.record
                            .map((e) => e.id)
                            .join(',')
                    });
                }
                else {
                    filters.push({
                        [relatedRecordFilter.relation]: (_a = relatedRecordFilter === null || relatedRecordFilter === void 0 ? void 0 : relatedRecordFilter.record) === null || _a === void 0 ? void 0 : _a.id
                    });
                }
            }
            else if (filterSpecifier.kind === 'relatedRecords') {
                if (filterSpecifier.op !== 'equal') {
                    throw new Error(`Operation "${filterSpecifier.op}" is not supported in JSONAPI for relatedRecords filtering`);
                }
                const relatedRecordsFilter = filterSpecifier;
                filters.push({
                    [relatedRecordsFilter.relation]: relatedRecordsFilter.records
                        .map((e) => e.id)
                        .join(',')
                });
            }
            else {
                throw new QueryExpressionParseError(`Filter operation ${filterSpecifier.op} not recognized for JSONAPISource.`);
            }
        });
        return filters;
    }
    buildSortParam(sortSpecifiers) {
        return sortSpecifiers
            .map((sortSpecifier) => {
            if (sortSpecifier.kind === 'attribute') {
                const attributeSort = sortSpecifier;
                // Note: We don't know the `type` of the attribute here, so passing `undefined`
                const resourceAttribute = this.serializeAttributeAsParam(undefined, attributeSort.attribute);
                return ((sortSpecifier.order === 'descending' ? '-' : '') +
                    resourceAttribute);
            }
            throw new QueryExpressionParseError(`Sort specifier ${sortSpecifier.kind} not recognized for JSONAPISource.`);
        })
            .join(',');
    }
    buildPageParam(pageSpecifier) {
        let pageParam = clone(pageSpecifier);
        delete pageParam.kind;
        return pageParam;
    }
    appendQueryParams(url, params) {
        let fullUrl = url;
        if (params) {
            fullUrl = appendQueryParams(fullUrl, params);
        }
        return fullUrl;
    }
    serializeAttributeAsParam(type, attribute) {
        if (this.serializer) {
            return this.serializer.resourceAttribute(type, attribute);
        }
        else {
            const serializer = this.serializerFor(JSONAPISerializers.ResourceFieldParam);
            return serializer.serialize(attribute, { type });
        }
    }
    serializeRelationshipAsParam(type, relationship) {
        if (this.serializer) {
            return this.serializer.resourceRelationship(type, relationship);
        }
        else {
            const serializer = this.serializerFor(JSONAPISerializers.ResourceFieldParam);
            return serializer.serialize(relationship, { type });
        }
    }
    serializeRelationshipInPath(type, relationship) {
        if (this.serializer) {
            return this.serializer.resourceRelationship(type, relationship);
        }
        else {
            const serializer = this.serializerFor(JSONAPISerializers.ResourceFieldPath);
            return serializer.serialize(relationship, { type });
        }
    }
}

class JSONAPIBaseSerializer extends BaseSerializer {
    constructor(settings) {
        const { serializerFor, serializationOptions, deserializationOptions, schema, keyMap } = settings;
        super({
            serializerFor,
            serializationOptions,
            deserializationOptions
        });
        this._schema = schema;
        this._keyMap = keyMap;
    }
    get schema() {
        return this._schema;
    }
    get keyMap() {
        return this._keyMap;
    }
    get resourceSerializer() {
        return this.serializerFor(JSONAPISerializers.Resource);
    }
    get documentSerializer() {
        return this.serializerFor(JSONAPISerializers.ResourceDocument);
    }
    get identitySerializer() {
        return this.serializerFor(JSONAPISerializers.ResourceIdentity);
    }
    get typeSerializer() {
        return this.serializerFor(JSONAPISerializers.ResourceType);
    }
    get fieldSerializer() {
        return this.serializerFor(JSONAPISerializers.ResourceField);
    }
    get atomicOperationSerializer() {
        return this.serializerFor(JSONAPISerializers.ResourceAtomicOperation);
    }
    get atomicOperationsDocumentSerializer() {
        return this.serializerFor(JSONAPISerializers.ResourceAtomicOperationsDocument);
    }
    get atomicResultsDocumentSerializer() {
        return this.serializerFor(JSONAPISerializers.ResourceAtomicResultsDocument);
    }
}

class JSONAPIAtomicOperationSerializer extends JSONAPIBaseSerializer {
    serialize(operation) {
        switch (operation.op) {
            case 'addRecord':
                return this.serializeAddRecordOperation(operation);
            case 'updateRecord':
                return this.serializeUpdateRecordOperation(operation);
            case 'removeRecord':
                return this.serializeRemoveRecordOperation(operation);
            case 'addToRelatedRecords':
                return this.serializeAddToRelatedRecordsOperation(operation);
            case 'removeFromRelatedRecords':
                return this.serializeRemoveFromRelatedRecordsOperation(operation);
            case 'replaceRelatedRecord':
                return this.serializeReplaceRelatedRecordOperation(operation);
            case 'replaceRelatedRecords':
                return this.serializeReplaceRelatedRecordsOperation(operation);
            case 'replaceAttribute':
                return this.serializeReplaceAttributeOperation(operation);
            default:
                throw new Error(`JSONAPIOperationSerializer: Unrecognized operation ${operation.op}.`);
        }
    }
    deserialize(operation) {
        if (isAddOperation(operation)) {
            return this.deserializeAddOperation(operation);
        }
        else if (isUpdateOperation(operation)) {
            return this.deserializeUpdateOperation(operation);
        }
        else if (isRemoveOperation(operation)) {
            return this.deserializeRemoveOperation(operation);
        }
        else {
            throw new Error(`JSONAPIOperationSerializer: Only "add", "update" and "remove" operations are supported at this time.`);
        }
    }
    serializeAddRecordOperation(operation) {
        const ref = this.identitySerializer.serialize(operation.record);
        return {
            op: 'add',
            ref,
            data: this.resourceSerializer.serialize(operation.record)
        };
    }
    serializeUpdateRecordOperation(operation) {
        return {
            op: 'update',
            ref: this.identitySerializer.serialize(operation.record),
            data: this.resourceSerializer.serialize(operation.record)
        };
    }
    serializeRemoveRecordOperation(operation) {
        return {
            op: 'remove',
            ref: this.identitySerializer.serialize(operation.record)
        };
    }
    serializeAddToRelatedRecordsOperation(operation) {
        const ref = this.identitySerializer.serialize(operation.record);
        return {
            op: 'add',
            ref: { relationship: operation.relationship, ...ref },
            data: this.identitySerializer.serialize(operation.relatedRecord)
        };
    }
    serializeRemoveFromRelatedRecordsOperation(operation) {
        const ref = this.identitySerializer.serialize(operation.record);
        return {
            op: 'remove',
            ref: { relationship: operation.relationship, ...ref },
            data: this.identitySerializer.serialize(operation.relatedRecord)
        };
    }
    serializeReplaceRelatedRecordsOperation(operation) {
        const ref = this.identitySerializer.serialize(operation.record);
        return {
            op: 'update',
            ref: { relationship: operation.relationship, ...ref },
            data: operation.relatedRecords.map((record) => this.identitySerializer.serialize(record))
        };
    }
    serializeReplaceRelatedRecordOperation(operation) {
        const ref = this.identitySerializer.serialize(operation.record);
        return {
            op: 'update',
            ref: { relationship: operation.relationship, ...ref },
            data: operation.relatedRecord
                ? this.identitySerializer.serialize(operation.relatedRecord)
                : null
        };
    }
    serializeReplaceAttributeOperation(operation) {
        const record = {
            id: operation.record.id,
            type: operation.record.type,
            attributes: {
                [operation.attribute]: operation.value
            }
        };
        const resource = this.resourceSerializer.deserialize(record);
        const ref = {
            id: resource.id,
            type: resource.type
        };
        return {
            op: 'update',
            ref,
            data: resource
        };
    }
    deserializeAddOperation(operation) {
        if (isRelatedResourceOperation(operation)) {
            return {
                op: 'addToRelatedRecords',
                relationship: operation.ref.relationship,
                record: this.identitySerializer.deserialize(operation.ref),
                relatedRecord: this.identitySerializer.deserialize(operation.data)
            };
        }
        else {
            return {
                op: 'addRecord',
                record: this.resourceSerializer.deserialize(operation.data)
            };
        }
    }
    deserializeUpdateOperation(operation) {
        if (isRelatedResourceOperation(operation)) {
            if (Array.isArray(operation.data)) {
                return {
                    op: 'replaceRelatedRecords',
                    relationship: operation.ref.relationship,
                    record: this.identitySerializer.deserialize(operation.ref),
                    relatedRecords: operation.data.map((record) => this.identitySerializer.deserialize(record))
                };
            }
            else {
                return {
                    op: 'replaceRelatedRecord',
                    relationship: operation.ref.relationship,
                    record: this.identitySerializer.deserialize(operation.ref),
                    relatedRecord: operation.data
                        ? this.identitySerializer.deserialize(operation.data)
                        : null
                };
            }
        }
        else {
            return {
                op: 'updateRecord',
                record: this.resourceSerializer.deserialize(operation.data)
            };
        }
    }
    deserializeRemoveOperation(operation) {
        if (isRelatedResourceOperation(operation)) {
            return {
                op: 'removeFromRelatedRecords',
                relationship: operation.ref.relationship,
                record: this.identitySerializer.deserialize(operation.ref),
                relatedRecord: this.identitySerializer.deserialize(operation.data)
            };
        }
        else {
            return {
                op: 'removeRecord',
                record: this.identitySerializer.deserialize(operation.ref)
            };
        }
    }
}
function isRelatedResourceOperation(operation) {
    return !!operation.ref.relationship;
}
function isAddOperation(operation) {
    return operation.op === 'add';
}
function isUpdateOperation(operation) {
    return operation.op === 'update';
}
function isRemoveOperation(operation) {
    return operation.op === 'remove';
}

const { deprecate: deprecate$1 } = Orbit;
class JSONAPIResourceSerializer extends JSONAPIBaseSerializer {
    serialize(record) {
        const resource = this.identitySerializer.serialize(record);
        const model = this.schema.getModel(record.type);
        this.serializeAttributes(resource, record, model);
        this.serializeRelationships(resource, record, model);
        this.serializeLinks(resource, record, model);
        this.serializeMeta(resource, record, model);
        return resource;
    }
    deserialize(resource, customOptions) {
        const options = this.buildDeserializationOptions(customOptions);
        options.includeKeys = true;
        const record = this.identitySerializer.deserialize(resource, options);
        const model = this.schema.getModel(record.type);
        this.deserializeAttributes(record, resource, model);
        this.deserializeRelationships(record, resource, model);
        this.deserializeLinks(record, resource, model);
        this.deserializeMeta(record, resource, model);
        return record;
    }
    serializeAttributes(resource, record, model) {
        if (record.attributes) {
            for (let field of Object.keys(record.attributes)) {
                this.serializeAttribute(resource, record, field, model);
            }
        }
    }
    serializeAttribute(resource, record, field, model) {
        var _a, _b, _c, _d;
        const value = (_a = record.attributes) === null || _a === void 0 ? void 0 : _a[field];
        if (value === undefined) {
            return;
        }
        const fieldOptions = (_b = model.attributes) === null || _b === void 0 ? void 0 : _b[field];
        if (fieldOptions === undefined) {
            return;
        }
        let resValue;
        if (value === null) {
            resValue = null;
        }
        else {
            const type = (_c = fieldOptions.type) !== null && _c !== void 0 ? _c : 'unknown';
            const serializer = this.serializerFor(type);
            if (serializer) {
                const serializationOptions = (_d = fieldOptions.serialization) !== null && _d !== void 0 ? _d : fieldOptions.serializationOptions;
                if (fieldOptions.serializationOptions !== undefined) {
                    // TODO: Remove in v0.18
                    deprecate$1(`The attribute '${field}' for '${record.type}' has been assigned \`serializationOptions\` in the schema. Use \`serialization\` instead.`);
                }
                resValue = serializer.serialize(value, serializationOptions);
            }
            else {
                throw new Assertion(`Serializer could not be found for attribute type '${type}'`);
            }
        }
        const resField = this.fieldSerializer.serialize(field, {
            type: record.type
        });
        deepSet(resource, ['attributes', resField], resValue);
    }
    serializeRelationships(resource, record, model) {
        if (record.relationships) {
            for (let field of Object.keys(record.relationships)) {
                this.serializeRelationship(resource, record, field, model);
            }
        }
    }
    serializeRelationship(resource, record, field, model) {
        var _a, _b;
        const value = (_a = record.relationships) === null || _a === void 0 ? void 0 : _a[field].data;
        if (value === undefined) {
            return;
        }
        if (((_b = model.relationships) === null || _b === void 0 ? void 0 : _b[field]) === undefined) {
            return;
        }
        let resValue;
        if (value === null) {
            resValue = null;
        }
        else {
            const identitySerializer = this.identitySerializer;
            if (Array.isArray(value)) {
                resValue = value.map((identity) => identitySerializer.serialize(identity));
            }
            else {
                resValue = identitySerializer.serialize(value);
            }
        }
        const resField = this.fieldSerializer.serialize(field, {
            type: record.type
        });
        deepSet(resource, ['relationships', resField, 'data'], resValue);
    }
    /* eslint-disable @typescript-eslint/no-unused-vars */
    serializeLinks(resource, record, model) { }
    serializeMeta(resource, record, model) { }
    /* eslint-enable @typescript-eslint/no-unused-vars */
    deserializeAttributes(record, resource, model) {
        if (resource.attributes) {
            for (let resField of Object.keys(resource.attributes)) {
                this.deserializeAttribute(record, resource, resField, model);
            }
        }
    }
    deserializeAttribute(record, resource, resField, model) {
        var _a, _b, _c;
        const resValue = (_a = resource.attributes) === null || _a === void 0 ? void 0 : _a[resField];
        if (resValue === undefined) {
            return;
        }
        const field = this.fieldSerializer.deserialize(resField, {
            type: record.type
        });
        const fieldOptions = (_b = model.attributes) === null || _b === void 0 ? void 0 : _b[field];
        if (fieldOptions === undefined) {
            return;
        }
        let value;
        if (resValue === null) {
            value = null;
        }
        else {
            const type = fieldOptions.type || 'unknown';
            const serializer = this.serializerFor(type);
            if (serializer) {
                const deserializationOptions = (_c = fieldOptions.deserialization) !== null && _c !== void 0 ? _c : fieldOptions.deserializationOptions;
                if (fieldOptions.deserializationOptions !== undefined) {
                    // TODO: Remove in v0.18
                    deprecate$1(`The attribute '${field}' for '${record.type}' has been assigned \`deserializationOptions\` in the schema. Use \`deserialization\` instead.`);
                }
                value = serializer.deserialize(resValue, deserializationOptions);
            }
            else {
                throw new Assertion(`Serializer could not be found for attribute type '${type}'`);
            }
        }
        deepSet(record, ['attributes', field], value);
    }
    deserializeRelationships(record, resource, model) {
        if (resource.relationships) {
            for (let resField of Object.keys(resource.relationships)) {
                this.deserializeRelationship(record, resource, resField, model);
            }
        }
    }
    deserializeRelationship(record, resource, resField, model) {
        var _a, _b;
        const resValue = (_a = resource.relationships) === null || _a === void 0 ? void 0 : _a[resField];
        if (!resValue) {
            return;
        }
        const field = this.fieldSerializer.deserialize(resField, {
            type: record.type
        });
        const fieldOptions = (_b = model.relationships) === null || _b === void 0 ? void 0 : _b[field];
        if (fieldOptions === undefined) {
            return;
        }
        let resData = resValue.data;
        if (resData !== undefined) {
            let data;
            if (resData === null) {
                data = null;
            }
            else {
                const identitySerializer = this.identitySerializer;
                if (Array.isArray(resData)) {
                    data = resData.map((resourceIdentity) => identitySerializer.deserialize(resourceIdentity));
                }
                else {
                    data = identitySerializer.deserialize(resData);
                }
            }
            deepSet(record, ['relationships', field, 'data'], data);
        }
        let { links, meta } = resValue;
        if (links !== undefined) {
            deepSet(record, ['relationships', field, 'links'], links);
        }
        if (meta !== undefined) {
            deepSet(record, ['relationships', field, 'meta'], meta);
        }
    }
    deserializeLinks(record, resource, 
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    model) {
        if (resource.links) {
            record.links = resource.links;
        }
    }
    deserializeMeta(record, resource, 
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    model) {
        if (resource.meta) {
            record.meta = resource.meta;
        }
    }
}

const { assert } = Orbit;
class JSONAPIResourceIdentitySerializer extends JSONAPIBaseSerializer {
    constructor(settings) {
        const { serializerFor, deserializationOptions, schema, keyMap, getResourceKey } = settings;
        super({
            serializerFor,
            deserializationOptions,
            schema,
            keyMap
        });
        this._resourceKeys = {};
        this._getCustomResourceKey = getResourceKey;
    }
    getResourceKey(type) {
        let key = this._resourceKeys[type];
        if (key === undefined) {
            if (this._getCustomResourceKey) {
                key = this._getCustomResourceKey(type);
            }
            if (key === undefined) {
                const model = this.schema.getModel(type);
                if (model === null || model === void 0 ? void 0 : model.keys) {
                    let availableKeys = Object.keys(model.keys);
                    if (availableKeys.length === 1) {
                        key = availableKeys[0];
                    }
                }
                if (key === undefined) {
                    key = 'id';
                }
            }
            assert("JSONAPIResourceIdentitySerializer requires a keyMap to support resource keys other than 'id'", key === 'id' || this.keyMap !== undefined);
            this._resourceKeys[type] = key;
        }
        return key;
    }
    serialize(recordIdentity) {
        const { type, id } = recordIdentity;
        const resourceKey = this.getResourceKey(type);
        const resourceType = this.typeSerializer.serialize(type);
        const keyMap = this.keyMap;
        const resourceId = resourceKey === 'id' ? id : keyMap.idToKey(type, resourceKey, id);
        const resource = {
            type: resourceType
        };
        if (resourceId !== undefined) {
            resource.id = resourceId;
        }
        return resource;
    }
    deserialize(resource, customOptions) {
        var _a;
        const options = this.buildDeserializationOptions(customOptions);
        const type = this.typeSerializer.deserialize(resource.type);
        const resourceKey = this.getResourceKey(type);
        if (resourceKey === 'id') {
            const { id } = resource;
            if (id) {
                return { type, id };
            }
            else {
                throw new Assertion(`Resource of type '${type}' is missing 'id'`);
            }
        }
        else {
            const keyMap = this.keyMap;
            const primaryRecord = options === null || options === void 0 ? void 0 : options.primaryRecord;
            let id;
            let keys;
            if (resource.id) {
                keys = {
                    [resourceKey]: resource.id
                };
                id =
                    ((_a = options.primaryRecord) === null || _a === void 0 ? void 0 : _a.id) ||
                        keyMap.idFromKeys(type, keys) ||
                        this.schema.generateId(type);
            }
            else {
                keys = null;
                id =
                    (primaryRecord && primaryRecord.id) || this.schema.generateId(type);
            }
            const record = { type, id };
            if (keys) {
                if (options.includeKeys) {
                    record.keys = keys;
                    keyMap.pushRecord(record);
                }
                else {
                    keyMap.pushRecord({
                        type,
                        id,
                        keys
                    });
                }
            }
            return record;
        }
    }
}

class JSONAPIDocumentSerializer extends JSONAPIBaseSerializer {
    serialize(document) {
        let resDocument = {
            data: Array.isArray(document.data)
                ? this.serializeRecords(document.data)
                : this.serializeRecord(document.data)
        };
        this.serializeLinks(document, resDocument);
        this.serializeMeta(document, resDocument);
        return resDocument;
    }
    deserialize(resDocument, customOptions) {
        const options = this.buildSerializationOptions(customOptions);
        let resData = resDocument.data;
        let data;
        if (Array.isArray(resData)) {
            data = this.deserializeResources(resData, options === null || options === void 0 ? void 0 : options.primaryRecords);
        }
        else if (resData !== null) {
            data = this.deserializeResource(resData, options === null || options === void 0 ? void 0 : options.primaryRecord);
        }
        else {
            data = null;
        }
        let result = { data };
        if (resDocument.included) {
            result.included = resDocument.included.map((e) => this.deserializeResource(e));
        }
        this.deserializeLinks(resDocument, result);
        this.deserializeMeta(resDocument, result);
        return result;
    }
    serializeRecords(records) {
        return records.map((record) => this.serializeRecord(record));
    }
    serializeRecord(record) {
        return this.resourceSerializer.serialize(record);
    }
    /* eslint-disable @typescript-eslint/no-unused-vars */
    serializeLinks(document, resDocument) { }
    serializeMeta(document, resDocument) { }
    /* eslint-enable @typescript-eslint/no-unused-vars */
    deserializeResources(resources, primaryRecords) {
        if (primaryRecords) {
            return resources.map((entry, i) => {
                return this.deserializeResource(entry, primaryRecords[i]);
            });
        }
        else {
            return resources.map((entry) => this.deserializeResource(entry));
        }
    }
    deserializeResource(resource, primaryRecord) {
        if (primaryRecord) {
            return this.resourceSerializer.deserialize(resource, { primaryRecord });
        }
        else {
            return this.resourceSerializer.deserialize(resource);
        }
    }
    deserializeLinks(resDocument, document) {
        if (resDocument.links) {
            document.links = resDocument.links;
        }
    }
    deserializeMeta(resDocument, document) {
        if (resDocument.meta) {
            document.meta = resDocument.meta;
        }
    }
}

class JSONAPIResourceFieldSerializer extends StringSerializer {
    serialize(arg, customOptions) {
        return super.serialize(arg, customOptions);
    }
    deserialize(arg, customOptions) {
        return super.deserialize(arg, customOptions);
    }
}

function buildJSONAPISerializerFor(settings) {
    const { schema, keyMap } = settings;
    const defaultSerializerClassFor = buildSerializerClassFor({
        unknown: NoopSerializer,
        object: NoopSerializer,
        array: NoopSerializer,
        boolean: BooleanSerializer,
        string: StringSerializer,
        date: DateSerializer,
        datetime: DateTimeSerializer,
        number: NumberSerializer,
        [JSONAPISerializers.Resource]: JSONAPIResourceSerializer,
        [JSONAPISerializers.ResourceDocument]: JSONAPIDocumentSerializer,
        [JSONAPISerializers.ResourceIdentity]: JSONAPIResourceIdentitySerializer,
        [JSONAPISerializers.ResourceAtomicOperation]: JSONAPIAtomicOperationSerializer,
        [JSONAPISerializers.ResourceType]: StringSerializer,
        [JSONAPISerializers.ResourceTypePath]: StringSerializer,
        [JSONAPISerializers.ResourceField]: JSONAPIResourceFieldSerializer,
        [JSONAPISerializers.ResourceFieldParam]: JSONAPIResourceFieldSerializer,
        [JSONAPISerializers.ResourceFieldPath]: JSONAPIResourceFieldSerializer
    });
    let serializerClassFor;
    if (settings.serializerClassFor) {
        serializerClassFor = (type = 'unknown') => {
            return (settings.serializerClassFor(type) ||
                defaultSerializerClassFor(type));
        };
    }
    else {
        serializerClassFor = defaultSerializerClassFor;
    }
    let serializerSettingsFor;
    let defaultSerializerSettingsFor = buildSerializerSettingsFor({
        sharedSettings: {
            keyMap,
            schema
        },
        settingsByType: {
            [JSONAPISerializers.ResourceTypePath]: {
                serializationOptions: { inflectors: ['pluralize', 'dasherize'] }
            },
            [JSONAPISerializers.ResourceFieldPath]: {
                serializationOptions: { inflectors: ['dasherize'] }
            }
        }
    });
    let customSerializerSettingsFor = settings.serializerSettingsFor;
    if (customSerializerSettingsFor) {
        serializerSettingsFor = (type = 'unknown') => {
            let defaultSerializerSettings = defaultSerializerSettingsFor(type) || {};
            let customSerializerSettings = customSerializerSettingsFor(type) || {};
            return deepMerge(defaultSerializerSettings, customSerializerSettings);
        };
    }
    else {
        serializerSettingsFor = defaultSerializerSettingsFor;
    }
    let customSerializerFor = settings.serializerFor;
    let backupSerializerFor = buildSerializerFor({
        serializerClassFor,
        serializerSettingsFor
    });
    if (customSerializerFor) {
        return (type = 'unknown') => customSerializerFor(type) ||
            backupSerializerFor(type);
    }
    else {
        return (type = 'unknown') => backupSerializerFor(type);
    }
}

const { assert: assert$1, deprecate: deprecate$2 } = Orbit;
class JSONAPIRequestProcessor {
    constructor(settings) {
        let { sourceName, allowedContentTypes, schema, keyMap, SerializerClass, serializerFor, serializerClassFor, serializerSettingsFor } = settings;
        this.sourceName = sourceName;
        this.allowedContentTypes = allowedContentTypes || [
            'application/vnd.api+json',
            'application/json'
        ];
        this.schema = schema;
        this.keyMap = keyMap;
        if (SerializerClass) {
            deprecate$2("The 'SerializerClass' setting for 'JSONAPIRequestProcessor' has been deprecated. Pass 'serializerFor', 'serializerClassFor', and/or 'serializerSettingsFor' instead.");
            this._serializer = new SerializerClass({
                schema,
                keyMap
            });
        }
        this._serializerFor = buildJSONAPISerializerFor({
            schema,
            keyMap,
            serializerFor,
            serializerClassFor,
            serializerSettingsFor
        });
        const URLBuilderClass = settings.URLBuilderClass || JSONAPIURLBuilder;
        const urlBuilderOptions = {
            host: settings.host,
            namespace: settings.namespace,
            keyMap: settings.keyMap,
            serializer: this._serializer,
            serializerFor: this._serializerFor
        };
        this.urlBuilder = new URLBuilderClass(urlBuilderOptions);
        this.initDefaultFetchSettings(settings);
    }
    get serializer() {
        deprecate$2("'JSONAPIRequestProcessor#serializer' has been deprecated. Use 'serializerFor' instead.");
        if (this._serializer) {
            return this._serializer;
        }
        else {
            return this._serializerFor(JSONAPISerializers.ResourceDocument);
        }
    }
    get serializerFor() {
        return this._serializerFor;
    }
    fetch(url, customSettings) {
        let settings = this.initFetchSettings(customSettings);
        let fullUrl = url;
        if (settings.params) {
            fullUrl = this.urlBuilder.appendQueryParams(fullUrl, settings.params);
            delete settings.params;
        }
        let fetchFn = Orbit.fetch || Orbit.globals.fetch;
        // console.log('fetch', fullUrl, settings, 'polyfill', fetchFn.polyfill);
        if (settings.timeout !== undefined && settings.timeout > 0) {
            let timeout = settings.timeout;
            delete settings.timeout;
            return new Promise((resolve, reject) => {
                let timedOut;
                let timer = Orbit.globals.setTimeout(() => {
                    timedOut = true;
                    reject(new NetworkError(`No fetch response within ${timeout}ms.`));
                }, timeout);
                fetchFn(fullUrl, settings)
                    .catch((e) => {
                    Orbit.globals.clearTimeout(timer);
                    if (!timedOut) {
                        return this.handleFetchError(e);
                    }
                })
                    .then((response) => {
                    Orbit.globals.clearTimeout(timer);
                    if (!timedOut) {
                        return this.handleFetchResponse(response);
                    }
                })
                    .then(resolve, reject);
            });
        }
        else {
            return fetchFn(fullUrl, settings)
                .catch((e) => this.handleFetchError(e))
                .then((response) => this.handleFetchResponse(response));
        }
    }
    initFetchSettings(customSettings = {}) {
        let settings = deepMerge({}, this.defaultFetchSettings, customSettings);
        if (settings.json) {
            assert$1("`json` and `body` can't both be set for fetch requests.", !settings.body);
            settings.body = JSON.stringify(settings.json);
            delete settings.json;
        }
        if (settings.headers && !settings.body) {
            delete settings.headers['Content-Type'];
        }
        return settings;
    }
    operationsFromDeserializedDocument(deserialized) {
        const records = [];
        Array.prototype.push.apply(records, toArray(deserialized.data));
        if (deserialized.included) {
            Array.prototype.push.apply(records, deserialized.included);
        }
        return records.map((record) => {
            return {
                op: 'updateRecord',
                record
            };
        });
    }
    buildFetchSettings(options = {}, customSettings) {
        return buildFetchSettings(options, customSettings);
    }
    customRequestOptions(queryOrTransform, queryExpressionOrOperation) {
        return requestOptionsForSource([queryOrTransform.options, queryExpressionOrOperation.options], this.sourceName);
    }
    /* eslint-disable @typescript-eslint/no-unused-vars */
    preprocessResponseDocument(document, request) { }
    /* eslint-enable @typescript-eslint/no-unused-vars */
    responseHasContent(response) {
        if (response.status === 204) {
            return false;
        }
        let contentType = response.headers.get('Content-Type');
        if (contentType) {
            for (let allowedContentType of this.allowedContentTypes) {
                if (contentType.indexOf(allowedContentType) > -1) {
                    return true;
                }
            }
        }
        return false;
    }
    initDefaultFetchSettings(settings) {
        this.defaultFetchSettings = {
            headers: {
                Accept: 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json'
            },
            timeout: 5000
        };
        if (settings.defaultFetchSettings) {
            deepMerge(this.defaultFetchSettings, settings.defaultFetchSettings);
        }
    }
    async handleFetchResponse(response) {
        const responseDetail = {
            response
        };
        if (response.status === 201) {
            if (this.responseHasContent(response)) {
                responseDetail.document = await response.json();
            }
            else {
                throw new InvalidServerResponse(`Server responses with a ${response.status} status should return content with one of the following content types: ${this.allowedContentTypes.join(', ')}.`);
            }
        }
        else if (response.status >= 200 && response.status < 300) {
            if (this.responseHasContent(response)) {
                responseDetail.document = await response.json();
            }
        }
        else if (response.status !== 304 && response.status !== 404) {
            if (this.responseHasContent(response)) {
                const document = await response.json();
                await this.handleFetchResponseError(response, document);
            }
            else {
                await this.handleFetchResponseError(response);
            }
        }
        return responseDetail;
    }
    async handleFetchResponseError(response, data) {
        let error;
        if (response.status >= 400 && response.status < 500) {
            error = new ClientError(response.statusText);
        }
        else {
            error = new ServerError(response.statusText);
        }
        error.response = response;
        error.data = data;
        throw error;
    }
    async handleFetchError(e) {
        if (typeof e === 'string') {
            throw new NetworkError(e);
        }
        else {
            throw e;
        }
    }
}

function getQueryRequests(requestProcessor, query) {
    const requests = [];
    for (let expression of toArray(query.expressions)) {
        let request = ExpressionToRequestMap[expression.op](expression, requestProcessor);
        let options = requestProcessor.customRequestOptions(query, expression);
        if (options) {
            if (request.options) {
                request.options = mergeJSONAPIRequestOptions(request.options, options);
            }
            else {
                request.options = options;
            }
        }
        requests.push(request);
    }
    return requests;
}
const ExpressionToRequestMap = {
    findRecord(expression) {
        const exp = expression;
        return {
            op: 'findRecord',
            record: cloneRecordIdentity(exp.record)
        };
    },
    findRecords(expression, requestProcessor) {
        const exp = expression;
        let request = {
            op: 'findRecords',
            type: exp.type
        };
        let options = {};
        if (exp.filter) {
            options.filter = requestProcessor.urlBuilder.buildFilterParam(exp.filter);
        }
        if (exp.sort) {
            options.sort = requestProcessor.urlBuilder.buildSortParam(exp.sort);
        }
        if (exp.page) {
            options.page = requestProcessor.urlBuilder.buildPageParam(exp.page);
        }
        request.options = options;
        return request;
    },
    findRelatedRecord(expression) {
        const exp = expression;
        return {
            op: 'findRelatedRecord',
            record: cloneRecordIdentity(exp.record),
            relationship: exp.relationship
        };
    },
    findRelatedRecords(expression, requestProcessor) {
        const exp = expression;
        const request = {
            op: 'findRelatedRecords',
            record: cloneRecordIdentity(exp.record),
            relationship: exp.relationship
        };
        const options = {};
        if (exp.filter) {
            options.filter = requestProcessor.urlBuilder.buildFilterParam(exp.filter);
        }
        if (exp.sort) {
            options.sort = requestProcessor.urlBuilder.buildSortParam(exp.sort);
        }
        if (exp.page) {
            options.page = requestProcessor.urlBuilder.buildPageParam(exp.page);
        }
        request.options = options;
        return request;
    }
};
const QueryRequestProcessors = {
    async findRecord(requestProcessor, request) {
        const { record } = request;
        const options = request.options || {};
        const settings = requestProcessor.buildFetchSettings(options);
        const url = options.url ||
            requestProcessor.urlBuilder.resourceURL(record.type, record.id);
        const details = await requestProcessor.fetch(url, settings);
        const { document } = details;
        requestProcessor.preprocessResponseDocument(document, request);
        if (document) {
            const serializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceDocument);
            const recordDoc = serializer.deserialize(document);
            const operations = requestProcessor.operationsFromDeserializedDocument(recordDoc);
            const transforms = [buildTransform(operations)];
            return { transforms, data: recordDoc.data, details };
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
            return { transforms: [] };
        }
    },
    async findRecords(requestProcessor, request) {
        const { type } = request;
        const options = request.options || {};
        const settings = requestProcessor.buildFetchSettings(options);
        const url = options.url || requestProcessor.urlBuilder.resourceURL(type);
        const details = await requestProcessor.fetch(url, settings);
        const { document } = details;
        requestProcessor.preprocessResponseDocument(document, request);
        if (document) {
            const serializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceDocument);
            const recordDoc = serializer.deserialize(document);
            const operations = requestProcessor.operationsFromDeserializedDocument(recordDoc);
            const transforms = [buildTransform(operations)];
            return { transforms, data: recordDoc.data, details };
        }
        else {
            return { transforms: [] };
        }
    },
    async findRelatedRecord(requestProcessor, request) {
        const { record, relationship } = request;
        const options = request.options || {};
        const settings = requestProcessor.buildFetchSettings(options);
        const url = options.url ||
            requestProcessor.urlBuilder.relatedResourceURL(record.type, record.id, relationship);
        const details = await requestProcessor.fetch(url, settings);
        const { document } = details;
        requestProcessor.preprocessResponseDocument(document, request);
        if (document) {
            const serializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceDocument);
            const recordDoc = serializer.deserialize(document);
            const relatedRecord = recordDoc.data;
            const operations = requestProcessor.operationsFromDeserializedDocument(recordDoc);
            operations.push({
                op: 'replaceRelatedRecord',
                record,
                relationship,
                relatedRecord
            });
            const transforms = [buildTransform(operations)];
            return { transforms, data: relatedRecord, details };
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
            return { transforms: [] };
        }
    },
    async findRelatedRecords(requestProcessor, request) {
        var _a, _b, _c;
        const { record, relationship } = request;
        const options = request.options || {};
        const settings = requestProcessor.buildFetchSettings(options);
        const url = options.url ||
            requestProcessor.urlBuilder.relatedResourceURL(record.type, record.id, relationship);
        const details = await requestProcessor.fetch(url, settings);
        const { document } = details;
        requestProcessor.preprocessResponseDocument(document, request);
        if (document) {
            const serializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceDocument);
            const recordDoc = serializer.deserialize(document);
            const relatedRecords = recordDoc.data;
            const operations = requestProcessor.operationsFromDeserializedDocument(recordDoc);
            const partialSet = (_a = options.partialSet) !== null && _a !== void 0 ? _a : !!(options.filter ||
                options.page || ((_b = recordDoc.links) === null || _b === void 0 ? void 0 : _b.next) || ((_c = recordDoc.links) === null || _c === void 0 ? void 0 : _c.prev));
            if (partialSet) {
                for (let relatedRecord of relatedRecords) {
                    operations.push({
                        op: 'addToRelatedRecords',
                        record,
                        relationship,
                        relatedRecord
                    });
                }
            }
            else {
                operations.push({
                    op: 'replaceRelatedRecords',
                    record,
                    relationship,
                    relatedRecords
                });
            }
            const transforms = [buildTransform(operations)];
            return { transforms, data: relatedRecords, details };
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
            return { transforms: [] };
        }
    }
};

const TransformRequestProcessors = {
    async addRecord(requestProcessor, request) {
        const { record } = request;
        const options = request.options || {};
        const serializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceDocument);
        const requestDoc = serializer.serialize({
            data: record
        });
        const settings = requestProcessor.buildFetchSettings(options, {
            method: 'POST',
            json: requestDoc
        });
        const url = options.url || requestProcessor.urlBuilder.resourceURL(record.type);
        const details = await requestProcessor.fetch(url, settings);
        const document = details.document;
        requestProcessor.preprocessResponseDocument(document, request);
        const recordDoc = serializer.deserialize(document, {
            primaryRecord: record
        });
        return handleChanges(record, recordDoc, details);
    },
    async removeRecord(requestProcessor, request) {
        const { record } = request;
        const options = request.options || {};
        const { type, id } = record;
        const settings = requestProcessor.buildFetchSettings(options, {
            method: 'DELETE'
        });
        const url = options.url || requestProcessor.urlBuilder.resourceURL(type, id);
        const details = await requestProcessor.fetch(url, settings);
        return { transforms: [], data: record, details };
    },
    async updateRecord(requestProcessor, request) {
        const { record } = request;
        const options = request.options || {};
        const { type, id } = record;
        const serializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceDocument);
        const requestDoc = serializer.serialize({
            data: record
        });
        const settings = requestProcessor.buildFetchSettings(options, {
            method: 'PATCH',
            json: requestDoc
        });
        const url = options.url || requestProcessor.urlBuilder.resourceURL(type, id);
        const details = await requestProcessor.fetch(url, settings);
        const { document } = details;
        if (document) {
            requestProcessor.preprocessResponseDocument(document, request);
            const recordDoc = serializer.deserialize(document, {
                primaryRecord: record
            });
            return handleChanges(record, recordDoc, details);
        }
        else {
            return { transforms: [], data: record, details };
        }
    },
    async addToRelatedRecords(requestProcessor, request) {
        const { relationship, record, relatedRecords } = request;
        const options = request.options || {};
        const { type, id } = record;
        const resourceIdentitySerializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceIdentity);
        const json = {
            data: relatedRecords.map((r) => resourceIdentitySerializer.serialize(r))
        };
        const settings = requestProcessor.buildFetchSettings(options, {
            method: 'POST',
            json
        });
        const url = options.url ||
            requestProcessor.urlBuilder.resourceRelationshipURL(type, id, relationship);
        const details = await requestProcessor.fetch(url, settings);
        return { transforms: [], data: record, details };
    },
    async removeFromRelatedRecords(requestProcessor, request) {
        const { relationship, record, relatedRecords } = request;
        const options = request.options || {};
        const { type, id } = record;
        const resourceIdentitySerializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceIdentity);
        const json = {
            data: relatedRecords.map((r) => resourceIdentitySerializer.serialize(r))
        };
        const settings = requestProcessor.buildFetchSettings(options, {
            method: 'DELETE',
            json
        });
        const url = options.url ||
            requestProcessor.urlBuilder.resourceRelationshipURL(type, id, relationship);
        const details = await requestProcessor.fetch(url, settings);
        return { transforms: [], data: record, details };
    },
    async replaceRelatedRecord(requestProcessor, request) {
        const { relationship, relatedRecord, record } = request;
        const options = request.options || {};
        const { type, id } = record;
        const resourceIdentitySerializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceIdentity);
        const json = {
            data: relatedRecord
                ? resourceIdentitySerializer.serialize(relatedRecord)
                : null
        };
        const settings = requestProcessor.buildFetchSettings(options, {
            method: 'PATCH',
            json
        });
        const url = options.url ||
            requestProcessor.urlBuilder.resourceRelationshipURL(type, id, relationship);
        const details = await requestProcessor.fetch(url, settings);
        return { transforms: [], data: record, details };
    },
    async replaceRelatedRecords(requestProcessor, request) {
        const { relationship, relatedRecords, record } = request;
        const options = request.options || {};
        const { type, id } = record;
        const resourceIdentitySerializer = requestProcessor.serializerFor(JSONAPISerializers.ResourceIdentity);
        const json = {
            data: relatedRecords.map((r) => resourceIdentitySerializer.serialize(r))
        };
        const settings = requestProcessor.buildFetchSettings(options, {
            method: 'PATCH',
            json
        });
        const url = options.url ||
            requestProcessor.urlBuilder.resourceRelationshipURL(type, id, relationship);
        const details = await requestProcessor.fetch(url, settings);
        return { transforms: [], data: record, details };
    }
};
function getTransformRequests(requestProcessor, transform) {
    const requests = [];
    let prevRequest = null;
    for (let operation of toArray(transform.operations)) {
        let request;
        let newRequestNeeded = true;
        if (prevRequest &&
            equalRecordIdentities(prevRequest.record, operation.record)) {
            if (operation.op === 'removeRecord') {
                newRequestNeeded = false;
                if (prevRequest.op !== 'removeRecord') {
                    prevRequest = null;
                    requests.pop();
                }
            }
            else if (prevRequest.op === 'addRecord' ||
                prevRequest.op === 'updateRecord') {
                if (operation.op === 'replaceAttribute') {
                    newRequestNeeded = false;
                    replaceRecordAttribute(prevRequest.record, operation.attribute, operation.value);
                }
                else if (operation.op === 'replaceRelatedRecord') {
                    newRequestNeeded = false;
                    replaceRecordHasOne(prevRequest.record, operation.relationship, operation.relatedRecord);
                }
                else if (operation.op === 'replaceRelatedRecords') {
                    newRequestNeeded = false;
                    replaceRecordHasMany(prevRequest.record, operation.relationship, operation.relatedRecords);
                }
            }
            else if (prevRequest.op === 'addToRelatedRecords' &&
                operation.op === 'addToRelatedRecords' &&
                prevRequest.relationship ===
                    operation.relationship) {
                newRequestNeeded = false;
                prevRequest.relatedRecords.push(cloneRecordIdentity(operation.relatedRecord));
            }
            else if (prevRequest.op === 'removeFromRelatedRecords' &&
                operation.op === 'removeFromRelatedRecords' &&
                prevRequest.relationship ===
                    operation.relationship) {
                newRequestNeeded = false;
                prevRequest.relatedRecords.push(cloneRecordIdentity(operation.relatedRecord));
            }
        }
        if (newRequestNeeded) {
            request = OperationToRequestMap[operation.op](operation);
        }
        if (request) {
            let options = requestProcessor.customRequestOptions(transform, operation);
            if (options) {
                request.options = options;
            }
            requests.push(request);
            prevRequest = request;
        }
    }
    return requests;
}
const OperationToRequestMap = {
    addRecord(operation) {
        const op = operation;
        return {
            op: 'addRecord',
            record: clone(op.record)
        };
    },
    removeRecord(operation) {
        const op = operation;
        return {
            op: 'removeRecord',
            record: cloneRecordIdentity(op.record)
        };
    },
    replaceAttribute(operation) {
        const op = operation;
        const record = cloneRecordIdentity(op.record);
        replaceRecordAttribute(record, op.attribute, op.value);
        return {
            op: 'updateRecord',
            record
        };
    },
    updateRecord(operation) {
        return {
            op: 'updateRecord',
            record: clone(operation.record)
        };
    },
    addToRelatedRecords(operation) {
        const { record, relationship, relatedRecord } = operation;
        return {
            op: 'addToRelatedRecords',
            record: cloneRecordIdentity(record),
            relationship,
            relatedRecords: [cloneRecordIdentity(relatedRecord)]
        };
    },
    removeFromRelatedRecords(operation) {
        const { record, relationship, relatedRecord } = operation;
        return {
            op: 'removeFromRelatedRecords',
            record: cloneRecordIdentity(record),
            relationship,
            relatedRecords: [cloneRecordIdentity(relatedRecord)]
        };
    },
    replaceRelatedRecord(operation) {
        const record = cloneRecordIdentity(operation.record);
        const { relationship, relatedRecord } = operation;
        deepSet(record, ['relationships', relationship, 'data'], relatedRecord);
        return {
            op: 'updateRecord',
            record
        };
    },
    replaceRelatedRecords(operation) {
        const record = cloneRecordIdentity(operation.record);
        const { relationship, relatedRecords } = operation;
        deepSet(record, ['relationships', relationship, 'data'], relatedRecords);
        return {
            op: 'updateRecord',
            record
        };
    }
};
function replaceRecordAttribute(record, attribute, value) {
    deepSet(record, ['attributes', attribute], value);
}
function replaceRecordHasOne(record, relationship, relatedRecord) {
    deepSet(record, ['relationships', relationship, 'data'], relatedRecord ? cloneRecordIdentity(relatedRecord) : null);
}
function replaceRecordHasMany(record, relationship, relatedRecords) {
    deepSet(record, ['relationships', relationship, 'data'], relatedRecords.map((r) => cloneRecordIdentity(r)));
}
function handleChanges(record, recordDoc, details) {
    let updatedRecord = recordDoc.data;
    let transforms = [];
    let updateOps = recordDiffs(record, updatedRecord);
    if (updateOps.length > 0) {
        transforms.push(buildTransform(updateOps));
    }
    if (recordDoc.included && recordDoc.included.length > 0) {
        let includedOps = recordDoc.included.map((record) => {
            return { op: 'updateRecord', record };
        });
        transforms.push(buildTransform(includedOps));
    }
    return { transforms, data: updatedRecord, details };
}

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const { deprecate: deprecate$3 } = Orbit;
/**
 Source for accessing a JSON API compliant RESTful API with a network fetch
 request.

 If a single transform or query requires more than one fetch request,
 requests will be performed sequentially and resolved together. From the
 perspective of Orbit, these operations will all succeed or fail together. The
 `maxRequestsPerTransform` and `maxRequestsPerQuery` settings allow limits to be
 set on this behavior. These settings should be set to `1` if your client/server
 configuration is unable to resolve partially successful transforms / queries.

 @class JSONAPISource
 @extends Source
 */
let JSONAPISource = class JSONAPISource extends RecordSource {
    constructor(settings) {
        settings.name = settings.name || 'jsonapi';
        super(settings);
        let { name, maxRequestsPerTransform, maxRequestsPerQuery, namespace, host, defaultFetchSettings, allowedContentTypes, serializerFor, serializerClassFor, serializerSettingsFor, SerializerClass, RequestProcessorClass, URLBuilderClass, keyMap } = settings;
        if (this.schema === undefined) {
            throw new Assertion("JSONAPISource's `schema` must be specified in the  `settings` passed to its constructor");
        }
        if (this._defaultQueryOptions === undefined) {
            this._defaultQueryOptions = {};
        }
        if (this._defaultTransformOptions === undefined) {
            this._defaultTransformOptions = {};
        }
        // Parallelize query requests by default (but not transform requests)
        if (this._defaultQueryOptions.parallelRequests === undefined) {
            this._defaultQueryOptions.parallelRequests = true;
        }
        if (maxRequestsPerTransform !== undefined) {
            deprecate$3("The 'maxRequestsPerTransform' setting for 'JSONAPSource' has been deprecated in favor of 'defaultTransformOptions.maxRequests'.");
            this._defaultTransformOptions.maxRequests = maxRequestsPerTransform;
        }
        if (maxRequestsPerQuery !== undefined) {
            deprecate$3("The 'maxRequestsPerQuery' setting for 'JSONAPSource' has been deprecated in favor of 'defaultQueryOptions.maxRequests'.");
            this._defaultQueryOptions.maxRequests = maxRequestsPerQuery;
        }
        RequestProcessorClass = RequestProcessorClass || JSONAPIRequestProcessor;
        this.requestProcessor = new RequestProcessorClass({
            sourceName: name,
            serializerFor,
            serializerClassFor,
            serializerSettingsFor,
            SerializerClass,
            URLBuilderClass: URLBuilderClass || JSONAPIURLBuilder,
            allowedContentTypes,
            defaultFetchSettings,
            namespace,
            host,
            schema: this.schema,
            keyMap
        });
    }
    /**
     * Deprecated in favor of `defaultTransformOptions.maxRequests`
     *
     * @deprecated since v0.17, remove in v0.18
     */
    get maxRequestsPerTransform() {
        var _a;
        deprecate$3("The 'maxRequestsPerTransform' property for 'JSONAPSource' has been deprecated in favor of 'defaultTransformOptions.maxRequests'.");
        return (_a = this._defaultTransformOptions) === null || _a === void 0 ? void 0 : _a.maxRequests;
    }
    /**
     * Deprecated in favor of `defaultTransformOptions.maxRequests`
     *
     * @deprecated since v0.17, remove in v0.18
     */
    set maxRequestsPerTransform(val) {
        deprecate$3("The 'maxRequestsPerTransform' property for 'JSONAPSource' has been deprecated in favor of 'defaultTransformOptions.maxRequests'.");
        if (this._defaultTransformOptions === undefined) {
            this._defaultTransformOptions = {};
        }
        this._defaultTransformOptions.maxRequests = val;
    }
    /**
     * Deprecated in favor of `defaultQueryOptions.maxRequests`
     *
     * @deprecated since v0.17, remove in v0.18
     */
    get maxRequestsPerQuery() {
        var _a;
        deprecate$3("The 'maxRequestsPerQuery' property for 'JSONAPSource' has been deprecated in favor of 'defaultQueryOptions.maxRequests'.");
        return (_a = this._defaultQueryOptions) === null || _a === void 0 ? void 0 : _a.maxRequests;
    }
    /**
     * Deprecated in favor of `defaultQueryOptions.maxRequests`
     *
     * @deprecated since v0.17, remove in v0.18
     */
    set maxRequestsPerQuery(val) {
        deprecate$3("The 'maxRequestsPerQuery' property for 'JSONAPSource' has been deprecated in favor of 'defaultQueryOptions.maxRequests'.");
        if (this._defaultQueryOptions === undefined) {
            this._defaultQueryOptions = {};
        }
        this._defaultQueryOptions.maxRequests = val;
    }
    /////////////////////////////////////////////////////////////////////////////
    // Pushable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async _push(transform) {
        if (this.transformLog.contains(transform.id)) {
            return {};
        }
        const responses = await this.processTransformRequests(transform);
        const details = [];
        const transforms = [];
        for (let response of responses) {
            if (response.transforms) {
                Array.prototype.push.apply(transforms, response.transforms);
            }
            if (response.details) {
                details.push(response.details);
            }
        }
        return {
            transforms: [transform, ...transforms],
            details
        };
    }
    /////////////////////////////////////////////////////////////////////////////
    // Pullable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async _pull(query) {
        const responses = await this.processQueryRequests(query);
        const details = [];
        const transforms = [];
        for (let response of responses) {
            if (response.transforms) {
                Array.prototype.push.apply(transforms, response.transforms);
            }
            if (response.details) {
                details.push(response.details);
            }
        }
        return {
            transforms,
            details
        };
    }
    /////////////////////////////////////////////////////////////////////////////
    // Queryable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async _query(query) {
        const responses = await this.processQueryRequests(query);
        const details = [];
        const transforms = [];
        const data = [];
        for (let response of responses) {
            if (response.transforms) {
                Array.prototype.push.apply(transforms, response.transforms);
            }
            if (response.details) {
                details.push(response.details);
            }
            data.push(response.data);
        }
        return {
            data: Array.isArray(query.expressions) ? data : data[0],
            details,
            transforms
        };
    }
    /////////////////////////////////////////////////////////////////////////////
    // Updatable interface implementation
    /////////////////////////////////////////////////////////////////////////////
    async _update(transform) {
        if (this.transformLog.contains(transform.id)) {
            return {};
        }
        const responses = await this.processTransformRequests(transform);
        const details = [];
        const transforms = [];
        const data = [];
        for (let response of responses) {
            if (response.transforms) {
                Array.prototype.push.apply(transforms, response.transforms);
            }
            if (response.details) {
                details.push(response.details);
            }
            data.push(response.data);
        }
        return {
            data: Array.isArray(transform.operations) ? data : data[0],
            details,
            transforms: [transform, ...transforms]
        };
    }
    getQueryRequestProcessor(request) {
        return QueryRequestProcessors[request.op];
    }
    getTransformRequestProcessor(request) {
        return TransformRequestProcessors[request.op];
    }
    async processQueryRequests(query) {
        const options = this.getQueryOptions(query);
        const requests = getQueryRequests(this.requestProcessor, query);
        if ((options === null || options === void 0 ? void 0 : options.maxRequests) !== undefined &&
            requests.length > options.maxRequests) {
            throw new QueryNotAllowed(`This query requires ${requests.length} requests, which exceeds the specified limit of ${options.maxRequests} requests per query.`, query);
        }
        if (options === null || options === void 0 ? void 0 : options.parallelRequests) {
            return Promise.all(requests.map((request) => {
                const processor = this.getQueryRequestProcessor(request);
                return processor(this.requestProcessor, request);
            }));
        }
        else {
            const responses = [];
            for (let request of requests) {
                const processor = this.getQueryRequestProcessor(request);
                responses.push(await processor(this.requestProcessor, request));
            }
            return responses;
        }
    }
    async processTransformRequests(transform) {
        const options = this.getTransformOptions(transform);
        const requests = getTransformRequests(this.requestProcessor, transform);
        if ((options === null || options === void 0 ? void 0 : options.maxRequests) !== undefined &&
            requests.length > options.maxRequests) {
            throw new TransformNotAllowed(`This transform requires ${requests.length} requests, which exceeds the specified limit of ${options.maxRequests} requests per transform.`, transform);
        }
        if (options === null || options === void 0 ? void 0 : options.parallelRequests) {
            return Promise.all(requests.map((request) => {
                const processor = this.getTransformRequestProcessor(request);
                return processor(this.requestProcessor, request);
            }));
        }
        else {
            const responses = [];
            for (let request of requests) {
                const processor = this.getTransformRequestProcessor(request);
                responses.push(await processor(this.requestProcessor, request));
            }
            return responses;
        }
    }
};
JSONAPISource = __decorate([
    pullable,
    pushable,
    queryable,
    updatable
], JSONAPISource);

var __pika_web_default_export_for_treeshaking__ = JSONAPISource;

export default __pika_web_default_export_for_treeshaking__;
export { JSONAPIRequestProcessor, JSONAPIURLBuilder };
