import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

export const Config = {
  API_URL: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL,
  STRIPE_PRODUCT: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_STRIPE_PRODUCT,
  USER_POOL_ID: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_USER_POOL_ID,
  USER_POOL_CLIENT_ID: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_USER_POOL_CLIENT_ID,
  AUTH_URL: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_AUTH_URL,
  SHIP_API_URL: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_SHIP_API_URL,
  VERSION: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_VERSION
};
console.log("Config", Config);
