import React, {useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {
  PageTitle,
  FormSectionHeader,
  FormGrid,
  FormTextArea,
  FormOutline,
  FormInput,
  FormLabel,
  FormStatic,
  PageTitleStretch,
  Button,
  FormCheck,
  FormSelect,
  FormToggle,
  SimpleLink,
  TagGrid,
  Tag,
  TagIcon,
  Theme,
  Toolbar,
  FormRow,
  FormSimpleInput3
} from "../../../components/index.js";
import styled from "../../../../_snowpack/pkg/styled-components.js";
import {
  useForm,
  useFormBoolean,
  useFormNumber,
  useFormSet,
  useFormString
} from "../../../components/util/index.js";
import {FiX} from "../../../../_snowpack/pkg/react-icons/fi.js";
import {CalendarRow} from "../../../structures/CalendaroRow.js";
import {memory} from "../../../data/orbit.js";
import {useAuth} from "../../../../_snowpack/pkg/@ironbay/ship-react.js";
const Split = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Theme.spacing.size1600};
`;
const LinkPrefix = styled(FormStatic)`
  color: ${Theme.colors.primary};
  font-weight: 600;
`;
const DURATIONS = [15, 30, 45, 60];
const BUFFERS = [0, 5, 10, 15];
const DurationGrid = styled.div`
  display: grid;
  grid-gap: ${Theme.spacing.size400};
  grid-template-columns: repeat(
    ${(props) => [].concat(props.children).length},
    1fr
  );
`;
const DurationGridItem = styled.div`
  cursor: pointer;
  font-size: ${Theme.font.size375};
  font-weight: 500;
  color: ${(props) => props.active && Theme.colors.primary};
  user-select: none;
`;
export function Edit(props) {
  const auth = useAuth();
  const [original, calendars, self] = memory.cache.query((q) => [
    q.findRecord({type: "template", id: props.template}),
    q.findRelatedRecords({type: "user", id: auth.user}, "calendars"),
    q.findRecord({type: "user", id: auth.user})
  ]);
  const [ready, ready_set] = useState(false);
  const form = useForm({
    name: useFormString(),
    description: useFormString(),
    slug: useFormString(),
    alias: useFormString(),
    guests: useFormSet(),
    duration: useFormNumber(),
    buffer: useFormNumber(),
    unnamed: useFormBoolean(),
    private: useFormBoolean(),
    optimize: useFormBoolean(),
    location_type: useFormString(),
    location_data: useFormString(),
    availability: useFormSet({
      is_equal: (a, b) => a.dow === b.dow
    })
  });
  const target = useFormString();
  const constraints = useFormSet({
    is_equal: (a, b) => a.id === b.id
  });
  function reset(template) {
    form.reset({
      ...template.attributes
    });
    target.reset(template.relationships?.target?.data?.id || null);
    constraints.reset(template.relationships?.constraints?.data || []);
    ready_set(true);
  }
  const [guest_input, guest_input_set] = useState("");
  useEffect(() => {
    memory.query((q) => [
      q.findRelatedRecords({type: "user", id: auth.user}, "calendars"),
      q.findRecord({type: "template", id: props.template})
    ]);
  }, []);
  useEffect(() => {
    if (!original)
      return;
    reset(original);
  }, [original]);
  useEffect(() => {
    if (form.hooks.slug.original)
      return;
    if (!form.hooks.name.value)
      return;
    form.hooks.slug.set(clean_slug(form.hooks.name.value));
  }, [form.hooks.name.value]);
  function guest_add() {
    if (!guest_input)
      return;
    form.hooks.guests.add(guest_input);
    guest_input_set("");
  }
  async function update_template() {
    const patch = form.patch();
    memory.update((t) => t.updateRecord({
      type: "template",
      id: props.template,
      attributes: patch,
      relationships: {
        target: {
          data: {
            id: target.value,
            type: "calendar"
          }
        },
        constraints: {
          data: constraints.value.map((item) => ({
            id: item.id,
            type: "calendar"
          }))
        }
      }
    }));
    props.navigate?.("/dashboard/templates");
  }
  async function delete_template() {
    memory.update((t) => t.removeRecord({type: "template", id: props.template}));
    props.navigate?.("/dashboard/templates");
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(PageTitle, null, /* @__PURE__ */ React.createElement(PageTitleStretch, null, "Create Template"), /* @__PURE__ */ React.createElement(Toolbar, null, /* @__PURE__ */ React.createElement(Button, {
    "data-test": "button-save",
    onClick: () => delete_template(),
    variant: "secondary",
    size: "sm"
  }, "Delete Template"), /* @__PURE__ */ React.createElement(Button, {
    "data-test": "button-save",
    onClick: update_template,
    size: "sm"
  }, "Save Changes"))), ready && /* @__PURE__ */ React.createElement(Split, null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(FormGrid, null, /* @__PURE__ */ React.createElement(FormSectionHeader, null, "General"), /* @__PURE__ */ React.createElement(FormSimpleInput3, {
    ...form.hooks.name.bind,
    label: "Event Name"
  }), /* @__PURE__ */ React.createElement(FormSimpleInput3, {
    ...form.hooks.alias.bind,
    label: "Alias (only visible to you)"
  }), /* @__PURE__ */ React.createElement(FormOutline, {
    grow: true
  }, /* @__PURE__ */ React.createElement(FormTextArea, {
    ...form.hooks.description.bind,
    rows: 5,
    placeholder: "Description"
  })), /* @__PURE__ */ React.createElement(FormOutline, null, /* @__PURE__ */ React.createElement(FormLabel, {
    active: true
  }, "Link to template"), /* @__PURE__ */ React.createElement(LinkPrefix, null, "https://withnori.com/", self.attributes.slug, "/"), /* @__PURE__ */ React.createElement(FormInput, {
    ...form.hooks.slug.bind,
    placeholder: "Enter name"
  })), /* @__PURE__ */ React.createElement(FormOutline, null, /* @__PURE__ */ React.createElement(FormLabel, {
    active: true
  }, "Duration (min)"), /* @__PURE__ */ React.createElement(FormInput, {
    ...form.hooks.duration.bind
  }), /* @__PURE__ */ React.createElement(DurationGrid, null, DURATIONS.map((item) => {
    return /* @__PURE__ */ React.createElement(DurationGridItem, {
      key: item,
      onClick: () => form.hooks.duration.set(item),
      active: form.hooks.duration.value === item
    }, item, "min");
  }))), /* @__PURE__ */ React.createElement(FormOutline, null, /* @__PURE__ */ React.createElement(FormLabel, {
    active: true
  }, "Buffer between events (min)"), /* @__PURE__ */ React.createElement(FormInput, {
    ...form.hooks.buffer.bind
  }), /* @__PURE__ */ React.createElement(DurationGrid, null, BUFFERS.map((item) => {
    return /* @__PURE__ */ React.createElement(DurationGridItem, {
      key: item,
      onClick: () => form.hooks.buffer.set(item),
      active: form.hooks.buffer.value === item
    }, item === 0 ? "None" : `${item}min`);
  }))), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(FormCheck, {
    onClick: () => form.hooks.unnamed.toggle(),
    value: form.hooks.unnamed.value
  }, "Allow guests to modify the event's name"), /* @__PURE__ */ React.createElement(FormCheck, {
    onClick: () => form.hooks.private.toggle(),
    value: form.hooks.private.value
  }, "Hide this event from your main Nori page"), /* @__PURE__ */ React.createElement(FormCheck, {
    onClick: () => form.hooks.optimize.toggle(),
    value: form.hooks.optimize.value
  }, "Highlight preferred times that optimize your schedule"), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Availability"), [
    [1, "Monday"],
    [2, "Tuesday"],
    [3, "Wednesday"],
    [4, "Thursday"],
    [5, "Friday"],
    [6, "Saturday"],
    [7, "Sunday"]
  ].map(([dow, label]) => {
    const value = form.hooks.availability.value.find((item) => item.dow === dow);
    return /* @__PURE__ */ React.createElement(FormRow, {
      key: dow
    }, /* @__PURE__ */ React.createElement(FormToggle, {
      onClick: () => {
        if (!value) {
          form.hooks.availability.add({
            dow,
            start: 9,
            end: 17
          });
          return;
        }
        form.hooks.availability.remove(value);
      },
      value: value != null
    }, label), value && /* @__PURE__ */ React.createElement(FormOutline, null, /* @__PURE__ */ React.createElement(FormLabel, {
      active: true
    }, "From"), /* @__PURE__ */ React.createElement(FormSelect, {
      onChange: (e) => {
        form.hooks.availability.add({
          ...value,
          start: parseInt(e.target.value)
        });
      },
      value: value.start
    }, /* @__PURE__ */ React.createElement("option", {
      value: "",
      disabled: true
    }, "Select..."), Hours)), value && /* @__PURE__ */ React.createElement(FormOutline, null, /* @__PURE__ */ React.createElement(FormLabel, {
      active: true
    }, "To"), /* @__PURE__ */ React.createElement(FormSelect, {
      onChange: (e) => {
        form.hooks.availability.add({
          ...value,
          end: parseInt(e.target.value)
        });
      },
      value: value.end
    }, /* @__PURE__ */ React.createElement("option", {
      value: "",
      disabled: true
    }, "Select..."), Hours)));
  }))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(FormGrid, null, /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Location"), /* @__PURE__ */ React.createElement(FormOutline, null, /* @__PURE__ */ React.createElement(FormLabel, {
    active: true
  }, "Event Location"), /* @__PURE__ */ React.createElement(FormSelect, {
    ...form.hooks.location_type.bind
  }, /* @__PURE__ */ React.createElement("option", {
    value: ""
  }, "Select..."), /* @__PURE__ */ React.createElement("option", {
    value: "GOOGLE"
  }, "Google Meet"), /* @__PURE__ */ React.createElement("option", {
    value: "CUSTOM"
  }, "Custom"))), form.hooks.location_type.value === "CUSTOM" && /* @__PURE__ */ React.createElement(FormSimpleInput3, {
    ...form.hooks.location_data.bind,
    label: "Location description"
  }), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Add to calendar"), calendars.filter((cal) => cal.attributes).map((cal) => {
    const is_selected = cal.id === target.value;
    return /* @__PURE__ */ React.createElement(CalendarRow, {
      calendar: cal,
      onClick: () => target.set(cal.id),
      key: cal.id
    }, is_selected && /* @__PURE__ */ React.createElement(FormCheck, {
      value: true
    }));
  }), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Check for conflicts"), calendars.map((cal) => {
    const is_selected = constraints.includes(cal);
    return /* @__PURE__ */ React.createElement(CalendarRow, {
      calendar: cal,
      onClick: () => {
        if (!is_selected) {
          constraints.add({
            id: cal.id,
            type: cal.type
          });
          return;
        }
        if (is_selected) {
          constraints.remove({
            id: cal.id,
            type: cal.type
          });
          return;
        }
      },
      key: cal.id
    }, /* @__PURE__ */ React.createElement(FormToggle, {
      value: is_selected
    }));
  }), /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement(FormSectionHeader, null, "Additional Guests"), /* @__PURE__ */ React.createElement(FormOutline, null, /* @__PURE__ */ React.createElement(FormLabel, {
    active: guest_input
  }, "Guest's email"), /* @__PURE__ */ React.createElement(FormInput, {
    onKeyPress: (e) => {
      if (e.key !== "Enter")
        return;
      if (!guest_input)
        return;
      form.hooks.guests.add(guest_input);
      guest_input_set("");
    },
    onChange: (e) => guest_input_set(e.target.value),
    value: guest_input,
    placeholder: "Enter email address"
  }), /* @__PURE__ */ React.createElement(SimpleLink, {
    onClick: guest_add,
    as: "div"
  }, "Add")), /* @__PURE__ */ React.createElement(TagGrid, null, form.hooks.guests.value.map((item) => /* @__PURE__ */ React.createElement(Tag, {
    onClick: () => form.hooks.guests.remove(item)
  }, item, /* @__PURE__ */ React.createElement(TagIcon, {
    as: FiX
  }))))))));
}
function clean_slug(input) {
  return (input || "").toLowerCase().replace(/[\W_]+/g, "-");
}
const Hours = [
  [0, "12:00am"],
  [1, "1:00am"],
  [2, "2:00am"],
  [3, "3:00am"],
  [4, "4:00am"],
  [5, "5:00am"],
  [6, "6:00am"],
  [7, "7:00am"],
  [8, "8:00am"],
  [9, "9:00am"],
  [10, "10:00am"],
  [11, "11:00am"],
  [12, "12:00pm"],
  [13, "1:00pm"],
  [14, "2:00pm"],
  [15, "3:00pm"],
  [16, "4:00pm"],
  [17, "5:00pm"],
  [18, "6:00pm"],
  [19, "7:00pm"],
  [20, "8:00pm"],
  [21, "9:00pm"],
  [22, "10:00pm"],
  [23, "11:00pm"],
  [24, "Midnight"]
].map(([val, label]) => /* @__PURE__ */ React.createElement("option", {
  key: val,
  value: val
}, label));
