import { E as Exception, f as fulfillAll, e as evented, O as Orbit } from './log-e4bf9a42.js';

/**
 * An error occured related to the schema.
 */
class SchemaError extends Exception {
    constructor(description) {
        super(`Schema: ${description}`);
        this.description = description;
    }
}
/**
 * A validation failed.
 */
class ValidationError extends Exception {
    constructor(description, issues) {
        super(description);
        this.description = description;
        this.issues = issues;
    }
}
/**
 * A model is not defined in the schema.
 */
class ModelNotDefined extends SchemaError {
    constructor(type) {
        super(`Model '${type}' not defined.`);
    }
}
/**
 * An attribute definition could not be found in the model definition.
 */
class AttributeNotDefined extends SchemaError {
    constructor(type, attribute) {
        super(`Attribute '${attribute}' not defined for model '${type}'.`);
    }
}
/**
 * A key definition could not be found in the model definition.
 */
class KeyNotDefined extends SchemaError {
    constructor(type, key) {
        super(`Key '${key}' not defined for model '${type}'.`);
    }
}
/**
 * A relationship definition could not be found in the model definition.
 */
class RelationshipNotDefined extends SchemaError {
    constructor(type, relationship) {
        super(`Relationship '${relationship}' not defined for model '${type}'.`);
    }
}
/**
 * An error occurred related to a particular record.
 */
class RecordException extends Exception {
    constructor(description, type, id, field) {
        let specifier = `${type}:${id}`;
        if (field) {
            specifier = `${specifier}/${field}`;
        }
        let message = `${description}: ${specifier}`;
        super(message);
        this.description = description;
        this.type = type;
        this.id = id;
        this.field = field;
    }
}
/**
 * A record could not be found.
 */
class RecordNotFoundException extends RecordException {
    constructor(type, id) {
        super('Record not found', type, id);
    }
}

var StandardRecordValidators;
(function (StandardRecordValidators) {
    StandardRecordValidators["Record"] = "record";
    StandardRecordValidators["RecordAttribute"] = "recordAttribute";
    StandardRecordValidators["RecordIdentity"] = "recordIdentity";
    StandardRecordValidators["RecordKey"] = "recordKey";
    StandardRecordValidators["RecordOperation"] = "recordOperation";
    StandardRecordValidators["RecordQueryExpression"] = "recordQueryExpression";
    StandardRecordValidators["RecordRelationship"] = "recordRelationship";
    StandardRecordValidators["RecordType"] = "recordType";
    StandardRecordValidators["RelatedRecord"] = "relatedRecord";
    StandardRecordValidators["RecordFieldDefinition"] = "recordFieldDefinition";
})(StandardRecordValidators || (StandardRecordValidators = {}));

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const { uuid, deprecate } = Orbit;
/**
 * A `Schema` defines the models allowed in a source, including their keys,
 * attributes, and relationships. A single schema may be shared across multiple
 * sources.
 */
let RecordSchema = class RecordSchema {
    constructor(settings = {}) {
        if (settings.version === undefined) {
            settings.version = 1;
        }
        if (settings.models === undefined) {
            settings.models = {};
        }
        this._applySettings(settings);
    }
    /**
     * Version
     */
    get version() {
        return this._version;
    }
    /**
     * Upgrades Schema to a new version with new settings.
     *
     * Emits the `upgrade` event to cue sources to upgrade their data.
     */
    async upgrade(settings = {}) {
        if (settings.version === undefined) {
            settings.version = this._version + 1;
        }
        this._applySettings(settings);
        await fulfillAll(this, 'upgrade', this._version);
    }
    /**
     * Registers a complete set of settings
     */
    _applySettings(settings) {
        // Version
        if (settings.version !== undefined) {
            this._version = settings.version;
        }
        // Allow overrides
        if (settings.generateId) {
            this.generateId = settings.generateId;
        }
        if (settings.pluralize) {
            deprecate('Schema#pluralize has been deprecated. Use inflectors from in @orbit/serializers instead.');
            this.pluralize = settings.pluralize;
        }
        if (settings.singularize) {
            deprecate('Schema#singularize has been deprecated. Use inflectors from @orbit/serializers instead.');
            this.singularize = settings.singularize;
        }
        // Register model schemas
        if (settings.models) {
            this._deprecateRelationshipModel(settings.models);
            this._models = settings.models;
        }
    }
    /**
     * Generate an id for a given model type.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    generateId(type) {
        return uuid();
    }
    /**
     * A naive pluralization method.
     *
     * Deprecated in favor of inflectors now in @orbit/serializers
     *
     * @deprecated since v0.17, remove in v0.18
     */
    pluralize(word) {
        deprecate('Schema#pluralize has been deprecated. Use inflectors from @orbit/serializers instead.');
        return word + 's';
    }
    /**
     * A naive singularization method.
     *
     * Deprecated in favor of inflectors now in @orbit/serializers
     *
     * @deprecated since v0.17, remove in v0.18
     */
    singularize(word) {
        deprecate('Schema#singularize has been deprecated. Use inflectors from @orbit/serializers instead.');
        if (word.lastIndexOf('s') === word.length - 1) {
            return word.substr(0, word.length - 1);
        }
        else {
            console.warn(`Orbit's built-in naive singularization rules cannot singularize ${word}. Pass singularize & pluralize functions to Schema to customize.`);
            return word;
        }
    }
    get models() {
        return this._models;
    }
    getModel(type) {
        const model = this.models[type];
        if (model) {
            return model;
        }
        else {
            throw new ModelNotDefined(type);
        }
    }
    getAttribute(type, attribute) {
        var _a;
        const model = this.getModel(type);
        const attributeDef = (_a = model.attributes) === null || _a === void 0 ? void 0 : _a[attribute];
        if (attributeDef) {
            return attributeDef;
        }
        else {
            throw new AttributeNotDefined(type, attribute);
        }
    }
    getKey(type, key) {
        var _a;
        const model = this.getModel(type);
        const keyDef = (_a = model.keys) === null || _a === void 0 ? void 0 : _a[key];
        if (keyDef) {
            return keyDef;
        }
        else {
            throw new KeyNotDefined(type, key);
        }
    }
    getRelationship(type, relationship) {
        var _a;
        const model = this.getModel(type);
        const relationshipDef = (_a = model.relationships) === null || _a === void 0 ? void 0 : _a[relationship];
        if (relationshipDef) {
            return relationshipDef;
        }
        else {
            throw new RelationshipNotDefined(type, relationship);
        }
    }
    hasModel(type) {
        return this.models[type] !== undefined;
    }
    hasAttribute(type, attribute) {
        var _a, _b;
        return ((_b = (_a = this.models[type]) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b[attribute]) !== undefined;
    }
    hasKey(type, key) {
        var _a, _b;
        return ((_b = (_a = this.models[type]) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b[key]) !== undefined;
    }
    hasRelationship(type, relationship) {
        var _a, _b;
        return ((_b = (_a = this.models[type]) === null || _a === void 0 ? void 0 : _a.relationships) === null || _b === void 0 ? void 0 : _b[relationship]) !== undefined;
    }
    eachAttribute(type, callbackFn) {
        const model = this.getModel(type);
        const attributes = model.attributes || {};
        for (let name in attributes) {
            callbackFn(name, attributes[name]);
        }
    }
    eachKey(type, callbackFn) {
        const model = this.getModel(type);
        const keys = model.keys || {};
        for (let name in keys) {
            callbackFn(name, keys[name]);
        }
    }
    eachRelationship(type, callbackFn) {
        const model = this.getModel(type);
        const relationships = model.relationships || {};
        for (let name in relationships) {
            callbackFn(name, relationships[name]);
        }
    }
    _deprecateRelationshipModel(models) {
        for (let type in models) {
            if (models[type].relationships) {
                let relationships = models[type]
                    .relationships;
                for (let name in relationships) {
                    let relationship = relationships[name];
                    if (relationship.model) {
                        deprecate('RelationshipDefinition.model is deprecated, use `type` and `kind` instead.');
                    }
                }
            }
        }
    }
};
RecordSchema = __decorate([
    evented
], RecordSchema);

const validateArray = (input, options) => {
    if (!Array.isArray(input)) {
        return [
            {
                validator: 'array',
                validation: 'type',
                description: 'is not an array',
                ref: input
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.minItems) !== undefined && input.length < options.minItems) {
        return [
            {
                validator: 'array',
                validation: 'minItems',
                description: 'has too few members',
                ref: input,
                details: {
                    minItems: options.minItems
                }
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.maxItems) !== undefined && input.length > options.maxItems) {
        return [
            {
                validator: 'array',
                validation: 'maxItems',
                description: 'has too many members',
                ref: input,
                details: {
                    maxItems: options.maxItems
                }
            }
        ];
    }
};

const validateBoolean = (input) => {
    if (typeof input !== 'boolean') {
        return [
            {
                validator: 'boolean',
                validation: 'type',
                description: 'is not a boolean',
                ref: input
            }
        ];
    }
};

const validateDate = (input, options) => {
    if (!(input instanceof Date)) {
        return [
            {
                validator: 'date',
                validation: 'type',
                description: 'is not a date',
                ref: input
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.minimum) !== undefined && input < options.minimum) {
        return [
            {
                validator: 'date',
                validation: 'minimum',
                description: 'is too early',
                ref: input,
                details: {
                    minimum: options.minimum
                }
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.maximum) !== undefined && input > options.maximum) {
        return [
            {
                validator: 'date',
                validation: 'maximum',
                description: 'is too late',
                ref: input,
                details: {
                    maximum: options.maximum
                }
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.exclusiveMinimum) !== undefined &&
        input <= options.exclusiveMinimum) {
        return [
            {
                validator: 'date',
                validation: 'exclusiveMinimum',
                description: 'is too early',
                ref: input,
                details: {
                    exclusiveMinimum: options.exclusiveMinimum
                }
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.exclusiveMaximum) !== undefined &&
        input >= options.exclusiveMaximum) {
        return [
            {
                validator: 'date',
                validation: 'exclusiveMaximum',
                description: 'is too late',
                ref: input,
                details: {
                    exclusiveMaximum: options.exclusiveMaximum
                }
            }
        ];
    }
};

const validateNumber = (input, options) => {
    if (typeof input !== 'number') {
        return [
            {
                validator: 'number',
                validation: 'type',
                description: 'is not a number',
                ref: input
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.minimum) !== undefined && input < options.minimum) {
        return [
            {
                validator: 'number',
                validation: 'minimum',
                description: 'is too low',
                ref: input,
                details: {
                    minimum: options.minimum
                }
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.maximum) !== undefined && input > options.maximum) {
        return [
            {
                validator: 'number',
                validation: 'maximum',
                description: 'is too high',
                ref: input,
                details: {
                    maximum: options.maximum
                }
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.exclusiveMinimum) !== undefined &&
        input <= options.exclusiveMinimum) {
        return [
            {
                validator: 'number',
                validation: 'exclusiveMinimum',
                description: 'is too low',
                ref: input,
                details: {
                    exclusiveMinimum: options.exclusiveMinimum
                }
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.exclusiveMaximum) !== undefined &&
        input >= options.exclusiveMaximum) {
        return [
            {
                validator: 'number',
                validation: 'exclusiveMaximum',
                description: 'is too high',
                ref: input,
                details: {
                    exclusiveMaximum: options.exclusiveMaximum
                }
            }
        ];
    }
};

const validateObject = (input, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
options) => {
    if (typeof input !== 'object' || input === null) {
        return [
            {
                validator: 'object',
                validation: 'type',
                description: 'is not an object',
                ref: input
            }
        ];
    }
};

const validateString = (input, options) => {
    if (typeof input !== 'string') {
        return [
            {
                validator: 'string',
                validation: 'type',
                description: 'is not a string',
                ref: input
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.minLength) !== undefined && input.length < options.minLength) {
        return [
            {
                validator: 'string',
                validation: 'minLength',
                description: 'is too short',
                ref: input,
                details: {
                    minLength: options.minLength
                }
            }
        ];
    }
    if ((options === null || options === void 0 ? void 0 : options.maxLength) !== undefined && input.length > options.maxLength) {
        return [
            {
                validator: 'string',
                validation: 'maxLength',
                description: 'is too long',
                ref: input,
                details: {
                    maxLength: options.maxLength
                }
            }
        ];
    }
};

var StandardValidators;
(function (StandardValidators) {
    StandardValidators["Array"] = "array";
    StandardValidators["Boolean"] = "boolean";
    StandardValidators["Date"] = "date";
    StandardValidators["DateTime"] = "datetime";
    StandardValidators["Number"] = "number";
    StandardValidators["Object"] = "object";
    StandardValidators["String"] = "string";
})(StandardValidators || (StandardValidators = {}));
const standardValidators = {
    array: validateArray,
    boolean: validateBoolean,
    date: validateDate,
    datetime: validateDate,
    number: validateNumber,
    object: validateObject,
    string: validateString
};

export { KeyNotDefined as K, ModelNotDefined as M, RecordNotFoundException as R, StandardRecordValidators as S, ValidationError as V, StandardValidators as a, RecordSchema as b, standardValidators as s };
