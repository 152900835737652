import React from "../../../../_snowpack/pkg/react.js";
import {Router} from "../../../../_snowpack/pkg/@reach/router.js";
import {Listing} from "./Listing.js";
import {Edit} from "./Edit.js";
export function Templates(_props) {
  return /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Listing, {
    path: "/"
  }), /* @__PURE__ */ React.createElement(Edit, {
    path: "/:template"
  }));
}
