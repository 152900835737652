import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import {Title, Description} from "./common.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
import {Wrap, Theme, Button} from "../../components/index.js";
import {Link} from "../../../_snowpack/pkg/@reach/router.js";
import {DurationIcon} from "../../structures/DurationIcon.js";
import {format} from "../../../_snowpack/pkg/date-fns.js";
import {memory} from "../../data/orbit.js";
const Root = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const EventCard = styled.div`
  margin: ${Theme.spacing.size600} 0;
  background: white;
  border: 1px solid ${Theme.colors.border};
  padding: ${Theme.spacing.size600} 0;
  border-radius: 12px;
`;
const EventTitle = styled(Title)`
  margin-right: ${Theme.spacing.size200};
`;
const EventHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export function Complete(props) {
  const booking = memory.cache.query((q) => q.findRecord({type: "booking", id: props.booking_id}));
  useEffect(() => {
    memory.query((q) => q.findRecord({type: "booking", id: props.booking_id}));
  }, [props.booking_id]);
  if (!booking)
    return null;
  const duration = new Date(booking.attributes.end).getTime() - new Date(booking.attributes.start).getTime();
  return /* @__PURE__ */ React.createElement(Root, null, /* @__PURE__ */ React.createElement(Wrap, {
    width: "xs"
  }, /* @__PURE__ */ React.createElement(Title, null, "Tada! We are done!"), /* @__PURE__ */ React.createElement(Description, null, "A calendar invite has been sent to your email to be sure you don't miss it."), /* @__PURE__ */ React.createElement(EventCard, null, /* @__PURE__ */ React.createElement(EventHeader, null, /* @__PURE__ */ React.createElement(EventTitle, null, booking.attributes.name), /* @__PURE__ */ React.createElement(DurationIcon, {
    duration: duration / 1e3 / 60
  })), /* @__PURE__ */ React.createElement(Description, null, duration / 1e3 / 60, " min  •  ", format(new Date(booking.attributes.start), "EEEE, MMMM 'at' hh:mm a"))), /* @__PURE__ */ React.createElement(Button, {
    as: Link,
    onClick: () => {
    },
    to: "/"
  }, "Use Nori for simpler scheduling")));
}
