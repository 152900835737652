import * as React from "../../_snowpack/pkg/react.js";
export function Logo(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: 79,
    height: 29,
    viewBox: "0 0 79 29",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M35 8.122h3.042l.26 1.794h.182c1.11-1.404 2.53-2.106 4.264-2.106 1.404 0 2.505.381 3.302 1.144.815.763 1.222 1.985 1.222 3.666v9.178H43.71v-8.424c0-.85-.2-1.465-.598-1.846-.399-.381-.988-.572-1.768-.572-.797 0-1.465.286-2.002.858-.52.555-.78 1.248-.78 2.08v7.904H35V8.122zM56.199 22.11c-2.34 0-4.082-.58-5.227-1.742-1.144-1.161-1.715-2.964-1.715-5.408 0-2.444.572-4.247 1.715-5.408C52.117 8.391 53.858 7.81 56.2 7.81c2.34 0 4.082.58 5.226 1.742 1.16 1.161 1.742 2.964 1.742 5.408 0 2.444-.581 4.247-1.742 5.408-1.144 1.161-2.886 1.742-5.226 1.742zm0-2.912c1.16 0 2.002-.33 2.522-.988.52-.676.78-1.69.78-3.042v-.416c0-1.352-.26-2.357-.78-3.016-.52-.676-1.361-1.014-2.522-1.014-1.162 0-2.002.338-2.523 1.014-.502.659-.754 1.664-.754 3.016v.416c0 1.352.252 2.366.755 3.042.52.659 1.36.988 2.522.988zM65.229 8.122h3.042l.286 2.21h.182c.295-.78.676-1.395 1.144-1.846.468-.45 1.083-.676 1.846-.676.503 0 .953.078 1.352.234v3.328h-.988c-1.161 0-2.002.32-2.522.962-.52.624-.78 1.577-.78 2.86v6.604h-3.562V8.122zM74.528 3h3.563v3.38h-3.563V3zm0 5.122h3.563v13.676h-3.563V8.122z",
    fill: "#000"
  }), /* @__PURE__ */ React.createElement("g", {
    clipPath: "url(#prefix__clip0)",
    fill: "#28C166"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M8.226.237h-.163A8.03 8.03 0 000 8.301v3.339c0 .57.733.814 1.14.326L8.796 1.215c.245-.408-.081-.978-.57-.978zM17.756 28.174c1.222-2.525 7.412-15.068 9.61-19.792.245-.489-.325-.896-.732-.651L2.28 24.835c-.408.244-.408.814-.082 1.14A8.12 8.12 0 008.063 28.5h9.041c.245.163.57 0 .652-.326zM0 20.6c0 .651.081 1.221.244 1.791.082.408.57.57.978.326l25.656-18c.244-.163.326-.57.163-.815-1.385-2.199-3.91-3.665-6.679-3.665H14.01c-.977 0-1.955.489-2.525 1.303L.57 16.934c-.407.489-.57 1.14-.57 1.792V20.6zM20.932 27.441c-.244.57.245 1.222.815 1.059 3.747-.652 6.597-3.991 6.597-7.9v-5.295c0-.814-1.059-1.059-1.466-.326l-5.946 12.462z"
  })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
    id: "prefix__clip0"
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "#fff",
    transform: "translate(0 .237)",
    d: "M0 0h28.426v28.426H0z"
  }))));
}
