import { a as fulfillInSeries, s as settleInSeries, O as Orbit, e as evented, A as Assertion } from './log-e4bf9a42.js';
import { S as Source, r as requestOptionsForSource } from './source-bf183794.js';
import { f as buildTransform, i as buildRecordValidatorFor, S as StandardRecordNormalizer, j as RecordQueryBuilder, k as RecordTransformBuilder, c as cloneRecordIdentity, b as equalRecordIdentities, l as uniqueRecordIdentities, v as validateRecordOperation, e as eq, a as equalRecordIdentitySets, r as recordsInclude, Q as QueryExpressionParseError, m as mergeRecords, d as buildQuery, g as recordsReferencedByOperations, O as OperationTerm, h as deserializeRecordIdentity, s as serializeRecordIdentity } from './record-source-87b54fe8.js';
import { d as deepGet, a as deepSet, i as isNone, c as clone, t as toArray, o as objectValues } from './objects-398cfae3.js';
import { V as ValidationError, R as RecordNotFoundException } from './standard-validators-4ed2ae82.js';

const { assert } = Orbit;
const SYNCABLE = '__syncable__';
/**
 * Has a source been decorated as `@syncable`?
 */
function isSyncable(source) {
    return !!source[SYNCABLE];
}
/**
 * Marks a source as "syncable" and adds an implementation of the `Syncable`
 * interface.
 *
 * The `sync` method is part of the "sync flow" in Orbit. This flow is used to
 * synchronize the contents of sources.
 *
 * Other sources can participate in the resolution of a `sync` by observing the
 * `transform` event, which is emitted whenever a new `Transform` is applied to
 * a source.
 */
function syncable(Klass) {
    let proto = Klass.prototype;
    if (isSyncable(proto)) {
        return;
    }
    assert('Syncable interface can only be applied to a Source', proto instanceof Source);
    proto[SYNCABLE] = true;
    proto.sync = async function (transformOrTransforms) {
        await this.activated;
        if (Array.isArray(transformOrTransforms)) {
            const transforms = transformOrTransforms;
            for (let transform of transforms) {
                await this.sync(transform);
            }
        }
        else {
            let transform;
            if (typeof transformOrTransforms === 'function') {
                transform = buildTransform(transformOrTransforms, undefined, undefined, this.transformBuilder);
            }
            else {
                transform = transformOrTransforms;
                if (this.transformLog.contains(transform.id)) {
                    return;
                }
            }
            return this._syncQueue.push({
                type: 'sync',
                data: transform
            });
        }
    };
    proto.__sync__ = async function (transform) {
        if (this.transformLog.contains(transform.id)) {
            return;
        }
        try {
            await fulfillInSeries(this, 'beforeSync', transform);
            await this._sync(transform);
            await this.transformed([transform]);
            await settleInSeries(this, 'sync', transform);
        }
        catch (error) {
            await settleInSeries(this, 'syncFail', transform, error);
            throw error;
        }
    };
}

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const { assert: assert$1 } = Orbit;
let RecordCache = class RecordCache {
    constructor(settings) {
        assert$1('RecordCache requires a `schema` setting to be constructed', !!settings.schema);
        const { name, schema, keyMap } = settings;
        this._name = name;
        this._schema = schema;
        this._keyMap = keyMap;
        let { validatorFor, validators } = settings;
        if (validatorFor) {
            assert$1('RecordCache can be constructed with either a `validatorFor` or `validators`, but not both', validators === undefined);
        }
        else if (Orbit.debug || validators !== undefined) {
            validatorFor = buildRecordValidatorFor({ validators });
        }
        this._validatorFor = validatorFor;
        if (settings.queryBuilder === undefined ||
            settings.transformBuilder === undefined) {
            let normalizer = settings.normalizer;
            if (normalizer === undefined) {
                normalizer = new StandardRecordNormalizer({
                    schema,
                    keyMap
                });
            }
            if (settings.queryBuilder === undefined) {
                settings.queryBuilder = new RecordQueryBuilder({
                    schema,
                    normalizer,
                    validatorFor
                });
            }
            if (settings.transformBuilder === undefined) {
                settings.transformBuilder = new RecordTransformBuilder({
                    schema,
                    normalizer,
                    validatorFor
                });
            }
        }
        this._queryBuilder = settings.queryBuilder;
        this._transformBuilder = settings.transformBuilder;
        this._defaultQueryOptions = settings.defaultQueryOptions;
        this._defaultTransformOptions = settings.defaultTransformOptions;
    }
    get name() {
        return this._name;
    }
    get schema() {
        return this._schema;
    }
    get keyMap() {
        return this._keyMap;
    }
    get validatorFor() {
        return this._validatorFor;
    }
    get queryBuilder() {
        return this._queryBuilder;
    }
    get transformBuilder() {
        return this._transformBuilder;
    }
    get defaultQueryOptions() {
        return this._defaultQueryOptions;
    }
    set defaultQueryOptions(options) {
        this._defaultQueryOptions = options;
    }
    get defaultTransformOptions() {
        return this._defaultTransformOptions;
    }
    set defaultTransformOptions(options) {
        this._defaultTransformOptions = options;
    }
    getQueryOptions(query, expression) {
        return requestOptionsForSource([
            this._defaultQueryOptions,
            query.options,
            expression === null || expression === void 0 ? void 0 : expression.options
        ], this._name);
    }
    getTransformOptions(transform, operation) {
        return requestOptionsForSource([
            this._defaultTransformOptions,
            transform.options,
            operation === null || operation === void 0 ? void 0 : operation.options
        ], this._name);
    }
};
RecordCache = __decorate([
    evented
], RecordCache);

function recordOperationChange(operation) {
    const record = operation.record;
    const change = {
        ...cloneRecordIdentity(record),
        remove: false,
        keys: [],
        attributes: [],
        relationships: [],
        meta: [],
        links: []
    };
    switch (operation.op) {
        case 'addRecord':
        case 'updateRecord':
            if (record.keys) {
                change.keys = Object.keys(record.keys);
            }
            if (record.attributes) {
                change.attributes = Object.keys(record.attributes);
            }
            if (record.relationships) {
                change.relationships = Object.keys(record.relationships);
            }
            if (record.meta) {
                change.meta = Object.keys(record.meta);
            }
            if (record.links) {
                change.links = Object.keys(record.links);
            }
            break;
        case 'replaceAttribute':
            change.attributes = [operation.attribute];
            break;
        case 'replaceKey':
            change.keys = [operation.key];
            break;
        case 'replaceRelatedRecord':
        case 'replaceRelatedRecords':
        case 'addToRelatedRecords':
        case 'removeFromRelatedRecords':
            change.relationships = [operation.relationship];
            break;
        case 'removeRecord':
            change.remove = true;
            break;
    }
    return change;
}

const { assert: assert$2 } = Orbit;
class LiveQuery {
    constructor(settings) {
        assert$2('Only single expression queries are supported on LiveQuery', !Array.isArray(settings.query.expressions));
        this.debounce = settings.debounce;
        this._query = settings.query;
    }
    _subscribe(onNext) {
        const execute = this.debounce ? onceTick(onNext) : onNext;
        const unsubscribePatch = this.cache.on('patch', (operation) => {
            if (this.operationRelevantForQuery(operation)) {
                execute();
            }
        });
        const unsubscribeReset = this.cache.on('reset', () => {
            execute();
        });
        function unsubscribe() {
            cancelTick(execute);
            unsubscribePatch();
            unsubscribeReset();
        }
        return unsubscribe;
    }
    operationRelevantForQuery(operation) {
        const change = recordOperationChange(operation);
        const expression = this._query.expressions;
        return this.queryExpressionRelevantForChange(expression, change);
    }
    queryExpressionRelevantForChange(expression, change) {
        switch (expression.op) {
            case 'findRecord':
                return this.findRecordQueryExpressionRelevantForChange(expression, change);
            case 'findRecords':
                return this.findRecordsQueryExpressionRelevantForChange(expression, change);
            case 'findRelatedRecord':
                return this.findRelatedRecordQueryExpressionRelevantForChange(expression, change);
            case 'findRelatedRecords':
                return this.findRelatedRecordsQueryExpressionRelevantForChange(expression, change);
            default:
                return true;
        }
    }
    findRecordQueryExpressionRelevantForChange(expression, change) {
        return equalRecordIdentities(expression.record, change);
    }
    findRecordsQueryExpressionRelevantForChange(expression, change) {
        if (expression.type) {
            return expression.type === change.type;
        }
        else if (expression.records) {
            for (let record of expression.records) {
                if (record.type === change.type) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }
    findRelatedRecordQueryExpressionRelevantForChange(expression, change) {
        return (equalRecordIdentities(expression.record, change) &&
            (change.relationships.includes(expression.relationship) || change.remove));
    }
    findRelatedRecordsQueryExpressionRelevantForChange(expression, change) {
        const relationshipDef = this.schema.getRelationship(expression.record.type, expression.relationship);
        const type = relationshipDef === null || relationshipDef === void 0 ? void 0 : relationshipDef.type;
        if (Array.isArray(type) && type.find((type) => type === change.type)) {
            return true;
        }
        else if (type === change.type) {
            return true;
        }
        return (equalRecordIdentities(expression.record, change) &&
            (change.relationships.includes(expression.relationship) || change.remove));
    }
}
const isNode = typeof Orbit.globals.process === 'object' &&
    typeof Orbit.globals.process.nextTick === 'function';
let resolvedPromise;
const nextTick = isNode
    ? function (fn) {
        if (!resolvedPromise) {
            resolvedPromise = Promise.resolve();
        }
        resolvedPromise.then(() => {
            Orbit.globals.process.nextTick(fn);
        });
    }
    : Orbit.globals.setImmediate || setTimeout;
function onceTick(fn) {
    return function tick() {
        if (!ticks.has(tick)) {
            ticks.add(tick);
            nextTick(() => {
                fn();
                cancelTick(tick);
            });
        }
    };
}
function cancelTick(tick) {
    ticks.delete(tick);
}
const ticks = new WeakSet();

class SyncLiveQueryUpdate {
    constructor(settings) {
        this._cache = settings.cache;
        this._query = settings.query;
    }
    query() {
        return this._cache.query(this._query);
    }
}
class SyncLiveQuery extends LiveQuery {
    constructor(settings) {
        super(settings);
        this.cache = settings.cache;
    }
    get schema() {
        return this.cache.schema;
    }
    get _update() {
        return new SyncLiveQueryUpdate({
            cache: this.cache,
            query: this._query
        });
    }
    query() {
        return this._update.query();
    }
    subscribe(cb) {
        return this._subscribe(() => {
            cb(this._update);
        });
    }
}

/**
 * Operation processors are used to identify operations that should be performed
 * together to ensure that a `Cache` or other container of data remains
 * consistent and correct.
 *
 * `OperationProcessor` is an abstract base class to be extended by specific
 * operation processors.
 */
class SyncOperationProcessor {
    constructor(accessor) {
        this._accessor = accessor;
    }
    /**
     * The `SyncRecordAccessor` that is monitored.
     */
    get accessor() {
        return this._accessor;
    }
    /**
     * Called when all the data in a cache has been reset.
     *
     * If `base` is included, the cache is being reset to match a base cache.
     */
    reset(base) { }
    /**
     * Allow the processor to perform an upgrade as part of a cache upgrade.
     */
    upgrade() { }
    /**
     * Validates an operation before processing it.
     */
    validate(operation) { }
    /**
     * Called before an `operation` has been applied.
     *
     * Returns an array of operations to be applied **BEFORE** the `operation`
     * itself is applied.
     */
    before(operation) {
        return [];
    }
    /**
     * Called before an `operation` has been applied.
     *
     * Returns an array of operations to be applied **AFTER** the `operation`
     * has been applied successfully.
     */
    after(operation) {
        return [];
    }
    /**
     * Called immediately after an `operation` has been applied and before the
     * `patch` event has been emitted (i.e. before any listeners have been
     * notified that the operation was applied).
     *
     * No operations may be returned.
     */
    immediate(operation) { }
    /**
     * Called after an `operation` _and_ any related operations have been applied.
     *
     * Returns an array of operations to be applied **AFTER** the `operation`
     * itself and any operations returned from the `after` hook have been applied.
     */
    finally(operation) {
        return [];
    }
}

function getInverseRelationship(schema, record, relationship, relatedRecord) {
    if (relatedRecord) {
        const recordIdentity = cloneRecordIdentity(record);
        return {
            record: recordIdentity,
            relationship,
            relatedRecord
        };
    }
    return null;
}
function getInverseRelationships(schema, record, relationship, relatedRecords) {
    if (relatedRecords && relatedRecords.length > 0) {
        const recordIdentity = cloneRecordIdentity(record);
        return relatedRecords.map((relatedRecord) => {
            return {
                record: recordIdentity,
                relationship,
                relatedRecord
            };
        });
    }
    return [];
}
function getAllInverseRelationships(schema, record) {
    const recordIdentity = cloneRecordIdentity(record);
    const inverseRelationships = [];
    schema.eachRelationship(record.type, (relationship) => {
        const relationshipData = deepGet(record, [
            'relationships',
            relationship,
            'data'
        ]);
        if (Array.isArray(relationshipData)) {
            for (let relatedRecord of relationshipData) {
                inverseRelationships.push({
                    record: recordIdentity,
                    relationship,
                    relatedRecord
                });
            }
        }
        else if (relationshipData) {
            inverseRelationships.push({
                record: recordIdentity,
                relationship,
                relatedRecord: relationshipData
            });
        }
    });
    return inverseRelationships;
}
function getInverseRelationshipRemovalOps(schema, inverseRelationships) {
    var _a;
    const ops = [];
    for (let inverseRelationship of inverseRelationships) {
        const { type } = inverseRelationship.record;
        const { relationship } = inverseRelationship;
        const relationshipDef = schema.getRelationship(type, relationship);
        // TODO - remove deprecated `type` check
        if (((_a = relationshipDef.kind) !== null && _a !== void 0 ? _a : relationshipDef.type) === 'hasMany') {
            ops.push({
                op: 'removeFromRelatedRecords',
                record: inverseRelationship.record,
                relationship: inverseRelationship.relationship,
                relatedRecord: inverseRelationship.relatedRecord
            });
        }
        else {
            ops.push({
                op: 'replaceRelatedRecord',
                record: inverseRelationship.record,
                relationship: inverseRelationship.relationship,
                relatedRecord: null
            });
        }
    }
    return ops;
}

/**
 * An operation processor that ensures that a cache's data is consistent and
 * doesn't contain any dead references.
 *
 * This is achieved by maintaining a mapping of reverse relationships for each
 * record. When a record is removed, any references to it can also be identified
 * and removed.
 */
class SyncCacheIntegrityProcessor extends SyncOperationProcessor {
    after(operation) {
        switch (operation.op) {
            case 'replaceRelatedRecord':
                this.removeInverseRelationship(operation.record, operation.relationship, this.accessor.getRelatedRecordSync(operation.record, operation.relationship));
                return [];
            case 'replaceRelatedRecords':
                this.removeInverseRelationships(operation.record, operation.relationship, this.accessor.getRelatedRecordsSync(operation.record, operation.relationship));
                return [];
            case 'removeFromRelatedRecords':
                this.removeInverseRelationship(operation.record, operation.relationship, operation.relatedRecord);
                return [];
            case 'removeRecord':
                this.removeAllInverseRelationships(operation.record);
                return [];
            case 'updateRecord':
                this.removeAllInverseRelationships(operation.record);
                return [];
            default:
                return [];
        }
    }
    finally(operation) {
        switch (operation.op) {
            case 'replaceRelatedRecord':
                this.addInverseRelationship(operation.record, operation.relationship, operation.relatedRecord);
                return [];
            case 'replaceRelatedRecords':
                this.addInverseRelationships(operation.record, operation.relationship, operation.relatedRecords);
                return [];
            case 'addToRelatedRecords':
                this.addInverseRelationship(operation.record, operation.relationship, operation.relatedRecord);
                return [];
            case 'addRecord':
                this.addAllInverseRelationships(operation.record);
                return [];
            case 'updateRecord':
                this.addAllInverseRelationships(operation.record);
                return [];
            case 'removeRecord':
                return this.clearInverseRelationshipOps(operation.record);
            default:
                return [];
        }
    }
    addInverseRelationship(record, relationship, relatedRecord) {
        let inverseRelationship = getInverseRelationship(this.accessor.schema, record, relationship, relatedRecord);
        if (inverseRelationship) {
            this.accessor.addInverseRelationshipsSync([inverseRelationship]);
        }
    }
    addInverseRelationships(record, relationship, relatedRecords) {
        let inverseRelationships = getInverseRelationships(this.accessor.schema, record, relationship, relatedRecords);
        if (inverseRelationships) {
            this.accessor.addInverseRelationshipsSync(inverseRelationships);
        }
    }
    addAllInverseRelationships(record) {
        let inverseRelationships = getAllInverseRelationships(this.accessor.schema, record);
        if (inverseRelationships.length > 0) {
            this.accessor.addInverseRelationshipsSync(inverseRelationships);
        }
    }
    removeInverseRelationship(record, relationship, relatedRecord) {
        let inverseRelationship = getInverseRelationship(this.accessor.schema, record, relationship, relatedRecord);
        if (inverseRelationship) {
            this.accessor.removeInverseRelationshipsSync([inverseRelationship]);
        }
    }
    removeInverseRelationships(record, relationship, relatedRecords) {
        let inverseRelationships = getInverseRelationships(this.accessor.schema, record, relationship, relatedRecords);
        if (inverseRelationships.length > 0) {
            this.accessor.removeInverseRelationshipsSync(inverseRelationships);
        }
    }
    removeAllInverseRelationships(record) {
        const currentRecord = this.accessor.getRecordSync(record);
        if (currentRecord) {
            const inverseRelationships = getAllInverseRelationships(this.accessor.schema, currentRecord);
            if (inverseRelationships.length > 0) {
                this.accessor.removeInverseRelationshipsSync(inverseRelationships);
            }
        }
    }
    clearInverseRelationshipOps(record) {
        return getInverseRelationshipRemovalOps(this.accessor.schema, this.accessor.getInverseRelationshipsSync(record));
    }
}

function recordAdded(schema, record) {
    const ops = [];
    if (record.relationships) {
        const recordIdentity = cloneRecordIdentity(record);
        schema.eachRelationship(record.type, (relationship, relationshipDef) => {
            const relationshipData = deepGet(record, [
                'relationships',
                relationship,
                'data'
            ]);
            if (relationshipData) {
                const relatedRecords = recordArrayFromData(relationshipData);
                Array.prototype.push.apply(ops, addRelatedRecordsOps(schema, recordIdentity, relationshipDef, relatedRecords));
            }
        });
    }
    return ops;
}
function relatedRecordAdded(schema, record, relationship, relatedRecord) {
    const ops = [];
    if (relatedRecord) {
        const { type } = record;
        const relationshipDef = schema.getRelationship(type, relationship);
        const inverseRelationship = relationshipDef.inverse;
        if (inverseRelationship) {
            ops.push(addRelationshipOp(schema, relatedRecord, inverseRelationship, record));
        }
    }
    return ops;
}
function relatedRecordRemoved(schema, record, relationship, relatedRecord, currentRelatedRecord) {
    const ops = [];
    if (currentRelatedRecord) {
        const { type } = record;
        const relationshipDef = schema.getRelationship(type, relationship);
        const inverseRelationship = relationshipDef.inverse;
        if (inverseRelationship) {
            ops.push(removeRelationshipOp(schema, relatedRecord, inverseRelationship, record));
        }
    }
    return ops;
}
function relatedRecordReplaced(schema, record, relationship, relatedRecord, currentRelatedRecord) {
    const ops = [];
    if (!equalRecordIdentities(relatedRecord, currentRelatedRecord)) {
        const { type } = record;
        const relationshipDef = schema.getRelationship(type, relationship);
        const inverseRelationship = relationshipDef.inverse;
        if (inverseRelationship) {
            if (currentRelatedRecord) {
                ops.push(removeRelationshipOp(schema, currentRelatedRecord, inverseRelationship, record));
            }
            if (relatedRecord) {
                ops.push(addRelationshipOp(schema, relatedRecord, inverseRelationship, record));
            }
        }
    }
    return ops;
}
function relatedRecordsReplaced(schema, record, relationship, relatedRecords, currentRelatedRecords) {
    const ops = [];
    const { type } = record;
    const relationshipDef = schema.getRelationship(type, relationship);
    let addedRecords;
    if (currentRelatedRecords && currentRelatedRecords.length > 0) {
        let removedRecords = uniqueRecordIdentities(currentRelatedRecords, relatedRecords);
        Array.prototype.push.apply(ops, removeRelatedRecordsOps(schema, record, relationshipDef, removedRecords));
        addedRecords = uniqueRecordIdentities(relatedRecords, currentRelatedRecords);
    }
    else {
        addedRecords = relatedRecords;
    }
    Array.prototype.push.apply(ops, addRelatedRecordsOps(schema, record, relationshipDef, addedRecords));
    return ops;
}
function recordRemoved(schema, record) {
    const ops = [];
    if (record && record.relationships) {
        const recordIdentity = cloneRecordIdentity(record);
        schema.eachRelationship(record.type, (relationship, relationshipDef) => {
            const relationshipData = deepGet(record, [
                'relationships',
                relationship,
                'data'
            ]);
            if (relationshipData) {
                const relatedRecords = recordArrayFromData(relationshipData);
                Array.prototype.push.apply(ops, removeRelatedRecordsOps(schema, recordIdentity, relationshipDef, relatedRecords));
            }
        });
    }
    return ops;
}
function recordUpdated(schema, record, currentRecord) {
    const ops = [];
    if (record.relationships) {
        const recordIdentity = cloneRecordIdentity(record);
        schema.eachRelationship(record.type, (relationship, relationshipDef) => {
            var _a;
            const relationshipData = deepGet(record, [
                'relationships',
                relationship,
                'data'
            ]);
            const currentRelationshipData = currentRecord &&
                deepGet(currentRecord, ['relationships', relationship, 'data']);
            if (relationshipData !== undefined) {
                // TODO - remove deprecated `type` check
                if (((_a = relationshipDef.kind) !== null && _a !== void 0 ? _a : relationshipDef.type) === 'hasMany') {
                    Array.prototype.push.apply(ops, relatedRecordsReplaced(schema, recordIdentity, relationship, relationshipData || [], currentRelationshipData || []));
                }
                else {
                    Array.prototype.push.apply(ops, relatedRecordReplaced(schema, recordIdentity, relationship, relationshipData || null, currentRelationshipData || null));
                }
            }
        });
    }
    return ops;
}
function addRelatedRecordsOps(schema, record, relationshipDef, relatedRecords) {
    if (relatedRecords.length > 0 && relationshipDef.inverse) {
        const inverse = relationshipDef.inverse;
        return relatedRecords.map((relatedRecord) => addRelationshipOp(schema, relatedRecord, inverse, record));
    }
    return [];
}
function removeRelatedRecordsOps(schema, record, relationshipDef, relatedRecords) {
    if (relatedRecords.length > 0) {
        if (relationshipDef.dependent === 'remove') {
            return removeDependentRecords(relatedRecords);
        }
        else if (relationshipDef.inverse) {
            const inverse = relationshipDef.inverse;
            return relatedRecords.map((relatedRecord) => removeRelationshipOp(schema, relatedRecord, inverse, record));
        }
    }
    return [];
}
function addRelationshipOp(schema, record, relationship, relatedRecord) {
    var _a;
    const { type } = record;
    const relationshipDef = schema.getRelationship(type, relationship);
    // TODO - remove deprecated `type` check
    const isHasMany = ((_a = relationshipDef.kind) !== null && _a !== void 0 ? _a : relationshipDef.type) === 'hasMany';
    return {
        op: isHasMany ? 'addToRelatedRecords' : 'replaceRelatedRecord',
        record,
        relationship,
        relatedRecord
    };
}
function removeRelationshipOp(schema, record, relationship, relatedRecord) {
    var _a;
    const { type } = record;
    const relationshipDef = schema.getRelationship(type, relationship);
    // TODO - remove deprecated `type` check
    const isHasMany = ((_a = relationshipDef.kind) !== null && _a !== void 0 ? _a : relationshipDef.type) === 'hasMany';
    return {
        op: isHasMany ? 'removeFromRelatedRecords' : 'replaceRelatedRecord',
        record,
        relationship,
        relatedRecord: isHasMany ? relatedRecord : null
    };
}
function recordArrayFromData(data) {
    if (Array.isArray(data)) {
        return data;
    }
    else if (data) {
        return [data];
    }
    else {
        return [];
    }
}
function removeDependentRecords(relatedRecords) {
    return relatedRecords.map((record) => ({
        op: 'removeRecord',
        record
    }));
}

/**
 * An operation processor that ensures that a cache's data is consistent with
 * its associated schema. This includes maintenance of inverse and dependent
 * relationships.
 */
class SyncSchemaConsistencyProcessor extends SyncOperationProcessor {
    after(operation) {
        switch (operation.op) {
            case 'addRecord':
                return recordAdded(this.accessor.schema, operation.record);
            case 'addToRelatedRecords':
                return relatedRecordAdded(this.accessor.schema, operation.record, operation.relationship, operation.relatedRecord);
            case 'replaceRelatedRecord':
                return relatedRecordReplaced(this.accessor.schema, operation.record, operation.relationship, operation.relatedRecord, this.accessor.getRelatedRecordSync(operation.record, operation.relationship));
            case 'replaceRelatedRecords':
                return relatedRecordsReplaced(this.accessor.schema, operation.record, operation.relationship, operation.relatedRecords, this.accessor.getRelatedRecordsSync(operation.record, operation.relationship));
            case 'removeFromRelatedRecords':
                return relatedRecordRemoved(this.accessor.schema, operation.record, operation.relationship, operation.relatedRecord, this.accessor.getRecordSync(operation.relatedRecord));
            case 'removeRecord':
                return recordRemoved(this.accessor.schema, this.accessor.getRecordSync(operation.record));
            case 'updateRecord':
                return recordUpdated(this.accessor.schema, operation.record, this.accessor.getRecordSync(operation.record));
            default:
                return [];
        }
    }
}

/**
 * An operation processor that ensures that an operation is compatible with
 * its associated schema.
 */
class SyncSchemaValidationProcessor extends SyncOperationProcessor {
    constructor(accessor) {
        super(accessor);
        const cache = this.accessor;
        const { schema, validatorFor } = cache;
        if (validatorFor === undefined || schema === undefined) {
            throw new Assertion('SyncSchemaValidationProcessor requires a RecordCache with both a `validationFor` and a `schema`.');
        }
        this.schema = schema;
        this.validatorFor = validatorFor;
    }
    validate(operation) {
        const { schema, validatorFor } = this;
        const issues = validateRecordOperation(operation, { schema, validatorFor });
        if (issues) {
            throw new ValidationError('Validation failed', issues);
        }
    }
}

const SyncInverseTransformOperators = {
    addRecord(cache, operation, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options) {
        const op = operation;
        const { type, id } = op.record;
        const current = cache.getRecordSync(op.record);
        if (current) {
            if (eq(current, op.record)) {
                return;
            }
            else {
                return {
                    op: 'updateRecord',
                    record: current
                };
            }
        }
        else {
            return {
                op: 'removeRecord',
                record: { type, id }
            };
        }
    },
    updateRecord(cache, operation, options) {
        const op = operation;
        const currentRecord = cache.getRecordSync(op.record);
        const replacement = op.record;
        const { type, id } = replacement;
        if (currentRecord) {
            let result = { type, id };
            let changed = false;
            ['attributes', 'keys'].forEach((grouping) => {
                if (replacement[grouping]) {
                    Object.keys(replacement[grouping]).forEach((field) => {
                        let value = replacement[grouping][field];
                        let currentValue = deepGet(currentRecord, [grouping, field]);
                        if (!eq(value, currentValue)) {
                            changed = true;
                            deepSet(result, [grouping, field], currentValue === undefined ? null : currentValue);
                        }
                    });
                }
            });
            if (replacement.relationships) {
                Object.keys(replacement.relationships).forEach((field) => {
                    let data = deepGet(replacement, ['relationships', field, 'data']);
                    if (data !== undefined) {
                        let currentData = deepGet(currentRecord, [
                            'relationships',
                            field,
                            'data'
                        ]);
                        let relationshipChanged;
                        if (Array.isArray(data)) {
                            if (currentData) {
                                relationshipChanged = !equalRecordIdentitySets(currentData, data);
                            }
                            else {
                                relationshipChanged = true;
                                currentData = [];
                            }
                        }
                        else {
                            if (currentData) {
                                relationshipChanged = !equalRecordIdentities(currentData, data);
                            }
                            else {
                                relationshipChanged = true;
                                currentData = null;
                            }
                        }
                        if (relationshipChanged) {
                            changed = true;
                            deepSet(result, ['relationships', field, 'data'], currentData);
                        }
                    }
                });
            }
            if (changed) {
                return {
                    op: 'updateRecord',
                    record: result
                };
            }
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(type, id);
            }
            else {
                return {
                    op: 'removeRecord',
                    record: { type, id }
                };
            }
        }
    },
    removeRecord(cache, operation, options) {
        const op = operation;
        const { record } = op;
        const currentRecord = cache.getRecordSync(record);
        if (currentRecord) {
            return {
                op: 'addRecord',
                record: currentRecord
            };
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
    },
    replaceKey(cache, operation, options) {
        const op = operation;
        const { record, key } = op;
        const currentRecord = cache.getRecordSync(record);
        if (currentRecord === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        const currentValue = currentRecord && deepGet(currentRecord, ['keys', key]);
        if (!eq(currentValue, op.value)) {
            const { type, id } = record;
            return {
                op: 'replaceKey',
                record: { type, id },
                key,
                value: currentValue
            };
        }
    },
    replaceAttribute(cache, operation, options) {
        const op = operation;
        const { record, attribute } = op;
        const currentRecord = cache.getRecordSync(record);
        if (currentRecord === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        const currentValue = currentRecord && deepGet(currentRecord, ['attributes', attribute]);
        if (!eq(currentValue, op.value)) {
            const { type, id } = record;
            return {
                op: 'replaceAttribute',
                record: { type, id },
                attribute,
                value: currentValue
            };
        }
    },
    addToRelatedRecords(cache, operation, options) {
        const op = operation;
        const { record, relationship, relatedRecord } = op;
        const currentRelatedRecords = cache.getRelatedRecordsSync(record, relationship);
        if (currentRelatedRecords === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                if (cache.getRecordSync(record) === undefined) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
            }
        }
        if (currentRelatedRecords === undefined ||
            !recordsInclude(currentRelatedRecords, relatedRecord)) {
            return {
                op: 'removeFromRelatedRecords',
                record,
                relationship,
                relatedRecord
            };
        }
    },
    removeFromRelatedRecords(cache, operation, options) {
        const op = operation;
        const { record, relationship, relatedRecord } = op;
        const currentRelatedRecords = cache.getRelatedRecordsSync(record, relationship);
        if (currentRelatedRecords === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                if (cache.getRecordSync(record) === undefined) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
            }
        }
        if (currentRelatedRecords !== undefined &&
            recordsInclude(currentRelatedRecords, relatedRecord)) {
            return {
                op: 'addToRelatedRecords',
                record,
                relationship,
                relatedRecord
            };
        }
    },
    replaceRelatedRecords(cache, operation, options) {
        const op = operation;
        const { record, relationship, relatedRecords } = op;
        const currentRelatedRecords = cache.getRelatedRecordsSync(record, relationship);
        if (currentRelatedRecords === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                if (cache.getRecordSync(record) === undefined) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
            }
        }
        if (currentRelatedRecords === undefined ||
            !equalRecordIdentitySets(currentRelatedRecords, relatedRecords)) {
            return {
                op: 'replaceRelatedRecords',
                record,
                relationship,
                relatedRecords: currentRelatedRecords || []
            };
        }
    },
    replaceRelatedRecord(cache, operation, options) {
        const op = operation;
        const { record, relationship, relatedRecord } = op;
        const currentRelatedRecord = cache.getRelatedRecordSync(record, relationship);
        if (currentRelatedRecord === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                if (cache.getRecordSync(record) === undefined) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
            }
        }
        if (currentRelatedRecord === undefined ||
            !equalRecordIdentities(currentRelatedRecord, relatedRecord)) {
            return {
                op: 'replaceRelatedRecord',
                record,
                relationship,
                relatedRecord: currentRelatedRecord || null
            };
        }
    }
};

const SyncQueryOperators = {
    findRecord(cache, expression, options) {
        const { record } = expression;
        const currentRecord = cache.getRecordSync(record);
        if (!currentRecord) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        return currentRecord;
    },
    findRecords(cache, expression, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options) {
        let exp = expression;
        let results = cache.getRecordsSync(exp.records || exp.type);
        if (exp.filter) {
            results = filterRecords(results, exp.filter);
        }
        if (exp.sort) {
            results = sortRecords(results, exp.sort);
        }
        if (exp.page) {
            results = paginateRecords(results, exp.page);
        }
        return results;
    },
    findRelatedRecords(cache, expression, options) {
        const exp = expression;
        const { record, relationship } = exp;
        const relatedIds = cache.getRelatedRecordsSync(record, relationship);
        if (!relatedIds || relatedIds.length === 0) {
            if (!cache.getRecordSync(record)) {
                if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
                else {
                    return undefined;
                }
            }
            return [];
        }
        let results = cache.getRecordsSync(relatedIds);
        if (exp.filter) {
            results = filterRecords(results, exp.filter);
        }
        if (exp.sort) {
            results = sortRecords(results, exp.sort);
        }
        if (exp.page) {
            results = paginateRecords(results, exp.page);
        }
        return results;
    },
    findRelatedRecord(cache, expression, options) {
        const exp = expression;
        const { record, relationship } = exp;
        const relatedId = cache.getRelatedRecordSync(record, relationship);
        if (relatedId) {
            return cache.getRecordSync(relatedId) || null;
        }
        else {
            if (!cache.getRecordSync(record)) {
                if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                    throw new RecordNotFoundException(record.type, record.id);
                }
                else {
                    return undefined;
                }
            }
            return null;
        }
    }
};
function filterRecords(records, filters) {
    return records.filter((record) => {
        for (let i = 0, l = filters.length; i < l; i++) {
            if (!applyFilter(record, filters[i])) {
                return false;
            }
        }
        return true;
    });
}
function applyFilter(record, filter) {
    if (filter.kind === 'attribute') {
        let actual = deepGet(record, ['attributes', filter.attribute]);
        if (actual === undefined) {
            return false;
        }
        let expected = filter.value;
        switch (filter.op) {
            case 'equal':
                return actual === expected;
            case 'gt':
                return actual > expected;
            case 'gte':
                return actual >= expected;
            case 'lt':
                return actual < expected;
            case 'lte':
                return actual <= expected;
            default:
                throw new QueryExpressionParseError('Filter operation ${filter.op} not recognized for Store.');
        }
    }
    else if (filter.kind === 'relatedRecords') {
        let actual = deepGet(record, [
            'relationships',
            filter.relation,
            'data'
        ]);
        if (actual === undefined) {
            return false;
        }
        let expected = filter.records;
        switch (filter.op) {
            case 'equal':
                return (actual.length === expected.length &&
                    expected.every((e) => actual.some((a) => a.id === e.id && a.type === e.type)));
            case 'all':
                return expected.every((e) => actual.some((a) => a.id === e.id && a.type === e.type));
            case 'some':
                return expected.some((e) => actual.some((a) => a.id === e.id && a.type === e.type));
            case 'none':
                return !expected.some((e) => actual.some((a) => a.id === e.id && a.type === e.type));
            default:
                throw new QueryExpressionParseError('Filter operation ${filter.op} not recognized for Store.');
        }
    }
    else if (filter.kind === 'relatedRecord') {
        let actual = deepGet(record, ['relationships', filter.relation, 'data']);
        if (actual === undefined) {
            return false;
        }
        let expected = filter.record;
        switch (filter.op) {
            case 'equal':
                if (actual === null) {
                    return expected === null;
                }
                else {
                    if (Array.isArray(expected)) {
                        return expected.some((e) => actual.type === e.type && actual.id === e.id);
                    }
                    else if (expected) {
                        return actual.type === expected.type && actual.id === expected.id;
                    }
                    else {
                        return false;
                    }
                }
            default:
                throw new QueryExpressionParseError('Filter operation ${filter.op} not recognized for Store.');
        }
    }
    return false;
}
function sortRecords(records, sortSpecifiers) {
    const comparisonValues = new Map();
    records.forEach((record) => {
        comparisonValues.set(record, sortSpecifiers.map((sortSpecifier) => {
            if (sortSpecifier.kind === 'attribute') {
                return deepGet(record, [
                    'attributes',
                    sortSpecifier.attribute
                ]);
            }
            else {
                throw new QueryExpressionParseError('Sort specifier ${sortSpecifier.kind} not recognized for Store.');
            }
        }));
    });
    const comparisonOrders = sortSpecifiers.map((sortExpression) => sortExpression.order === 'descending' ? -1 : 1);
    return records.sort((record1, record2) => {
        const values1 = comparisonValues.get(record1);
        const values2 = comparisonValues.get(record2);
        for (let i = 0; i < sortSpecifiers.length; i++) {
            if (values1[i] < values2[i]) {
                return -comparisonOrders[i];
            }
            else if (values1[i] > values2[i]) {
                return comparisonOrders[i];
            }
            else if (isNone(values1[i]) && !isNone(values2[i])) {
                return comparisonOrders[i];
            }
            else if (isNone(values2[i]) && !isNone(values1[i])) {
                return -comparisonOrders[i];
            }
        }
        return 0;
    });
}
function paginateRecords(records, paginationOptions) {
    if (paginationOptions.limit !== undefined) {
        let offset = paginationOptions.offset === undefined ? 0 : paginationOptions.offset;
        let limit = paginationOptions.limit;
        return records.slice(offset, offset + limit);
    }
    else {
        throw new QueryExpressionParseError('Pagination options not recognized for Store. Please specify `offset` and `limit`.');
    }
}

const SyncTransformOperators = {
    addRecord(cache, operation, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options) {
        const op = operation;
        const { record } = op;
        cache.setRecordSync(record);
        if (cache.keyMap) {
            cache.keyMap.pushRecord(record);
        }
        return record;
    },
    updateRecord(cache, operation, options) {
        const op = operation;
        const { record } = op;
        const currentRecord = cache.getRecordSync(record);
        if (currentRecord === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        const mergedRecord = mergeRecords(currentRecord || null, record);
        cache.setRecordSync(mergedRecord);
        if (cache.keyMap) {
            cache.keyMap.pushRecord(mergedRecord);
        }
        return mergedRecord;
    },
    removeRecord(cache, operation, options) {
        const op = operation;
        const record = cache.removeRecordSync(op.record);
        if (record === undefined) {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(op.record.type, op.record.id);
            }
        }
        return record;
    },
    replaceKey(cache, operation, options) {
        const op = operation;
        const currentRecord = cache.getRecordSync(op.record);
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        deepSet(record, ['keys', op.key], op.value);
        cache.setRecordSync(record);
        if (cache.keyMap) {
            cache.keyMap.pushRecord(record);
        }
        return record;
    },
    replaceAttribute(cache, operation, options) {
        const op = operation;
        const currentRecord = cache.getRecordSync(op.record);
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        deepSet(record, ['attributes', op.attribute], op.value);
        cache.setRecordSync(record);
        return record;
    },
    addToRelatedRecords(cache, operation, options) {
        const op = operation;
        const { relationship, relatedRecord } = op;
        const currentRecord = cache.getRecordSync(op.record);
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        const relatedRecords = deepGet(record, ['relationships', relationship, 'data']) || [];
        if (!recordsInclude(relatedRecords, relatedRecord)) {
            relatedRecords.push(relatedRecord);
            deepSet(record, ['relationships', relationship, 'data'], relatedRecords);
            cache.setRecordSync(record);
        }
        return record;
    },
    removeFromRelatedRecords(cache, operation, options) {
        const op = operation;
        const currentRecord = cache.getRecordSync(op.record);
        const { relationship, relatedRecord } = op;
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
            let relatedRecords = deepGet(record, [
                'relationships',
                relationship,
                'data'
            ]);
            if (relatedRecords) {
                relatedRecords = relatedRecords.filter((r) => !equalRecordIdentities(r, relatedRecord));
                if (deepSet(record, ['relationships', relationship, 'data'], relatedRecords)) {
                    cache.setRecordSync(record);
                }
            }
            return record;
        }
        else {
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(op.record.type, op.record.id);
            }
        }
    },
    replaceRelatedRecords(cache, operation, options) {
        const op = operation;
        const currentRecord = cache.getRecordSync(op.record);
        const { relationship, relatedRecords } = op;
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        if (deepSet(record, ['relationships', relationship, 'data'], relatedRecords)) {
            cache.setRecordSync(record);
        }
        return record;
    },
    replaceRelatedRecord(cache, operation, options) {
        const op = operation;
        const currentRecord = cache.getRecordSync(op.record);
        const { relationship, relatedRecord } = op;
        let record;
        if (currentRecord) {
            record = clone(currentRecord);
        }
        else {
            record = cloneRecordIdentity(op.record);
            if (options === null || options === void 0 ? void 0 : options.raiseNotFoundExceptions) {
                throw new RecordNotFoundException(record.type, record.id);
            }
        }
        if (deepSet(record, ['relationships', relationship, 'data'], relatedRecord)) {
            cache.setRecordSync(record);
        }
        return record;
    }
};

const { assert: assert$3, deprecate } = Orbit;
class SyncRecordCache extends RecordCache {
    constructor(settings) {
        var _a, _b, _c;
        super(settings);
        this._queryOperators = (_a = settings.queryOperators) !== null && _a !== void 0 ? _a : SyncQueryOperators;
        this._transformOperators = (_b = settings.transformOperators) !== null && _b !== void 0 ? _b : SyncTransformOperators;
        this._inverseTransformOperators = (_c = settings.inverseTransformOperators) !== null && _c !== void 0 ? _c : SyncInverseTransformOperators;
        this._debounceLiveQueries = settings.debounceLiveQueries !== false;
        this._transformBuffer = settings.transformBuffer;
        const processors = settings.processors
            ? settings.processors
            : [SyncSchemaConsistencyProcessor, SyncCacheIntegrityProcessor];
        if (Orbit.debug && settings.processors === undefined) {
            processors.push(SyncSchemaValidationProcessor);
        }
        this._processors = processors.map((Processor) => {
            let processor = new Processor(this);
            assert$3('Each processor must extend SyncOperationProcessor', processor instanceof SyncOperationProcessor);
            return processor;
        });
    }
    get processors() {
        return this._processors;
    }
    getQueryOperator(op) {
        return this._queryOperators[op];
    }
    getTransformOperator(op) {
        return this._transformOperators[op];
    }
    getInverseTransformOperator(op) {
        return this._inverseTransformOperators[op];
    }
    applyRecordChangesetSync(changeset) {
        const { setRecords, removeRecords, addInverseRelationships, removeInverseRelationships } = changeset;
        if (setRecords && setRecords.length > 0) {
            this.setRecordsSync(setRecords);
        }
        if (removeRecords && removeRecords.length > 0) {
            this.removeRecordsSync(removeRecords);
        }
        if (addInverseRelationships && addInverseRelationships.length > 0) {
            this.addInverseRelationshipsSync(addInverseRelationships);
        }
        if (removeInverseRelationships && removeInverseRelationships.length > 0) {
            this.removeInverseRelationshipsSync(removeInverseRelationships);
        }
    }
    getRelatedRecordSync(identity, relationship) {
        const record = this.getRecordSync(identity);
        if (record) {
            return deepGet(record, ['relationships', relationship, 'data']);
        }
        return undefined;
    }
    getRelatedRecordsSync(identity, relationship) {
        const record = this.getRecordSync(identity);
        if (record) {
            return deepGet(record, ['relationships', relationship, 'data']);
        }
        return undefined;
    }
    query(queryOrExpressions, options, id) {
        const query = buildQuery(queryOrExpressions, options, id, this._queryBuilder);
        const response = this._query(query, options);
        if (options === null || options === void 0 ? void 0 : options.fullResponse) {
            return response;
        }
        else {
            return response.data;
        }
    }
    update(transformOrOperations, options, id) {
        const transform = buildTransform(transformOrOperations, options, id, this._transformBuilder);
        const response = this._update(transform, options);
        if (options === null || options === void 0 ? void 0 : options.fullResponse) {
            return response;
        }
        else {
            return response.data;
        }
    }
    /**
     * Patches the cache with an operation or operations.
     *
     * @deprecated since v0.17
     */
    patch(operationOrOperations) {
        deprecate('SyncRecordCache#patch has been deprecated. Use SyncRecordCache#update instead.');
        // TODO - Why is this `this` cast necessary for TS to understand the correct
        // method overload?
        const { data, details } = this.update(operationOrOperations, {
            fullResponse: true
        });
        return {
            inverse: (details === null || details === void 0 ? void 0 : details.inverseOperations) || [],
            data: Array.isArray(data) ? data : [data]
        };
    }
    liveQuery(queryOrExpressions, options, id) {
        const query = buildQuery(queryOrExpressions, options, id, this.queryBuilder);
        let debounce = options && options.debounce;
        if (typeof debounce !== 'boolean') {
            debounce = this._debounceLiveQueries;
        }
        return new SyncLiveQuery({
            debounce,
            cache: this,
            query
        });
    }
    /////////////////////////////////////////////////////////////////////////////
    // Protected methods
    /////////////////////////////////////////////////////////////////////////////
    _query(query, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options) {
        let data;
        if (Array.isArray(query.expressions)) {
            data = [];
            for (let expression of query.expressions) {
                const queryOperator = this.getQueryOperator(expression.op);
                if (!queryOperator) {
                    throw new Error(`Unable to find query operator: ${expression.op}`);
                }
                data.push(queryOperator(this, expression, this.getQueryOptions(query, expression)));
            }
        }
        else {
            const expression = query.expressions;
            const queryOperator = this.getQueryOperator(expression.op);
            if (!queryOperator) {
                throw new Error(`Unable to find query operator: ${expression.op}`);
            }
            data = queryOperator(this, expression, this.getQueryOptions(query, expression));
        }
        return { data: data };
    }
    _update(transform, options) {
        var _a, _b;
        if ((_a = this.getTransformOptions(transform)) === null || _a === void 0 ? void 0 : _a.useBuffer) {
            const buffer = this._initTransformBuffer(transform);
            buffer.startTrackingChanges();
            const response = buffer.update(transform, {
                fullResponse: true
            });
            const changes = buffer.stopTrackingChanges();
            this.applyRecordChangesetSync(changes);
            const { appliedOperations, appliedOperationResults } = response.details;
            for (let i = 0, len = appliedOperations.length; i < len; i++) {
                this.emit('patch', appliedOperations[i], appliedOperationResults[i]);
            }
            return response;
        }
        else {
            const response = {
                data: []
            };
            if (options === null || options === void 0 ? void 0 : options.fullResponse) {
                response.details = {
                    appliedOperations: [],
                    appliedOperationResults: [],
                    inverseOperations: []
                };
            }
            let data;
            if (Array.isArray(transform.operations)) {
                this._applyTransformOperations(transform, transform.operations, response, true);
                data = response.data;
            }
            else {
                this._applyTransformOperation(transform, transform.operations, response, true);
                if (Array.isArray(response.data)) {
                    data = response.data[0];
                }
            }
            if (options === null || options === void 0 ? void 0 : options.fullResponse) {
                (_b = response.details) === null || _b === void 0 ? void 0 : _b.inverseOperations.reverse();
            }
            return {
                ...response,
                data
            };
        }
    }
    _getTransformBuffer() {
        if (this._transformBuffer === undefined) {
            throw new Assertion('transformBuffer must be provided to cache via constructor settings');
        }
        return this._transformBuffer;
    }
    _initTransformBuffer(transform) {
        const buffer = this._getTransformBuffer();
        const records = recordsReferencedByOperations(toArray(transform.operations));
        const inverseRelationships = this.getInverseRelationshipsSync(records);
        const relatedRecords = inverseRelationships.map((ir) => ir.record);
        Array.prototype.push.apply(records, relatedRecords);
        buffer.resetState();
        buffer.setRecordsSync(this.getRecordsSync(records));
        buffer.addInverseRelationshipsSync(inverseRelationships);
        return buffer;
    }
    _applyTransformOperations(transform, ops, response, primary = false) {
        for (const op of ops) {
            this._applyTransformOperation(transform, op, response, primary);
        }
    }
    _applyTransformOperation(transform, operation, response, primary = false) {
        var _a, _b, _c, _d;
        if (operation instanceof OperationTerm) {
            operation = operation.toOperation();
        }
        for (let processor of this._processors) {
            processor.validate(operation);
        }
        const inverseTransformOperator = this.getInverseTransformOperator(operation.op);
        const inverseOp = inverseTransformOperator(this, operation, this.getTransformOptions(transform, operation));
        if (inverseOp) {
            (_b = (_a = response.details) === null || _a === void 0 ? void 0 : _a.inverseOperations) === null || _b === void 0 ? void 0 : _b.push(inverseOp);
            // Query and perform related `before` operations
            for (let processor of this._processors) {
                this._applyTransformOperations(transform, processor.before(operation), response);
            }
            // Query related `after` operations before performing
            // the requested operation. These will be applied on success.
            let preparedOps = [];
            for (let processor of this._processors) {
                preparedOps.push(processor.after(operation));
            }
            // Perform the requested operation
            let transformOperator = this.getTransformOperator(operation.op);
            let data = transformOperator(this, operation, this.getTransformOptions(transform, operation));
            if (primary) {
                (_c = response.data) === null || _c === void 0 ? void 0 : _c.push(data);
            }
            if (response.details) {
                response.details.appliedOperationResults.push(data);
                response.details.appliedOperations.push(operation);
            }
            // Query and perform related `immediate` operations
            for (let processor of this._processors) {
                processor.immediate(operation);
            }
            // Emit event
            this.emit('patch', operation, data);
            // Perform prepared operations after performing the requested operation
            for (let ops of preparedOps) {
                this._applyTransformOperations(transform, ops, response);
            }
            // Query and perform related `finally` operations
            for (let processor of this._processors) {
                this._applyTransformOperations(transform, processor.finally(operation), response);
            }
        }
        else if (primary) {
            (_d = response.data) === null || _d === void 0 ? void 0 : _d.push(undefined);
        }
    }
}

function serializeRecordRelationshipIdentity(rri) {
    return `${serializeRecordIdentity(rri.record)}::${rri.relationship}`;
}
function deserializeRecordRelationshipIdentity(rri) {
    const [record, relationship] = rri.split('::');
    return { record: deserializeRecordIdentity(record), relationship };
}
class SimpleRecordTransformBuffer extends SyncRecordCache {
    constructor(settings) {
        super(settings);
        this.resetState();
    }
    resetState() {
        this._state = {
            records: {},
            inverseRelationships: {}
        };
    }
    startTrackingChanges() {
        this._delta = {
            records: {},
            inverseRelationships: {}
        };
    }
    stopTrackingChanges() {
        var _a, _b, _c, _d;
        if (this._delta === undefined) {
            throw new Error(`Changes are not being tracked. Call 'startTrackingChanges' before 'stopTrackingChanges'`);
        }
        let { records, inverseRelationships } = this._delta;
        let changeset = {};
        for (let rid of Object.keys(records)) {
            let rv = records[rid];
            if (rv === null) {
                changeset.removeRecords = (_a = changeset.removeRecords) !== null && _a !== void 0 ? _a : [];
                changeset.removeRecords.push(deserializeRecordIdentity(rid));
            }
            else {
                changeset.setRecords = (_b = changeset.setRecords) !== null && _b !== void 0 ? _b : [];
                changeset.setRecords.push(rv);
            }
        }
        for (let rid of Object.keys(inverseRelationships)) {
            let relatedRecord = deserializeRecordIdentity(rid);
            let rels = inverseRelationships[rid];
            for (let rel of Object.keys(rels)) {
                let rv = rels[rel];
                let { record, relationship } = deserializeRecordRelationshipIdentity(rel);
                let rri = { relatedRecord, record, relationship };
                if (rv === null) {
                    changeset.removeInverseRelationships = (_c = changeset.removeInverseRelationships) !== null && _c !== void 0 ? _c : [];
                    changeset.removeInverseRelationships.push(rri);
                }
                else {
                    changeset.addInverseRelationships = (_d = changeset.addInverseRelationships) !== null && _d !== void 0 ? _d : [];
                    changeset.addInverseRelationships.push(rri);
                }
            }
        }
        this._delta = undefined;
        return changeset;
    }
    getRecordSync(identity) {
        var _a;
        return (_a = this._state.records[serializeRecordIdentity(identity)]) !== null && _a !== void 0 ? _a : undefined;
    }
    getRecordsSync(typeOrIdentities) {
        if (typeof typeOrIdentities === 'string') {
            return objectValues(this._state.records[typeOrIdentities]);
        }
        else if (Array.isArray(typeOrIdentities)) {
            const records = [];
            const identities = typeOrIdentities;
            for (let i of identities) {
                let record = this.getRecordSync(i);
                if (record) {
                    records.push(record);
                }
            }
            return records;
        }
        else {
            throw new Error('typeOrIdentities must be specified in getRecordsSync');
        }
    }
    setRecordSync(record) {
        this._state.records[serializeRecordIdentity(record)] = record;
        if (this._delta) {
            this._delta.records[serializeRecordIdentity(record)] = record;
        }
    }
    setRecordsSync(records) {
        records.forEach((record) => this.setRecordSync(record));
    }
    removeRecordSync(recordIdentity) {
        const record = this.getRecordSync(recordIdentity);
        if (record) {
            delete this._state.records[serializeRecordIdentity(record)];
            if (this._delta) {
                this._delta.records[serializeRecordIdentity(record)] = null;
            }
            return record;
        }
        else {
            return undefined;
        }
    }
    removeRecordsSync(recordIdentities) {
        const records = [];
        for (let recordIdentity of recordIdentities) {
            let record = this.getRecordSync(recordIdentity);
            if (record) {
                records.push(record);
                delete this._state.records[serializeRecordIdentity(record)];
                if (this._delta) {
                    this._delta.records[serializeRecordIdentity(record)] = null;
                }
            }
        }
        return records;
    }
    getInverseRelationshipsSync(recordIdentityOrIdentities) {
        if (Array.isArray(recordIdentityOrIdentities)) {
            let relationships = [];
            recordIdentityOrIdentities.forEach((record) => {
                Array.prototype.push(relationships, this._getInverseRelationshipsSync(record));
            });
            return relationships;
        }
        else {
            return this._getInverseRelationshipsSync(recordIdentityOrIdentities);
        }
    }
    addInverseRelationshipsSync(relationships) {
        var _a, _b;
        for (let relationship of relationships) {
            const ri = serializeRecordIdentity(relationship.relatedRecord);
            const rri = serializeRecordRelationshipIdentity(relationship);
            const rels = (_a = this._state.inverseRelationships[ri]) !== null && _a !== void 0 ? _a : {};
            rels[rri] = relationship;
            this._state.inverseRelationships[ri] = rels;
            if (this._delta) {
                const rels = (_b = this._delta.inverseRelationships[ri]) !== null && _b !== void 0 ? _b : {};
                rels[rri] = relationship;
                this._delta.inverseRelationships[ri] = rels;
            }
        }
    }
    removeInverseRelationshipsSync(relationships) {
        var _a;
        for (let relationship of relationships) {
            const ri = serializeRecordIdentity(relationship.relatedRecord);
            const rri = serializeRecordRelationshipIdentity(relationship);
            const rels = this._state.inverseRelationships[ri];
            if (rels) {
                rels[rri] = null;
                if (this._delta) {
                    const rels = (_a = this._delta.inverseRelationships[ri]) !== null && _a !== void 0 ? _a : {};
                    rels[rri] = null;
                }
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////
    // Protected methods
    /////////////////////////////////////////////////////////////////////////////
    _getInverseRelationshipsSync(recordIdentity) {
        let relationships = this._state.inverseRelationships[serializeRecordIdentity(recordIdentity)];
        if (relationships) {
            return objectValues(relationships).filter((r) => r !== null);
        }
        else {
            return [];
        }
    }
}

export { LiveQuery as L, RecordCache as R, SimpleRecordTransformBuffer as S, getInverseRelationships as a, getAllInverseRelationships as b, getInverseRelationshipRemovalOps as c, recordRemoved as d, relatedRecordRemoved as e, relatedRecordsReplaced as f, getInverseRelationship as g, relatedRecordReplaced as h, relatedRecordAdded as i, recordAdded as j, SyncRecordCache as k, recordUpdated as r, syncable as s };
